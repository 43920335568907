import _ from 'lodash';
import moment from 'moment';
import handleError from '@fuse/core/errorHandler';
import ContractProjectList from './ContractProjectList';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCreateContractMutation, useEditContractMutation } from 'src/app/services/contractsApi';
import { CURRENCY_TYPES_ARRAY } from '../vars';

export const CONTRACT_TYPES = [
  { id: 0, key: 'pp', label: 'ПП' },
  { id: 1, key: 'up', label: 'УП' },
  { id: 2, key: 'pkm', label: 'ПКМ' },
  { id: 3, key: 'minCifr', label: 'Поручение МинЦифр' },
  { id: 4, key: 'chiefAssign', label: 'Поручение руководителя' },
  { id: 5, key: 'initProject', label: 'Инициативный проект' },
];

export const FINANCING_TYPES = [
  { id: 0, key: 'ictfund', label: 'Фонд Развития Информационно-Коммуникационных Технологий' },
  { id: 1, key: 'company', label: 'Собственные средства предприятия' },
  { id: 2, key: 'ebrd', label: 'Европейский банк реконструкции и развития' },
  { id: 3, key: 'customer', label: 'Бюджетные средства' },
  { id: 4, key: 'investments', label: 'Инвестиционные средства'}
];

const ContractForm = ({ data, refetch, organizations, onClose }) => {
  const { t } = useTranslation('translations');
  const { projectId, contractId } = useParams();
  const query = contractId ? useEditContractMutation : useCreateContractMutation;
  const [save, { isLoading }] = query();

  const defaultValues = {
    assignee: '',
    contractNumber: '',
    contractDate: new Date(),
    contractType: 0,
    address: '',
    amount: 0,
    financing: 0,
    prepayment: 0,
    basis: '',
    ...data,
    organizationId: data?.organizationId ? _.find(organizations, (o) => o.id === data.organizationId) : null,
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    register,
    setValue,
  } = useForm({
    defaultValues,
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (formValues) => {
    const contractDate = moment(formValues.contractDate).format('YYYY-MM-DDTHH:mm:ss.SSS');
    formValues.contractDate = contractDate;
    formValues.projectId = projectId;
    formValues.organizationId = formValues.organizationId.id;
    formValues.percentPrepayment = +formValues.percentPrepayment;
    formValues.prepayment = +formValues.prepayment;
    save(formValues).unwrap().then(handleClose).catch(handleError);
  };

  const filterOptions = (_options, { inputValue }) => {
    if (inputValue === '') {
      return _options.slice(0, 1000); 
    }
    return _options.filter((option) => option.title.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 1000); 
  };

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit(onSubmit)} style={{width: 1000, height: 600, overflowY: 'auto' }}>
        <Grid container spacing={2} py={1}>
          {data && (
            <Grid item xs={12}>
              <ContractProjectList data={data} />
            </Grid>
          )}
          <Grid item xs={6}>
            <Controller
              name="contractNumber"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('ContractNumber')}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  required
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="contractDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  disableMaskedInput
                  format="dd.MM.yyyy"
                  value={field.value ? new Date(field.value) : null}
                  onChange={(val) => field.onChange(val)}
                  label={t('ContractDate')}
                  textField={(_props) => <TextField {...register('contractDate')} {..._props} />}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="organizationId"
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  label={t('Organization')}
                  filterOptions={filterOptions}
                  getOptionLabel={(option) => option.title ?? ''}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={organizations || []}
                  renderOption={(_props, option) => (
                    <li {..._props} key={option.id}>
                      {option.title}
                    </li>
                  )}
                  onChange={(e, value) => {
                    if (value) {
                      field.onChange(value);
                      setValue('address', value.workAddress);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={t('Organization')} />}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Address')}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="contractType"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Cause')}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  variant="outlined"
                  required
                  fullWidth
                  select
                >
                  {CONTRACT_TYPES.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="financing"
              control={control}
              render={({ field }) => (
                <TextField {...field} select required fullWidth label={t('Financing')}>
                  {FINANCING_TYPES.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label={t('Amount')}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="percentPrepayment"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  InputProps={{
                    inputProps: { 
                        max: 100, min: 10 
                    }
                  }}
                  className='mr-10 w-[160px]'
                  label={t('Prepayment')+' %'}
                  error={!!errors.prepayment}
                />
              )}
            />
            <Controller
              control={control}
              rules={{ required: true }}
              name="сurrencyType"
              render={({ field }) => (
                <TextField 
                  {...field} 
                  className='w-[100px]' 
                  required
                  select 
                  label={t('Currency')} 
                  classes={{ input: 'flex items-center' }}
                >
                  {CURRENCY_TYPES_ARRAY.map(({id, label}) => (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="payment"
              control={control}
              render={({ field }) => (
                <div className='ml-5 flex items-center'>
                  <span>{t('ContractPayment')}: {field.value}</span>
                </div>
              )}
            />
            <Controller
              name="prepayment"
              control={control}
              render={({ field }) => (
                <div className='ml-5 flex items-center'>
                  <span>{t('ContractPrepayment')}: {field.value}</span>
                </div>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="basis"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  rows={4}
                  label={t('Cause')}
                  error={!!errors.title}
                  helperText={errors?.title?.message}
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          </Grid>
          <LoadingButton
            variant="contained"
            color="secondary"
            loading={isLoading}
            className=" w-full mt-16"
            type="submit"
            size="large"
          >
            {t('Save')}
          </LoadingButton>
      </form>
    </div>
  );
}

export default ContractForm;
