import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import ContactAvatar from '../../ContactAvatar';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { API } from 'app/configs/api';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/app/hooks/useUser';
import { removeMemberFromGroup } from 'app/store/chatStore/chatsSlice';

const StyledListItem = styled(ListItem)(({ theme, active }) => ({
  '&.active': {
    backgroundColor: theme.palette.background.default,
  },
}));

function ContactListItem({ chat, contact, group }) {
  removeMemberFromGroup;
  const { t } = useTranslation('translations');
  const routeParams = useParams();
  const dispatch = useDispatch();
  const { user } = useUser();

  const deleteUserFromChatGroup = async () => {
    try {
      const res = await axios.post(API.removeUserFromGroup, {
        groupId: group.id,
        userId: [contact.id],
      });
      if (res.data === 1) {
        dispatch(
          showMessage({ message: t('UserSuccessfullyRemovedFromGroup'), variant: 'success' })
        );
        dispatch(removeMemberFromGroup({ userId: contact.id, chatId: group.id }));
      }
    } catch (e) {
      console.log(e.message);
      dispatch(showMessage({ message: t('ErrorRemovingUserFromGroup'), variant: 'error' }));
    }
  };

  return (
    <StyledListItem
      button
      className={`px-32 py-12 min-h-80 ${chat ? 'cursor-pointer' : 'cursor-default'}`}
      active={routeParams.id === contact.id ? 1 : 0}
      component={chat ? NavLinkAdapter : ListItem}
      to={`/apps/chat/${contact.inChat ? contact.chatId : contact.id}`}
      {...(chat && { end: true, activeClassName: 'active' })}
      secondaryAction={
        chat ? null : group?.createdBy === user.id ? (
          <IconButton onClick={deleteUserFromChatGroup}>
            <Delete />
          </IconButton>
        ) : null
      }
    >
      <ContactAvatar contact={contact} />

      <ListItemText
        classes={{
          root: 'min-w-px px-16',
          primary: 'font-medium text-14',
          secondary: 'truncate',
        }}
        primary={contact.fullName || contact.name}
        secondary={
          chat ? contact.lastMessageText : group && group?.createdBy === contact.id ? 'owner' : ''
        }
      />

      <div className="flex flex-col justify-center items-end">
        {contact?.lastMessageDate && (
          <Typography className="whitespace-nowrap mb-8 font-medium text-12" color="text.secondary">
            {/* {format(new Date(contact.lastMessageDate), 'HH:mm')} */}
            {moment(contact.lastMessageDate).diff(moment(), 'days') < -7
              ? moment(contact.lastMessageDate).format('DD.MM.YY')
              : moment(contact.lastMessageDate).fromNow().replace('Bir necha ', '')}
          </Typography>
        )}
        <div className="items-center">
          {Boolean(contact?.unreadMessagesCount) && (
            <Box
              sx={{
                backgroundColor: 'secondary.main',
                color: 'secondary.contrastText',
              }}
              className="flex items-center justify-center min-w-20 h-20 rounded-full font-medium text-10 text-center"
            >
              {contact.unreadMessagesCount}
            </Box>
          )}
        </div>
      </div>
    </StyledListItem>
  );
}

export default ContactListItem;
