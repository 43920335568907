import BlankConfig from './blank/BlankConfig';
import CalendarAppConfig from './calendar/CalendarAppConfig';
import ChatAppConfig from './chat/ChatAppConfig';
import HelpDeskAppConfig from './help-desk/HelpDeskAppConfig';
import NewsAppConfig from './news/NewsAppConfig';
import MapAppConfig from './map/MapConfig';
import OrgStructureConfig from './org-structure/OrgStructureConfig';
import ProfileAppConfig from './profile/ProfileAppConfig';
import ProjectsAppConfig from './projects/ProjectsConfig';
import SkudConfig from './skud/employees/SkudConfig';
import SkudDetailConfig from './skud/skud-detail/SkudDetailConfig';
import SupportConfig from './support/SupportConfig';
import UsersConfig from './users/UsersConfig';
import PresentationConfig from './presentation/PresentationConfig';
import ClaimsAppConfig from './claims/ClaimsAppConfig';
import InternalDocumentsConfig from './internal-documents/InternalDocumentsConfig';
import HrmEmployeesConfig from '../hr/hrm-employees/HrmEmployeesConfig';

const appsConfigs = [
  ProfileAppConfig,
  ChatAppConfig,
  OrgStructureConfig,
  UsersConfig,
  HrmEmployeesConfig,
  ProjectsAppConfig,
  SkudConfig,
  SkudDetailConfig,
  BlankConfig,
  NewsAppConfig,
  MapAppConfig,
  HelpDeskAppConfig,
  CalendarAppConfig,
  SupportConfig,
  PresentationConfig,
  ClaimsAppConfig,
  InternalDocumentsConfig,
];

export default appsConfigs;
