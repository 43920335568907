import handleError from '@fuse/core/errorHandler';
import {
  Avatar,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { ChevronLeft, ChevronRight, Link, Save, Search } from '@mui/icons-material';
import { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStaffListMutation } from 'src/app/services/staffApi';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';
import { Button } from 'antd';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { API } from 'app/configs/api';
import useMessage from 'src/app/hooks/useMessage';

const rows = 5;

const LinkHRMForm = ({ selectedUser, onCloseAndRefresh }) => {
  const { t } = useTranslation('translations');
  const [employees, setEmployees] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [search, setSearch] = useState('');
  const [activeEmpl, setActiveEmpl] = useState(null);
  const [page, setPage] = useState(1);
  const [getStaffData] = useStaffListMutation();
  const deferredSearch = useDeferredValue(search);
  const [loading, setLoading] = useState(false);
  const { showMessage } = useMessage();

  const getEmployeesList = () => {
    setEmployees([]);
    getStaffData({
      name: search,
      filters: '{}',
      first: (page - 1) * rows,
      rows: rows,
      sortField: 'id',
      sortOrder: 0,
    })
      .then((res) => {
        const result = res?.data?.items;
        setEmployees(result);
        setTotalEmployees(res?.data?.totalItems);
      })
      .catch(handleError);
  };

  const onLinkHrm = async () => {
    setLoading(true);
    try {
      await axios.post(API.addUserStaffId, {
        userId: selectedUser.id,
        staffId: activeEmpl,
      });
      showMessage({ message: t('DataSuccessfullyUpdated'), variant: 'success' });
      onCloseAndRefresh();
    } catch (e) {
      showMessage({ message: t('ErrorOccuredOpenBrowserConsole'), variant: 'error' });
      handleError(e);
    }
    setLoading(false);
  };

  const handleEmplClick = (id) => {
    if (activeEmpl === id) {
      setActiveEmpl(null);
    } else {
      setActiveEmpl(id);
    }
  };

  const handleSearchEmployee = (event) => {
    setPage(1);
    const employee = event.target.value.trim();
    setSearch(employee);
  };

  const handleClearSearch = () => {
    setPage(1);
    setSearch('');
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalEmployees / rows);
  }, [totalEmployees]);

  useEffect(() => {
    if (deferredSearch === '' || deferredSearch.length > 2) {
      getEmployeesList();
    }
  }, [deferredSearch, page]);

  return (
    <div className="w-full">
      {!activeEmpl && (
        <div className="flex justify-center items-center gap-3 mt-16 border-0 relative">
          <span
            className="block absolute right-0 z-40 text-xl font-medium mr-12 text-grey-500 hover:cursor-pointer"
            onClick={handleClearSearch}
          >
            &times;
          </span>
          <TextField
            fullWidth
            id="outlined-controlled"
            size="small"
            autoComplete="off"
            value={search}
            variant="outlined"
            placeholder={t('Search')}
            onChange={handleSearchEmployee}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginLeft: '1px' }}>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
      <div className="p-0 mt-20 ">
        <List className="p-0 h-[300px] overflow-y-auto">
          {employees.map((empl) => (
            <ListItem
              key={empl.id}
              className={`px-0 space-x-8 cursor-pointer hover:bg-blue-50 border-b-1 ${
                activeEmpl === empl.id ? 'bg-blue-100 dark:bg-lightblack' : 'bg-white dark:bg-dark'
              }`}
              onClick={() => handleEmplClick(empl.id)}
            >
              <Avatar
                src={getAbsoluteFileUrl(empl?.staffImageFileUrl)}
                alt={empl.staffImageFileUrl}
              />
              <ListItemText>
                <div className="flex flex-col">
                  <Typography
                    className="text-base font-300 font-lexend dark:text-white"
                    color="primary.main"
                    paragraph={false}
                  >
                    {`${empl.fullName.surname} ${empl.fullName.name} ${empl.fullName.patronymic}`}
                  </Typography>
                </div>
              </ListItemText>
            </ListItem>
          ))}
        </List>
        {employees.length < totalEmployees && totalEmployees > rows && !activeEmpl && (
          <div className="h-10 mt-10 flex justify-center items-center">
            <Button type="text" onClick={() => setPage(page - 1)} disabled={page < 2}>
              <ChevronLeft />
            </Button>
            <span>
              {page} / {totalPages}
            </span>
            <Button type="text" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
              <ChevronRight />
            </Button>
          </div>
        )}
        {activeEmpl && (
          <div className="text-center mt-10">
            <LoadingButton
              variant="outlined"
              color="secondary"
              loading={loading}
              startIcon={<Save />}
              onClick={onLinkHrm}
            >
              {t('Save')}
            </LoadingButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default LinkHRMForm;
