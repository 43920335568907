import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const contractsApi = createApi({
  reducerPath: 'contractsApi',
  baseQuery: rtkBaseQuery('/ticket/Contract'),
  tagTypes: ['Contract'],
  endpoints: (builder) => ({
    createContract: builder.mutation({
      query: (body) => ({
        url: '/CreateContract',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Contract'],
    }),
    editContract: builder.mutation({
      query: (body) => ({
        url: '/EditContract',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Contract', id: arg.id }, 'Contract'],
    }),
    deleteContract: builder.mutation({
      query: (params) => ({
        url: '/DeleteContract',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Contract'],
    }),
    getContractList: builder.query({
      query: (body) => ({
        url: '/ContractList',
        method: 'POST',
        body,
      }),
      providesTags: ['Contract'],
    }),
    fetchContractList: builder.mutation({
      query: (body) => ({
        url: '/ContractList',
        method: 'POST',
        body,
      }),
    }),
    getContractById: builder.query({
      query: (params) => ({
        url: '/GetContractById',
        params,
      }),
      providesTags: (result, error, { id }) => [{ type: 'Contract', id }],
    }),

    getStringBase64ByIdContract: builder.mutation({
      query: (params) => ({
        url: '/GetStringBase64ByIdContract',
        method: 'POST',
        params,
      }),
      providesTags: (result, error, { id }) => [{ type: 'Contract', id }],
    }),

    bindContractToProject: builder.mutation({
      query: (params) => ({
        url: '/BindContractToProject',
        method: 'POST',
        params,
      }),
      invalidatesTags: (result, error, { contractId }) => [{ type: 'Contract', id: contractId }, 'Contract'],
    }),
    unBindContractFromProject: builder.mutation({
      query: (params) => ({
        url: '/UnBindContractFromProject',
        method: 'POST',
        params,
      }),
      invalidatesTags: (result, error, { contractId }) => [{ type: 'Contract', id: contractId }, 'Contract'],
    }),
  }),
});

export const {
  useCreateContractMutation,
  useEditContractMutation,
  useDeleteContractMutation,
  useGetContractListQuery,
  useFetchContractListMutation,
  useGetContractByIdQuery,
  useGetStringBase64ByIdContractMutation,
  useBindContractToProjectMutation,
  useUnBindContractFromProjectMutation,
} = contractsApi;
