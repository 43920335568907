import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const spApi = createApi({
  reducerPath: 'spApi',
  baseQuery: rtkBaseQuery('/sps'),
  endpoints: (builder) => ({
    getLang: builder.query({
      query: (lang) => `/Lang/LangToJSON/${lang}`,
    }),

    getSelectItems: builder.query({
      query: (tableName) => `/Selectitem/GetSelectItems/${tableName}`,
    }),

    getSelectItemsByRoot: builder.query({
      query: ({ tableName, rootId }) => `/Selectitem/GetSelectItemsByRoot/${tableName}/${rootId}`,
    }),

    getSelectItemsByCode: builder.query({
      query: ({ tableName, codeId }) => `/Selectitem/GetSelectItemsByCode/${tableName}/${codeId}`,
    }),

    getSpTablesList: builder.query({
      query: () => '/SP/SpTablesList',
    }),

    spItemsList: builder.mutation({
      query: ({ tableName, ...body }) => ({
        url: `/SP/SpItemsList/${tableName}`,
        method: 'POST',
        body,
      }),
    }),

    getSpPositions: builder.mutation({
      query: (body) => ({
        url: '/SP/SpItemsList/Positions',
        method: 'POST',
        body,
      }),
    }),

    getAllTranslations: builder.query({
      query: (params) => ({
        url: '/SP/GetAllTranslations',
        params,
      }),
    }),

    createNewTableSPS: builder.mutation({
      query: ({ tableName, ...body }) => ({
        url: `/SP/CreateNewTableSPS/${tableName}`,
        method: 'POST',
        body,
      }),
    }),

    addUpdate: builder.mutation({
      query: ({ tableName, ...body }) => ({
        url: `/SP/AddUpdate/${tableName}`,
        method: 'POST',
        body,
      }),
    }),

    updateSpPosition: builder.mutation({
      query: (body) => ({
        url: `/SP/AddUpdate/Positions`,
        method: 'POST',
        body,
      }),
    }),

    spById: builder.mutation({
      query: ({ tableName, id, ...body }) => ({
        url: `/SP/GetSpById/${tableName}/${id}`,
        method: 'POST',
        body,
      }),
    }),

    AddReference: builder.mutation({
      query: (body) => ({
        url: '/SP/CreateNewReference',
        method: 'POST',
        body,
      }),
    }),

    createLang: builder.mutation({
      query: (body) => ({
        url: 'Lang/Create',
        method: 'POST',
        body,
      }),
    }),

    updateLang: builder.mutation({
      query: (body) => ({
        url: 'Lang/Update',
        method: 'PUT',
        body,
      }),
    }),

    deleteLang: builder.mutation({
      query: ({ id }) => ({
        url: `Delete?lang=${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetLangQuery,
  useGetSelectItemsQuery,
  useGetSelectItemsByRootQuery,
  useGetSelectItemsByCodeQuery,
  useGetSpTablesListQuery,
  useSpItemsListMutation,
  useGetSpPositionsMutation,
  useGetAllTranslationsQuery,
  useCreateNewTableSPSMutation,
  useAddUpdateMutation,
  useUpdateSpPositionMutation,
  useSpByIdMutation,
  useAddReferenceMutation,
  useCreateLangMutation,
  useUpdateLangMutation,
  useDeleteLangMutation,
} = spApi;
