import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import InternalDocumentsTableHeader from './InternalDocumentsTableHeader';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import moment from 'moment';
import { INTERNAL_DOCUMENTS_CONSTANTS } from './InternalDocumentsConstants';

const InternalDocumentsTable = ({ searchText }) => {
  return (
    <div>
      <div className="grow overflow-x-auto overflow-y-auto">
        <Table
          stickyHeader
          className="min-w-xl"
          aria-labelledby="tableTitle"
          size="small"
          aria-label="a dense table"
        >
          <InternalDocumentsTableHeader />
          <TableBody>
            {INTERNAL_DOCUMENTS_CONSTANTS.filter((doc) =>
              doc.fileTitle.toLowerCase().includes(searchText.toLowerCase())
            ).map((item) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={item.id} sx={{ height: 30 }}>
                <TableCell
                  className="w-52 pl-32 text-blue-800"
                  component="th"
                  scope="row"
                  padding="none"
                >
                  <FuseSvgIcon>heroicons-outline:document-text</FuseSvgIcon>
                </TableCell>

                <TableCell component="td" scope="row">
                  <a className="no-underline text-black" href={item.contentPath}>
                    {item.fileTitle}
                  </a>
                </TableCell>

                <TableCell component="td" scope="row">
                  {moment(item.createdDate).format('DD.MM.YYYY')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default InternalDocumentsTable;
