const { createApi } = require('@reduxjs/toolkit/dist/query/react');
const { rtkBaseQuery } = require('../../helpers/rtkquery');

export const projectsApi = createApi({
  reducerPath: 'ticketProjectsApi',
  baseQuery: rtkBaseQuery('/ticket/Project'),
  tagTypes: ['Project'],
  endpoints: (builder) => ({
    createProject: builder.mutation({
      query: (body) => ({
        url: '/CreateProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    deleteProject: builder.mutation({
      query: (params) => ({
        url: '/DeleteProject',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Project'],
    }),
    getProjectById: builder.query({
      query: (params) => ({
        url: '/GetProjectById',
        params,
      }),
      providesTags: (result, error, { id }) => [{ type: 'Project', id }],
    }),
    getProjectsList: builder.query({
      query: (body) => ({
        url: '/ProjectsList',
        method: 'POST',
        body,
      }),
      providesTags: ['Project'],
    }),
    addStaffToProject: builder.mutation({
      query: (body) => ({
        url: '/AddStaffToProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],
    }),
    changeOwnerInProject: builder.mutation({
      query: (body)=>({
        url: '/ChangeOwnerInProject',
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],
    }),
    removeStaffFromProject: builder.mutation({
      query: (body) => ({
        url: '/RemoveStaffFromProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],
    }),
    changeRoleInProject: builder.mutation({
      query: (body) => ({
        url: '/ChangeRoleInProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],
    }),
    downloadGanttChart: builder.mutation({
      query: (body) => ({
        url: '/DownloadGantChart',
        method: 'POST',
        body,
        responseHandler: (response) => response.text(),
      }),
    }),
    getAllProjectList: builder.query({
      query: () => '/GetAllProjectList',
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectByIdQuery,
  useGetProjectsListQuery,
  useAddStaffToProjectMutation,
  useChangeOwnerInProjectMutation,
  useRemoveStaffFromProjectMutation,
  useChangeRoleInProjectMutation,
  useDownloadGanttChartMutation,
  useGetAllProjectListQuery,
} = projectsApi;
