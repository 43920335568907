import {Tabs, Tab, Box} from '@mui/material';
import ContractTabPanel from './ContractTabPanel';
import { useState } from 'react';
import { AttachFile, AttachMoney, Handshake } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import ContractForm from './ContractForm';
import ContractFiles from './ContractFiles';
import ContractPaymentsList from './ContractPaymentsList';
import ContractAdditionalAgreements from './ContractAdditionalAgreements';

const ContractView = ({ data, refetch, organizations, onClose }) => {
  const { t } = useTranslation('translations');
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (


    <Box sx={{ width:900, borderBottom: 1, borderColor: 'divider'}}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="icon position tabs example"
      >
        <Tab label={t('Information')} />
        <Tab icon={<AttachFile />} iconPosition="start" label={t('Files')} />
        <Tab icon={<AttachMoney/>} iconPosition="start" label={t('Payments')} />
        <Tab icon={<Handshake/>} iconPosition="start" label={t('AdditionalAgreements')} />
      </Tabs>
      <ContractTabPanel value={tabValue} index={0}>
        <ContractForm data={data} refetch={refetch} organizations={organizations} onClose={onClose}/>
      </ContractTabPanel>

      <ContractTabPanel value={tabValue} index={1}>
        <ContractFiles data={data} refetch={refetch} contractId={null}/>
      </ContractTabPanel>

      <ContractTabPanel value={tabValue} index={2}>
        <ContractPaymentsList contract={data}/>
      </ContractTabPanel>

      <ContractTabPanel value={tabValue} index={3}>
        <ContractAdditionalAgreements />
      </ContractTabPanel>
    </Box>
  );
}

export default ContractView;