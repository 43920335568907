import FuseScrollbars from '@fuse/core/FuseScrollbars';
import SkudDepartment from './SkudDepartment';
import handleError from '@fuse/core/errorHandler';
import FuseLoading from '@fuse/core/FuseLoading';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { useFetchSubordinateDepartmentsMutation } from 'src/app/services/shtatApi';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { useEffect, useState } from 'react';
import { useThemeMediaQuery } from '@fuse/hooks';
import { Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const SkudDepartments = () => {
  const { t } = useTranslation('translations');
  const user = useSelector(selectUser);
  const [fetchDepartments, { isLoading }] = useFetchSubordinateDepartmentsMutation();
  const [departments, setDepartments] = useState([]);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const getDepartments = () => {
    fetchDepartments({ superviserId: user.staffId })
      .unwrap()
      .then((res) => setDepartments(res))
      .catch(handleError);
  };

  useEffect(() => {
    if (user && user.staffId) {
      getDepartments();
    }
  }, [user]);

  if (isLoading)
    return (
      <div className="h-full flex justify-center items-center">
        <FuseLoading />
      </div>
    );

  return (
    <FusePageCarded
      header={
        <div className="card overflow-x-auto w-full">
          <div className="p-24 sm:p-32 w-full border-b-1 flex flex-row justify-between items-center">
            <div className="flex flex-col items-center sm:items-start">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-24 md:text-32 tracking-tight leading-none"
              >
                {t('SubordinatesWorkingHoursCalculation')}
              </Typography>
            </div>
          </div>
        </div>
      }
      content={
        <FuseScrollbars className="grow overflow-x-auto px-5">
          {departments.length === 0 && (
            <div className="p-32 text-lg text-center">{t('YouDontHaveSubordinates')}</div>
          )}
          {departments.map((dep) => (
            <SkudDepartment dep={dep} key={dep.id} />
          ))}
        </FuseScrollbars>
      }
      scroll={isMobile ? 'normal' : 'page'}
    />
  );
};

export default SkudDepartments;
