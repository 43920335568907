import handleError from '@fuse/core/errorHandler';
import MyAutocomplete from '@fuse/core/MyAutocomplete';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { API } from 'app/configs/api';
import { selectUser } from 'app/store/userSlice';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function AddUserStatusForm({ selectedUser, userStatusState, onClose }) {
  const { t } = useTranslation('translations');
  const [loading, setLoading] = useState(false);
  const status = useSelector(selectUser);

  const { control, handleSubmit } = useForm();

  const onSubmit = async (formValues) => {
    setLoading(true);
    try {
      formValues.userId = selectedUser.id;
      formValues.status = formValues.status.id;
      await axios.post(API.updateStatus, formValues);
      console.log(formValues);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-10">
      <MyAutocomplete
        defaultValue={null}
        control={control}
        name="status"
        label={t('UserStatus')}
        options={userStatusState}
        className="dark:bg-dark"
      />
      <LoadingButton
        loading={loading}
        variant="contained"
        color="secondary"
        className="w-full mt-24"
        type="submit"
        size="large"
      >
        {t('Save')}
      </LoadingButton>
    </form>
  );
}

export default AddUserStatusForm;
