import SimpleDialog from '@fuse/core/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import moment from 'moment';
import useMessage from 'src/app/hooks/useMessage';
import { Add, DeleteOutline, EditOutlined } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useDeletePaymentMutation,
  useGetContractPaymentsListMutation,
  useGetPaymentByIdQuery,
} from 'src/app/services/paymentsApi';
import PaymentForm from '../payment/PaymentForm';
import PaymentAmount from '../payment/PaymentAmount';
import { useProjectContext } from '../ProjectContext';

const ContractPaymentsList = ({ contract }) => {
  const { contractId } = useParams();
  const initialParams = {
    filters: JSON.stringify({ contractid: { value: contractId } }),
    first: 0,
    rows: 100,
    sortField: 'id',
    sortOrder: 0,
  };
  const { t } = useTranslation('translations');
  const { showMessage } = useMessage();

  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);

  const { data: payment } = useGetPaymentByIdQuery({ id: paymentId }, { skip: !paymentId });
  const [getContractPaymentsList] = useGetContractPaymentsListMutation();

  const [deletePayment] = useDeletePaymentMutation();
  const { isOwner } = useProjectContext();

  const getContractPayments = () => {
    getContractPaymentsList(initialParams)
      .unwrap()
      .then((res) => {
        setPaymentsList(res.items);
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (contractId) {
      getContractPayments();
    }
  }, [contractId]);

  useEffect(() => {
    if (payment) {
      setPaymentOpen(true);
    }
  }, [payment]);

  const cols = [
    {
      key: 'paymentDate',
      label: t('PaymentDate'),
      render: (row) => moment(new Date(row.paymentDate)).format('DD.MM.YYYY HH:mm'),
    },
    {
      key: 'amount',
      label: t('Sum'),
      render: (row) => <PaymentAmount data={row} />,
    },
  ];

  const handleEditPayment = (_payment) => {
    if (_payment.id === paymentId) {
      setPaymentOpen(true);
    } else {
      setPaymentId(_payment.id);
    }
  };

  const handleDeletePayment = (id) => {
    if (window.confirm(t('AreYouSureToDeletePayment'))) {
      deletePayment({ id })
        .unwrap()
        .then(() => {
          showMessage({ message: t('PaymentSuccessfullyDeleted'), autoHideDuration: 3000 });
        })
        .catch(handleError);
    }
  };

  const handleClosePayment = () => {
    setPaymentId(null);
    setPaymentOpen(false);
  };

  const handleAddPayment = (e) => {
    setPaymentId(null);
    setPaymentOpen(true);
  };

  return (
    <div className="w-full bg-white dark:bg-dark h-[600px] overflow-y-auto">
      <Table>
        <TableHead>
          <TableRow>
            {cols.map((col) => (
              <TableCell key={col.key}>{col.label}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': {
              py: 1,
            },
          }}
        >
          {paymentsList.map((row) => (
            <TableRow hover key={row.id}>
              {cols.map((col) => (
                <TableCell key={col.key}>{col.render ? col.render(row) : row[col.key]}</TableCell>
              ))}
              <TableCell>
                {isOwner && (
                  <>
                    <IconButton onClick={() => handleEditPayment(row)} size="small" color="info">
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => handleDeletePayment(row.id)}
                      size="small"
                      color="error"
                    >
                      <DeleteOutline />
                    </IconButton>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        className="mt-10"
        variant="outlined"
        color="success"
        startIcon={<Add />}
        onClick={handleAddPayment}
      >
        {t('AddPayment')}
      </Button>
      <SimpleDialog open={paymentOpen} onClose={handleClosePayment} title={t('Payment')}>
        <PaymentForm
          contract={contract || {}}
          data={payment}
          onClose={handleClosePayment}
          refetch={getContractPayments}
          paymentId={paymentId}
        />
      </SimpleDialog>
    </div>
  );
};

export default ContractPaymentsList;
