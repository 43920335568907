import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const ClaimExecutors = lazy(() => import('./ClaimExecutors'));

const ClaimExecutorsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/claimexecutors',
      element: <ClaimExecutors />,
    },
  ],
};

export default ClaimExecutorsConfig;
