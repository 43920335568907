import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const departmentsApi = createApi({
  reducerPath: 'departmentsApi',
  baseQuery: rtkBaseQuery('/staff/LocationDepartment'),
  tagTypes: ['Departments'],
  endpoints: (builder) => ({
    createDepartment: builder.mutation({
      query: (body) => ({
        url: '/Create',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Departments']
    }),
    editDepartment: builder.mutation({
      query: (body) => ({
        url: '/Update',
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Departments']
    }),
    getDepartmentsList: builder.query({
      query: () => '/GetAll',
      providesTags: ['Departments']
    }),
    getDepartmentById: builder.query({
      query: ({id}) => ({
        url: `/GetById?id=${id}`,
      })
    }),
    getDepartmentByFloorId: builder.query({
      query: ({id}) => ({
        url: `/GetDepartmentByFloorId?id=${id}`
      })
    }),
    getDepartmentByDepartmentId: builder.query({
      query: ({id}) => ({
        url: `/GetLocationDepartmentByDepartmentId?id=${id}`
      })
    }),
    deleteDepartmentById: builder.mutation({
      query: (id) => ({
        url: `/Delete?id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Departments'],
    }),
    searchDepartment: builder.mutation({
      query: (params) => ({
        url: `/SearchDepartment`,
        method: 'POST',
        params
      })
    })
  })
});

export const {
  useCreateDepartmentMutation,
  useEditDepartmentMutation,
  useGetDepartmentsListQuery,
  useGetDepartmentByIdQuery,
  useDeleteDepartmentByIdMutation,
  useGetDepartmentByFloorIdQuery,
  useGetDepartmentByDepartmentIdQuery,
  useSearchDepartmentMutation
} = departmentsApi;