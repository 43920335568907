import { Close } from '@mui/icons-material';
import { DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { useSelector } from 'react-redux';

function SimpleDialog({ title, children, open, onClose, noPadding = false }) {
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="fuse-dialog-title"
      sx={{
        minWidth: 400,
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '100%', // Set your width here
          },
        },
      }}
      classes={{
        paper: 'relative rounded-8',
      }}
    >
      <IconButton onClick={onClose} className="absolute right-16 top-16 z-10">
        <Close />
      </IconButton>

      {title && (
        <DialogTitle
          component="h5"
          sx={{
            color: isDarkMode ? '#fff' : '#111827',
            background: isDarkMode ? '#16151C' : '#fff;',
            fontSize: 18,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography className="text-3xl font-500 mr-28">{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent
        sx={{ background: isDarkMode ? '#16151C' : '#fff;', p: !noPadding ? 3 : 0, minWidth: 300 }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default SimpleDialog;
