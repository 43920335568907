import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeThemeAsync } from 'app/store/themeSlice';
import styles from './ModeTheme.module.css';
import { useUser } from 'src/app/hooks/useUser';
import JwtService from 'src/app/auth/services/jwtService';

const ModeTheme = () => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const isDarkMode = useSelector((state) => state.themeSlice.isDarkMode);

  const handleChangeTheme = async (event) => {
    const theme = event.target.checked;
    await dispatch(
      changeThemeAsync({
        userId: user.id,
        theme: +theme,
      })
    );
    await JwtService.refreshToken();
    localStorage.setItem('isDarkMode', Boolean(theme));
  };

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  return (
    <div className="flex items-center">
      <input
        className={styles.input}
        type="checkbox"
        id="darkmode-toggle"
        onClick={handleChangeTheme}
        defaultChecked={isDarkMode}
      />
      <label className={styles.label} htmlFor="darkmode-toggle">
        <svg className={styles.sun}>
          <use xlinkHref="./assets/icons/switcher/sprite.svg#sun"></use>
        </svg>
        <svg className={styles.moon}>
          <use xlinkHref="./assets/icons/switcher/sprite.svg#moon"></use>
        </svg>
      </label>
    </div>
  );
};

export default ModeTheme;
