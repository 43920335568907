import * as React from 'react';
import handleError from '@fuse/core/errorHandler';
import jwtService from './services/jwtService';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';
import {
  logoutUser,
  setIsGreetingsVisible,
  setUser,
  setUserBanner,
  setUserPhoto,
} from 'app/store/userSlice';
import { API } from 'app/configs/api';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { addMessage, updateMessagesStatuses } from 'app/store/chatStore/chatSlice';
import { setOnlineUsers } from 'app/store/chatStore/contactsSlice';
import {
  addLastMessage,
  addUnreadMessage,
  getChats,
  initializeChat,
  selectChats,
} from 'app/store/chatStore/chatsSlice';
import { getNewAlarmsCount } from 'app/store/alarmsSlice';
import { changeLanguageAsync } from 'app/store/i18nSlice';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { setTheme } from 'app/store/themeSlice';
import { getStaffList } from 'app/store/staffSlice';
import { resetNavigation } from 'app/store/fuse/navigationSlice';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const dispatch = useDispatch();
  const chatsList = useSelector(selectChats);

  useEffect(() => {
    jwtService.on('onAutoLogin', () => {
      // dispatch(showMessage({ message: 'Signing in with JWT' }));

      /**
       * Sign in and retrieve user data with stored token
       */
      jwtService
        .signInWithToken()
        .then((user) => {
          success(user, 'Welcome');
          setUserLanguage(user);
          setUserTheme(user);
        })
        .catch((error) => {
          pass(error.message);
        });
    });

    jwtService.on('onLogin', (user) => {
      success(user, 'Signed in');
    });

    jwtService.on('onLogout', () => {
      pass('Signed out');

      dispatch(resetNavigation());
      dispatch(logoutUser());
    });

    jwtService.on('onAutoLogout', (message) => {
      pass(message);

      dispatch(logoutUser());
    });

    jwtService.on('onRefreshToken', (message) => {
      jwtService
        .refreshToken()
        .then((user) => {
          success(user, 'Login information updated');
        })
        .catch((error) => {
          pass(error.message);
        });
    });

    jwtService.on('onNoAccessToken', () => {
      pass();
    });

    jwtService.init();

    async function getImage(cb, value) {
      try {
        if (value) {
          const { data } = await axios.post(API.getBase64, undefined, {
            params: { id: value },
          });
          dispatch(cb(data));
        }
      } catch (e) {
        handleError(e);
      }
    }

    const showNotification = (message) => {
      if (Notification.permission === 'granted') {
        const notification = new Notification('Новое сообщение', {
          body: message.text,
          icon: 'assets/images/alarms/chatalarm.png',
        });
        notification.onclick = () => {
          window.open(`/apps/chat${message.chatGroupId ? '/' + message.chatGroupId : ''}`, '_self');
        };
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            showNotification(message);
          }
        });
      }
    };

    const showAlarmNotification = (message) => {
      if (Notification.permission === 'granted') {
        const notification = new Notification('Оповещение', {
          body: message.text,
          icon: 'assets/images/alarms/chatalarm.png',
        });
        notification.onclick = () => {
          window.open('', '_self');
        };
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            showAlarmNotification(message);
          }
        });
      }
    };

    const connect = async () => {
      try {
        const connection = new HubConnectionBuilder()
          .withUrl(process.env.REACT_APP_BASE_SOCKET_URL, {
            accessTokenFactory: () => jwtService.getAccessToken(),
          })
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
              if (retryContext.elapsedMilliseconds < 10000) {
                // If we've been reconnecting for less than 10 seconds so far,
                // wait 2 seconds before the next reconnect attempt.
                return 2000;
              } else if (retryContext.elapsedMilliseconds < 120000) {
                // If we've been reconnecting for less than 120 seconds so far,
                // wait 10 seconds before the next reconnect attempt.
                return 10000;
              } else {
                // If we've been reconnecting more than 120 seconds ago,
                // wait 60 seconds before the next reconnect attempt.
                return 60000;
              }
            },
          })
          .configureLogging(LogLevel.Error)
          .build();

        connection.on('ReceiveMessage', (username, message) => {
          dispatch(addMessage(message));
          dispatch(addLastMessage(message));
        });

        connection.on('ReceiveMessageOffLine', (message) => {
          showNotification(message);
          dispatch(addLastMessage(message));
          dispatch(addUnreadMessage(message));
          if (!chatsList.includes(message?.chatGroupId)) {
            dispatch(getChats());
          }
        });

        connection.on('ReceiveMessageStatus', (message) => {
          dispatch(updateMessagesStatuses(message));
        });

        connection.on('UpdateMessageStatus', (message) => {
          dispatch(updateMessagesStatuses(message));
        });

        connection.on('GetOnlineUsers', (users) => {
          dispatch(setOnlineUsers(users));
          setTimeout(() => dispatch(initializeChat()), 500);
        });

        connection.on('send', (data) => {
          // console.log(data);
        });

        connection.on('connect', () => {
          console.log('chathub connected');
        });

        connection.onreconnected(() => {
          console.log('Chat reconnected: ' + moment().format('DD.MM.YYYY HH:mm:ss'));
          setTimeout(() => {
            const unsentMessages = JSON.parse(localStorage.getItem('unsentMessages'));
            if (Array.isArray(unsentMessages) && unsentMessages.length > 0) {
              unsentMessages.reverse().forEach((message) => {
                window.connection.invoke('SendGroupMessage', message);
              });
            }
            localStorage.removeItem('unsentMessages');
          }, [1000]);

          connection.invoke('EnterChat', undefined, null);
        });

        await connection.start();
        window.connection = connection;
      } catch (e) {
        console.log('Connection error!', e.message);
      }
    };

    const connectAlarmWS = async () => {
      try {
        const alarmsConnection = new HubConnectionBuilder()
          .withUrl(process.env.REACT_APP_ALARMS_SOCKET_URL, {
            accessTokenFactory: () => jwtService.getAccessToken(),
          })
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: (retryContext) => {
              if (retryContext.elapsedMilliseconds < 120000) {
                // If we've been reconnecting for less than 120 seconds so far,
                // wait 10 seconds before the next reconnect attempt.
                return 10000;
              } else {
                // If we've been reconnecting more than 120 seconds ago,
                // wait 60 seconds before the next reconnect attempt.
                return 60000;
              }
            },
          })
          .configureLogging(LogLevel.Error)
          .build();

        // alarmsConnection.onclose((e) => {
        //   console.log('alarmsConnection closed');
        // });

        alarmsConnection.on('connect', () => {
          console.log('alarmhub connected');
        });

        alarmsConnection.on('ReceiveMessage', (message) => {
          dispatch(getNewAlarmsCount());
          showAlarmNotification(message);
        });

        await alarmsConnection.start();
        window.alarmsConnection = alarmsConnection;
      } catch (e) {
        console.log('alarmsConnection error!', e.message);
      }
    };

    async function success(user, message) {
      // if (message) {
      //   dispatch(showMessage({ message }));
      // }
      Promise.all([
        dispatch(setUser(user)),
        // getImage(setUserPhoto, user.staffImageFileUrl),
        // getImage(setUserBanner, user.fileBannerId),
        dispatch(getStaffList()),
        // You can receive data in here before app initialization
      ]).then((values) => {
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
        if (
          moment(user?.profileCreatedDate).format('DD.MM.YYYY') === moment().format('DD.MM.YYYY') &&
          localStorage.getItem('isFirstTime') != 'false'
        ) {
          dispatch(setIsGreetingsVisible(true));
        }
      });

      connect();
      connectAlarmWS();
    }

    function pass(message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }

    const setUserLanguage = async (user) => {
      const langs = ['uz', 'ru'];
      const storageLanguage = langs.includes(localStorage.getItem('language'))
        ? localStorage.getItem('language')
        : 'uz';
      if (langs[user.localization] === storageLanguage) {
        return;
      } else if (!langs.includes[storageLanguage]) {
        //Language changed. Need to update language on server
        await dispatch(
          changeLanguageAsync({
            userId: user.id,
            lng: { code: langs.indexOf(storageLanguage), id: storageLanguage },
          })
        );
        // await jwtService.refreshToken();
      } else {
        //bad language detected
        localStorage.setItem('language', 'uz');
      }
    };

    const setUserTheme = async (user) => {
      const themes = ['false', 'true'];
      let storageIsDarkTheme = localStorage.getItem('isDarkMode');
      if (themes[user.theme] === storageIsDarkTheme) {
        dispatch(setTheme(user.theme === 1));
      } else if (themes.includes(storageIsDarkTheme)) {
        dispatch(setTheme(user.theme === 1));
        localStorage.setItem('isDarkMode', themes[user.theme]);
      } else {
        localStorage.setItem('isDarkMode', false);
        dispatch(setTheme(false));
      }
    };
  }, [dispatch]);

  return waitAuthCheck ? (
    <FuseSplashScreen />
  ) : (
    <AuthContext.Provider value={{ isAuthenticated }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
