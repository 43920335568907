import { Avatar } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { getAbsoluteFileUrl, renderFullName } from '../helpers/utils';

const StaffAvatar = ({ staff, type = 'doc', ...props }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    // Define a default URL based on staff?.staffFileUrl
    let newUrl = getAbsoluteFileUrl(staff?.staffFileUrl);

    // Check the type and update the URL accordingly
    if (type === 'doc') {
      // Use staff?.staffFileUrl for 'doc'
      newUrl = getAbsoluteFileUrl(staff?.staffFileUrl);
    } else if (type === 'avatar') {
      // Check if staff?.applicationUserImageFileUrl is available, and use it if it exists
      if (staff?.applicationUserImageFileUrl) {
        newUrl = getAbsoluteFileUrl(staff.applicationUserImageFileUrl);
      }
    }

    // Set the URL
    setUrl(newUrl);
  }, [type, staff]);

  const fullName = renderFullName(staff);

  return (
    <Avatar src={url} alt={fullName} title={fullName} {...props}>
      {fullName &&
        fullName
          .split(' ')
          .slice(0, 2)
          .map((name) => name[0])
          .join('')}
    </Avatar>
  );
};

export default memo(StaffAvatar);
