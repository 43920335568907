import handleError from '@fuse/core/errorHandler';
import moment from 'moment';
import { Button, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useCreatePaymentMutation,
  useEditPaymentMutation,
  useFetchCurrencyMutation,
  useGetPaymentByIdQuery,
} from 'src/app/services/paymentsApi';
import { useGetAllProjectListQuery } from 'src/app/services/ticket/projectsApi';
import { CURRENCY_TYPE, CurrencyTypes } from '../vars';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showMessage } from 'app/store/fuse/messageSlice';

const PaymentForm = ({ contract = { id: null, projectIds: [], projectPayments: [] }, onClose, refetch, paymentId }) => {
  const {t} = useTranslation('translations');
  const { id: contractId, projectIds } = contract;
  const [incorrectValues, setIncorrectValues] = useState(false);
  const [rate, setRate] = useState(1);
  const { data: projectsData } = useGetAllProjectListQuery();
  const [getExchangeRate] = useFetchCurrencyMutation();
  const [createPayment] = useCreatePaymentMutation();
  const [editPayment] = useEditPaymentMutation();
  const { data } = useGetPaymentByIdQuery({ id: paymentId }, { skip: !paymentId });

  const dispatch = useDispatch();

  const query = data ? editPayment : createPayment;

  const projects = projectsData?.filter((p) => projectIds?.includes(p.key));

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    register,
    setValue,
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      id: 0,
      contractId,
      paymentDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
      amount: 0,
      сurrencyType: +CURRENCY_TYPE.UZS,
      projectPayments: projects?.map((p) => ({ projectId: p.key, amount: 0, id: 0, contractPaymentId: 0 })) || [],
      ...data,
    },
  });

  const onSubmit = (values) => {
    const sum = values.projectPayments.reduce((acc, p) => acc + +p.amount, 0);

    if (sum !== +values.amount) {
      setIncorrectValues(true);
      return;
    }

    query({ ...values, amount: +values.amount })
      .unwrap()
      .then((res) => {
        reset();
        const message = data ? t('PaymentSuccessfullyEdited'): t('PaymentSuccessfullyAdded');
        dispatch(showMessage({message, variant:'success'}));
        setIncorrectValues(false);
        refetch();
        onClose();
      })
      .catch((err)=>{
        handleError(err);
        const message = data ? t('PaymentEditingError'): t('PaymentAddingError');
        dispatch(showMessage({message, variant:'error'}));
      });
  };

  const amount = watch('amount');
  const currency = watch('сurrencyType');

  useEffect(() => {
    if (currency !== CURRENCY_TYPE.UZS)
      getExchangeRate({ date: moment(new Date()).format('YYYY-MM-DD'), currency })
        .unwrap()
        .then((res) => setRate(+res[0].rate))
        .catch(handleError);
  }, [currency]);

  useEffect(() => {
    const projectPayments = [...getValues('projectPayments')];
    projectPayments?.forEach((p, index) => {
      setValue(`projectPayments[${index}].amount`, +(+amount / projectPayments.length).toFixed(2));
    });
  }, [amount]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-16 pt-8 min-w-320 max-w-480">
      <Controller
        name="paymentDate"
        control={control}
        render={({ field }) => (
          <DateTimePicker
            {...field}
            disableMaskedInput
            value={field.value ? new Date(field.value) : null}
            onChange={(val) => setValue('paymentDate', moment(val).format('YYYY-MM-DDTHH:mm:ss'))}
            sx={{ width: 1 }}
            slotProps={{
              textField: {
                size: 'small',
              },
            }}
          />
        )}
      />
      <div className="flex gap-16">
        <Controller
          control={control}
          name="amount"
          render={({ field }) => <TextField {...field} label={t('Sum')} size="small" className="flex-grow" />}
        />
        <Controller
          control={control}
          name="сurrencyType"
          render={({ field }) => (
            <TextField {...field} select label={t('Currency')} size="small" classes={{ input: 'flex items-center' }}>
              {Object.entries(CurrencyTypes).map(([value, { label }]) => (
                <MenuItem key={value} value={+value}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </div>
      { projects && projects.length>1 &&
        <div className="flex flex-col gap-8">
          <Typography variant="subtitle1">{t('DistributionByProjects')}</Typography>
          {projects?.map((project, index) => (
            <div key={project.key} className="flex items-center gap-16">
              <input type="hidden" {...register(`projectPayments[${index}].id`)} />
              <input type="hidden" {...register(`projectPayments[${index}].projectId`)} />
              <input type="hidden" {...register(`projectPayments[${index}].contractPaymentId`)} />
              <Typography variant="subtitle2 w-1/2">{project.value}</Typography>
              <Controller
                control={control}
                name={`projectPayments[${index}].amount`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t('Sum')}
                    size="small"
                    className="flex-grow"
                    helperText={`~${(field.value * +rate).toFixed(2)} UZS`}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{CurrencyTypes[currency].label}</InputAdornment>,
                    }}
                  />
                )}
              />
            </div>
          ))}
        </div>
      }
      {incorrectValues && <Typography color="error">{t('WrongMoneyDistributionByProjects')}</Typography>}

      <Button type="submit" variant="contained" color='secondary'>
        {t('Save')}
      </Button>
    </form>
  );
};

export default PaymentForm;
