import fuse from './fuse';
import i18n from './i18nSlice';
import user from './userSlice';
import alarms from './alarmsSlice';
import staffSlice from './staffSlice';
import floorsSlice from '../main/apps/map/store/floorsSlice';
import themeSlice from './themeSlice';
import chatStore from './chatStore';
import { combineReducers } from '@reduxjs/toolkit';
import { accountApi } from '../services/accountApi';
import { articlesApi } from '../services/articlesApi';
import { contractsApi } from '../services/contractsApi';
import { paymentsApi } from '../services/paymentsApi';
import { shtatApi } from '../services/shtatApi';
import { skudApi } from '../services/skudApi';
import { spApi } from '../services/spApi';
import { staffApi } from '../services/staffApi';
import { projectsApi } from '../services/ticket/projectsApi';
import { ticketsApi } from '../services/ticketsApi';
import { chatApi } from '../services/chatApi';
import { buildingsApi } from '../services/buildingsApi';
import { floorApi } from '../services/floorApi';
import { departmentsApi } from '../services/deparmentsApi';
import { resourceApi } from '../services/resourceApi';
import { claimApi } from '../services/claim/claimApi';
import { claimTypeApi } from '../services/claim/claimTypeApi';
import { claimExecutorApi } from '../services/claim/claimExecutorApi';
import { claimStatusApi } from '../services/claim/claimStatusApi';
import { rolesApi } from '../services/rolesApi';
import { adApi } from '../services/adApi';

const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    fuse,
    i18n,
    user,
    alarms,
    staffSlice,
    floorsSlice,
    themeSlice,
    chatStore,
    [accountApi.reducerPath]: accountApi.reducer,
    [adApi.reducerPath]: adApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [shtatApi.reducerPath]: shtatApi.reducer,
    [spApi.reducerPath]: spApi.reducer,
    [staffApi.reducerPath]: staffApi.reducer,
    [ticketsApi.reducerPath]: ticketsApi.reducer,
    [articlesApi.reducerPath]: articlesApi.reducer,
    [skudApi.reducerPath]: skudApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [contractsApi.reducerPath]: contractsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    [resourceApi.reducerPath]: resourceApi.reducer,
    [buildingsApi.reducerPath]: buildingsApi.reducer,
    [floorApi.reducerPath]: floorApi.reducer,
    [departmentsApi.reducerPath]: departmentsApi.reducer,
    [claimApi.reducerPath]: claimApi.reducer,
    [claimTypeApi.reducerPath]: claimTypeApi.reducer,
    [claimExecutorApi.reducerPath]: claimExecutorApi.reducer,
    [claimStatusApi.reducerPath]: claimStatusApi.reducer,
    ...asyncReducers,
  });

  /*
	Reset the redux store when user logged out
	 */
  if (action.type === 'user/userLoggedOut') {
    // state = undefined;
  }

  return combinedReducer(state, action);
};

export default createReducer;
