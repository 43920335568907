import { lazy } from 'react';

import { authRoles } from 'src/app/auth';
import i18next from 'i18next';

const PresentationApp = lazy(() => import('./Presentation'));

const PresentationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/presentation',
      element: <PresentationApp />,
    },
  ],
}

export default PresentationConfig;
