import { capitalize } from 'lodash';
import { FILE_BASE_URL } from '../constants/vars';

export const renderFullName = (staff, toCapitalize = false) => {
  if (toCapitalize)
    return [
      capitalize(staff?.fullName_Surname),
      capitalize(staff?.fullName_Name),
      capitalize(staff?.fullName_Patronymic),
    ].join(' ');
  return [staff?.fullName_Surname, staff?.fullName_Name, staff?.fullName_Patronymic].join(' ');
};

export const normalizeFIO = (fio) => {
  return fio
    .replace(/\s+/g, ' ')
    .split(' ')
    .reduce((acc, cur) => {
      if (cur === 'O‘G‘LI' || cur === 'UG‘LI' || cur === 'KIZI' || cur === 'QIZI') {
        return acc + ' ' + cur.toLowerCase();
      } else {
        return acc + ' ' + cur[0] + cur.slice(1).toLowerCase();
      }
    }, '');
};
export const getAbsoluteFileUrl = (url) => url && FILE_BASE_URL + url;

export const formatFilters = (params) => {
  return { ...params, filters: JSON.stringify(params.filters) };
};

export const capitalizeFirstLetter = (str) => {
  if (!str) {
    return '';
  } else {
    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
  }
};

export const getUserDeviceInfo = (userAgent, elements) => {
  let browserList = {
      Chrome: [/Chrome\/(\S+)/],
      Firefox: [/Firefox\/(\S+)/],
      MSIE: [/MSIE (\S+);/],
      Opera: [/Opera\/.*?Version\/(\S+)/, /Opera\/(\S+)/],
      Safari: [/Version\/(\S+).*?Safari\//],
    },
    re,
    m,
    userBrowser,
    version;
  let osList = {
      Windows: [/.*(Windows).*/],
      Linux: [/Linux\/(\S+)/],
      Mac: [/Mac\/(\S+)/],
    },
    re2,
    m2,
    userOS;

  if (userAgent === undefined) userAgent = navigator.userAgent;
  if (elements === undefined) elements = 2;
  else if (elements === 0) elements = 1337;
  for (const browser in browserList) {
    while ((re = browserList[browser].shift())) {
      if ((m = userAgent.match(re))) {
        version = m[1].match(new RegExp('[^.]+(?:.[^.]+){0,' + --elements + '}'))[0];
        userBrowser = browser;
      }
    }
  }
  for (const os in osList) {
    while ((re2 = osList[os].shift())) {
      if ((m2 = userAgent.match(re2))) {
        userOS = os;
      }
    }
  }
  return `OS: ${userOS}, Browser: ${userBrowser} ${version ? 'version ' + version : ''}`;
};

export const copyTextToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text);
    return 'copied';
  } catch (err) {
    console.error(err);
    return err;
  }
};
