const { showMessage } = require('app/store/fuse/messageSlice');
const { useDispatch } = require('react-redux');

/**
 * Custom hook for displaying messages.
 * @props {
 *  message: string, // message text
 *  autoHideDuration?: number, // auto hide duration in milliseconds
 *  variant?: string, // 'success' | 'error' | 'info' | 'warning'
 * }
 */
const useMessage = () => {
  const dispatch = useDispatch();
  return {
    showMessage: (props) => dispatch(showMessage(props)),
  };
};

export default useMessage;
