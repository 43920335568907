import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const claimTypeApi = createApi({
  reducerPath: 'claimTypeApi',
  baseQuery: rtkBaseQuery('/claim/ClaimType'),
  tagTypes: ['ClaimTypes'],
  endpoints: (builder) => ({
    getAllClaimTypes: builder.query({
      query: () => ({
        url: '/GetAll',
      }),
      providesTags: ['ClaimTypes'],
    }),
    getClaimTypeById: builder.query({
      query: (id) => ({
        url: '/GetById',
      }),
      providesTags: (result, error, { id }) => [{ type: 'ClaimTypes', id }],
    }),
    createClaimType: builder.mutation({
      query: ({ name }) => ({
        url: `/Create?name=${name}`,
        method: 'POST',
      }),
      invalidatesTags: ['ClaimTypes'],
    }),
    updateClaimType: builder.mutation({
      query: ({ id, name }) => ({
        url: '/Update',
        method: 'PUT',
        params: { id, name },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ClaimTypes', id }, 'ClaimTypes'], //Need to check if invalidating is working
    }),
    deleteClaimType: builder.mutation({
      query: (id) => ({
        url: `/Delete?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ClaimTypes'],
    }),
  }),
});

export const {
  useGetAllClaimTypesQuery,
  useGetClaimTypeByIdQuery,
  useCreateClaimTypeMutation,
  useUpdateClaimTypeMutation,
  useDeleteClaimTypeMutation,
} = claimTypeApi;
