import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const staffApi = createApi({
  reducerPath: 'staffApi',
  baseQuery: rtkBaseQuery('/staff/Staff'),
  providesTags: ['Stafflist', 'orgChart'],
  endpoints: (builder) => ({
    staffList: builder.mutation({
      query: ({ name, ...body }) => {
        return {
          url: `/StaffsList${name ? '?name=' + name : ''}`,
          method: 'POST',
          body,
        };
      },
    }),
    getStaffList: builder.query({
      query: () => '/StaffsListAll',
      providesTags: ['Stafflist'],
      // transformResponse: (response) => response.items,
    }),
    getListOfStaffs: builder.mutation({
      query: (params) => ({
        url: '/GetStaffsList',
        method: 'POST',
        params,
      }),
    }),
    staffBirthdayListByDate: builder.mutation({
      query: (params) => ({
        url: '/StaffBirthdayListByDate',
        method: 'POST',
        params,
      }),
    }),
    getStaffBdayToday: builder.mutation({
      query: () => ({
        url: '/StaffBirthdayListByDate',
        method: 'POST',
        params: { date: new Date().toISOString() },
      }),
    }),
    getStaffFromHRM: builder.mutation({
      query: (body) => ({
        url: '/getStaffFromHRM',
        method: 'POST',
        body,
      }),
    }),
    getTemplateFileList: builder.query({
      query: () => '/GetTemplateFileList',
    }),
    staffById: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/StaffById/${id}`,
        method: 'POST',
        body,
      }),
    }),
    onSaveStaff: builder.mutation({
      query: (body) => ({
        url: '/onSaveStaff',
        method: 'POST',
        body,
      }),
    }),
    onSaveProfile: builder.mutation({
      query: (body) => ({
        url: '/onSaveProfile',
        method: 'POST',
        body,
      }),
    }),
    getOrgChart: builder.query({
      query: () => '/GetOrgChart',
      providesTags: ['orgChart'],
    }),
    getOrgUnitList: builder.query({
      query: () => '/GetOrgUnitList',
    }),
    createOrgUnit: builder.mutation({
      query: (body) => ({
        url: '/CreateOrgUnit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['orgChart'],
    }),
    deleteOrgUnit: builder.mutation({
      query: (id) => ({
        url: `/DeleteOrgUnit?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['orgChart'],
    }),
    editOrgUnit: builder.mutation({
      query: (body) => ({
        url: '/EditOrgUnit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['orgChart'],
    }),
    moveOrgUnit: builder.mutation({
      query: ({ orgUnitId, move }) => ({
        url: `/MoveOrgUnit?orgUnitId=${orgUnitId}&move=${move}`,
        method: 'POST',
      }),
      invalidatesTags: ['orgChart'],
    }),
    getOrgUnitListDepartmentsOnly: builder.query({
      query: () => '/GetOrgUnitListDepartmentsOnly',
    }),
    createScheduleOfStaff: builder.mutation({
      query: (body) => ({
        url: '/CreateScheduleOfStaff',
        method: 'POST',
        body,
      }),
    }),
    deleteScheduleOfStaff: builder.mutation({
      query: (body) => ({
        url: '/DeleteScheduleOfStaff',
        method: 'POST',
        body,
      }),
    }),
    editScheduleOfStaff: builder.mutation({
      query: (body) => ({
        url: '/EditScheduleOfStaff',
        method: 'POST',
        body,
      }),
    }),
    getScheduleOfStaff: builder.query({
      query: () => '/GetScheduleOfStaff',
    }),
    getBase64ImageById: builder.mutation({
      query: (id) => ({
        url: `/GetStringBase64ById?id=${id}`,
        method: 'POST',
      }),
    }),
    getBase64VideoById: builder.mutation({
      query: (id) => ({
        url: `/GetStringBase64ByIdVideo?id=${id}`,
        method: 'POST',
      }),
    }),
    uploadFileForDb: builder.mutation({
      query: (body) => ({
        url: '/UploadFileForDb',
        method: 'POST',
        body,
      }),
    }),
    uploadFileForFolder: builder.mutation({
      query: (body) => ({
        url: '/UploadFileForFolder',
        method: 'POST',
        body,
      }),
    }),
    getAllSkills: builder.query({
      query: () => ({
        url: '/GetSkillList',
      }),
    }),
    addSkillsToUser: builder.mutation({
      query: (body) => ({
        url: '/AddSkillToStaff',
        method: 'POST',
        body,
      }),
    }),
    getUpcomingBirthdays: builder.query({
      query: () => ({ url: 'GetUpcomingEmployeeBirthdayList' }),
    }),
    getTodayBirthdays: builder.query({
      query: () => ({ url: 'GetTodayEmployeeBirthdayList' }),
    }),
    getPhotoFromHrm: builder.query({
      query: ({ staffId }) => ({
        url: `GetPhotoFromHrm?staffId=${staffId}`,
      }),
    }),
  }),
});

export const {
  useStaffListMutation,
  useGetStaffListQuery,
  useGetListOfStaffsMutation,
  useStaffBirthdayListByDateMutation,
  useGetTemplateFileListQuery,
  useStaffByIdMutation,
  useOnSaveStaffMutation,
  useOnSaveProfileMutation,
  useGetOrgChartQuery,
  useGetOrgUnitListQuery,
  useCreateOrgUnitMutation,
  useDeleteOrgUnitMutation,
  useEditOrgUnitMutation,
  useMoveOrgUnitMutation,
  useGetOrgUnitListDepartmentsOnlyQuery,
  useCreateScheduleOfStaffMutation,
  useDeleteScheduleOfStaffMutation,
  useEditScheduleOfStaffMutation,
  useGetScheduleOfStaffQuery,
  useGetBase64ImageByIdMutation,
  useGetBase64VideoByIdMutation,
  useUploadFileForDbMutation,
  useUploadFileForFolderMutation,
  useGetStaffFromHRMMutation,
  useGetAllSkillsQuery,
  useAddSkillsToUserMutation,
  useGetStaffBdayTodayMutation,
  useGetUpcomingBirthdaysQuery,
  useGetTodayBirthdaysQuery,
  useGetPhotoFromHrmQuery,
} = staffApi;
