import { createApi } from '@reduxjs/toolkit/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: rtkBaseQuery('/auth/Chat'),
  endpoints: (builder) => ({
    getChatGroups: builder.query({
      query: (params) => ({
        url: '/GetChatGroupList',
        params
      }),
    }),

    getChatGroupByProjectId: builder.query({
      query: ({userId})=>({
        url: `/GetChatGroupList?userId=${userId}`,
      }),
      transformResponse: (response, meta, arg) => response.filter(res=>response.name===Number(arg.projectId))
    })
  }),
});

export const {
  useGetChatGroupsQuery,
  useGetChatGroupByProjectIdQuery
} = chatApi;
