import InternalDocumentsTable from './InternalDocumentsTable';
import FusePageCarded from '@fuse/core/FusePageCarded/FusePageCarded';
import { useThemeMediaQuery } from '@fuse/hooks';
import { IconButton, Input, InputAdornment, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Close, Search } from '@mui/icons-material';

const InternalDocuments = () => {
  const { t } = useTranslation('translations');
  const [searchText, setSearchText] = useState('');
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <FusePageCarded
      header={
        <div className="card overflow-x-auto w-full">
          <div className="px-12 xl:px-4 py-24 w-full">
            <Typography
              component={motion.span}
              initial={{ x: -20 }}
              animate={{ x: 0, transition: { delay: 0.2 } }}
              delay={300}
              className="text-24 md:text-32 tracking-tight leading-none"
            >
              {t('InternalDocuments')}
            </Typography>
            <div className="flex items-center justify-between gap-10 mt-10">
              <Input
                placeholder={t('Search')}
                className="px-12 border-1 rounded-8"
                disableUnderline
                fullWidth
                value={searchText}
                inputProps={{
                  'aria-label': 'Поиск',
                }}
                onChange={(ev) => setSearchText(ev.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    {searchText.length > 0 && (
                      <IconButton
                        aria-label="search-document-title"
                        onClick={() => setSearchText('')}
                        edge="end"
                      >
                        <Close />
                      </IconButton>
                    )}
                  </InputAdornment>
                }
              />
            </div>
          </div>
        </div>
      }
      content={<InternalDocumentsTable searchText={searchText} />}
      scroll={isMobile ? 'normal' : 'page'}
    />
  );
};

export default InternalDocuments;
