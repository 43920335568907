import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const adApi = createApi({
  reducerPath: 'adApi',
  baseQuery: rtkBaseQuery('/auth/AD'),
  endpoints: (builder) => ({
    unbindUser: builder.mutation({
      query: ({userId}) => ({
        url: `/UnbindUser?userId=${userId}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
 useUnbindUserMutation
} = adApi;
