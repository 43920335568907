import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const DepartmentList = lazy(() => import('./DepartmentList'));

const DepartmentListConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.hr,
  routes: [
    {
      path: 'hr/departments',
      element: <DepartmentList />,
    },
  ],
};

export default DepartmentListConfig;
