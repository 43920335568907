import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useGetClaimByIdQuery, useUpdateClaimMutation } from 'src/app/services/claim/claimApi';
import { DialogTitle, IconButton } from '@mui/material';
import ClaimStaffCard from './ClaimStaffCard';
import useMessage from 'src/app/hooks/useMessage';
import handleError from '@fuse/core/errorHandler';
import { Close } from '@mui/icons-material';

const ClaimDialog = ({ id, isOpen, onClose, refetch, isViewMode = false }) => {
  const { t } = useTranslation('translations');
  const { data } = useGetClaimByIdQuery({ id }, { skip: !id });
  const [update] = useUpdateClaimMutation();
  const { showMessage } = useMessage();
  const claim = data ? data.data : null;

  const updateClaim = (statusId) => {
    update({ claimId: id, statusId })
      .unwrap()
      .then(() => {
        refetch();
        onClose();
        showMessage({
          message: t('SuccessfullySaved'),
          variant: 'success',
        });
      })
      .catch(handleError);
  };

  const handleStartExecution = () => {
    updateClaim(2);
  };

  const handleExecute = () => {
    updateClaim(7);
  };

  const handleReject = () => {
    updateClaim(6);
  };

  const handleDefer = () => {
    updateClaim(5);
  };

  const handleClose = (_event, reason) => {
    if (reason === 'backdropClick') {
      return;
    } else {
      onClose();
    }
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleClose}>
      <DialogTitle>
        <div className="flex justify-between">
          {t('ClaimProcessing')}
          <IconButton aria-label="close" onClick={onClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="pb-0">
        <div className="pb-20 flex flex-col gap-14">
          <div>
            <p className="text-12 text-grey">{t('Creator')}</p>
            {claim?.staffId > 0 && <ClaimStaffCard staffId={claim.staffId} />}
            {claim?.staffId === 0 && (
              <p className="text-14 text-red">{t('ImpossibleToIdentify')}</p>
            )}
          </div>
          <div>
            <p className="text-12 text-grey">{t('Status')}</p>
            <p className="text-14">{claim ? t(claim.statusName) : '-'}</p>
          </div>
          <div>
            <p className="text-12 text-grey">{t('Type')}</p>
            <p className="text-14">{claim ? t(claim.claimTypeName) : '-'}</p>
          </div>
          <div>
            <p className="text-12 text-grey">{t('Subject')}</p>
            <p className="text-14">{claim?.subject}</p>
          </div>
          <div>
            <p className="text-12 text-grey">{t('Text')}</p>
            <p className="text-14">{claim?.message}</p>
          </div>

          {!!claim?.comments?.length && (
            <div>
              <p className="text-12 text-grey">{t('Comments')}</p>
              <div className="text-14">
                {claim.comments.map((comment) => (
                  <p>{comment.content}</p>
                ))}
              </div>
            </div>
          )}

          {isViewMode ||
            (claim && claim.statusId !== 7 && (
              <div className="mt-28 flex items-center justify-end gap-10 flex-wrap">
                <button
                  onClick={handleStartExecution}
                  type="button"
                  className="text-white text-lg border-[1px] bg-[#4676fc] border-[#E6E5E7] rounded-12 p-12"
                >
                  {t('StartExecution')}
                </button>
                <button
                  onClick={handleExecute}
                  type="button"
                  className="text-white text-lg border-[1px] bg-[#27a542] border-[#E6E5E7] rounded-12 p-12"
                >
                  {t('Execute')}
                </button>
                <button
                  onClick={handleDefer}
                  type="button"
                  className="text-[#ffffff] text-lg border-[1px] bg-[#ff9a24] border-[#E6E5E7] rounded-12 p-12"
                >
                  {t('Defer')}
                </button>
                <button
                  onClick={handleReject}
                  type="button"
                  className="text-[#ffffff] text-lg border-[1px] bg-[#f05454] border-[#E6E5E7] rounded-12 p-12"
                >
                  {t('Reject')}
                </button>
              </div>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ClaimDialog;
