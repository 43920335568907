import { useTranslation } from "react-i18next";

const ContractAdditionalAgreements = () => {
  const {t} = useTranslation('translations');

  return(
    <div className="h-[600px] overflow-y-auto">
      <div className="flex justify-center">
        {t('NoAdditionalAgreements')}
      </div>
    </div>
  )
}

export default ContractAdditionalAgreements;