import _ from 'lodash';
import SimpleDialog from '@fuse/core/SimpleDialog';
import ContractForm from './ContractForm';
import ContractTourHelp from './ContractTour';
import ContractRow from './ContractRow';
import FuseLoading from '@fuse/core/FuseLoading';
import ContractView from './ContractView';
import { Add } from '@mui/icons-material';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { QuestionOutlined } from '@ant-design/icons';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetContractByIdQuery, useGetContractListQuery } from 'src/app/services/contractsApi';
import { useGetAllOrganizationsQuery } from 'src/app/services/ticketsApi';
import { useThemeMediaQuery } from '@fuse/hooks';

const ContractList = () => {
  const { projectId, contractId } = useParams();
  const { t } = useTranslation('translations');
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openTour, setOpenTour] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const ref1 = useRef(null);

  const { data, refetch, isLoading } = useGetContractByIdQuery(
    { id: contractId },
    { skip: !contractId }
  );
  const { data: organizations } = useGetAllOrganizationsQuery();
  const { data: contracts } = useGetContractListQuery(
    {
      filters: JSON.stringify({ projectId: { value: projectId } }),
      first: 0,
      rows: 99,
    },
    { skip: !projectId }
  );

  useEffect(() => {
    if (contractId) setOpen(true);
  }, [contractId]);

  const handleClose = () => {
    setOpen(false);
    navigate('..');
  };

  const handleAddContract = () => {
    setOpen(true);
  };

  return (
    <div className="w-full">
      <Table className="bg-white dark:bg-dark rounded-8">
        <TableHead>
          <TableRow>
            <TableCell>{t('ContractNumber')}</TableCell>
            <TableCell>{t('LinkedProjects')}</TableCell>
            <TableCell>{t('ContractDate')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': {
              padding: '8px 16px',
            },
          }}
        >
          {contracts?.items &&
            contracts.items.map((contract) => <ContractRow key={contract.id} data={contract} />)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={6}>
              <Button
                ref={ref1}
                variant="outlined"
                color="success"
                startIcon={<Add />}
                onClick={handleAddContract}
              >
                {t('AddContract')}
              </Button>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <SimpleDialog
        open={open}
        onClose={handleClose}
        title={contractId ? t('EditingContract') : t('NewContract')}
      >
        {' '}
        {isLoading && <FuseLoading />}
        {!contractId && (
          <ContractForm refetch={refetch} organizations={organizations} onClose={handleClose} />
        )}
        {contractId && data && (
          <ContractView
            data={data}
            refetch={refetch}
            organizations={organizations}
            onClose={handleClose}
          />
        )}
      </SimpleDialog>
      <ContractTourHelp onClose={() => setOpenTour(false)} onOpen={openTour} ref1={ref1} />
      {!isMobile && (
        <IconButton
          color="info"
          sx={{ border: 1 }}
          onClick={() => setOpenTour(true)}
          className="fixed bottom-48 right-24"
        >
          <QuestionOutlined />
        </IconButton>
      )}
    </div>
  );
};

export default ContractList;
