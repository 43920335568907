import { configureStore } from '@reduxjs/toolkit';
import { accountApi } from '../services/accountApi';
import { rolesApi } from '../services/rolesApi';
import { articlesApi } from '../services/articlesApi';
import { contractsApi } from '../services/contractsApi';
import { paymentsApi } from '../services/paymentsApi';
import { shtatApi } from '../services/shtatApi';
import { skudApi } from '../services/skudApi';
import { spApi } from '../services/spApi';
import { staffApi } from '../services/staffApi';
import { projectsApi } from '../services/ticket/projectsApi';
import { ticketsApi } from '../services/ticketsApi';
import { chatApi } from '../services/chatApi';
import { resourceApi } from '../services/resourceApi';
import { buildingsApi } from '../services/buildingsApi';
import { floorApi } from '../services/floorApi';
import { departmentsApi } from '../services/deparmentsApi';
import { claimApi } from '../services/claim/claimApi';
import { claimTypeApi } from '../services/claim/claimTypeApi';
import { claimExecutorApi } from '../services/claim/claimExecutorApi';
import { claimStatusApi } from '../services/claim/claimStatusApi';
import createReducer from './rootReducer';
import { rtkQueryErrorLogger } from '../helpers/rtkquery';
import { adApi } from '../services/adApi';

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const middlewares = [
  rtkQueryErrorLogger,
  accountApi.middleware,
  adApi.middleware,
  rolesApi.middleware,
  shtatApi.middleware,
  spApi.middleware,
  staffApi.middleware,
  ticketsApi.middleware,
  articlesApi.middleware,
  skudApi.middleware,
  projectsApi.middleware,
  contractsApi.middleware,
  paymentsApi.middleware,
  chatApi.middleware,
  resourceApi.middleware,
  buildingsApi.middleware,
  floorApi.middleware,
  claimApi.middleware,
  claimTypeApi.middleware,
  claimExecutorApi.middleware,
  claimStatusApi.middleware,
  departmentsApi.middleware,
];

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

export default store;
