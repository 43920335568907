import { ResponsivePie } from '@nivo/pie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const NivoPieChart = ({ onClick, data = data }) => {
  const { t } = useTranslation('translations');
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  return (
    <ResponsivePie
      data={data}
      onClick={onClick}
      margin={{ top: 40, right: 90, bottom: 80, left: 90 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      tooltip={(e) => (
        <div className="bg-white dark:bg-lightblack rounded px-4">{`${t(e.datum.label)} (${
          e.datum.value
        }%)`}</div>
      )}
      borderWidth={1}
      colors={['#11a9ba', '#ff1200', '#ffb700']}
      colorBy="index"
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      arcLinkLabelsSkipAngle={20}
      arcLabel={(d) => d.value + '%'}
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLinkLabel={(e) => t(e.label)}
      arcLabelsSkipAngle={10}
      arcLinkLabelsOffset={-10}
      arcLinkLabelsTextColor={isDarkMode ? '#fff' : '#16151C'}
      arcLabelsTextColor={isDarkMode ? '#fff' : '#16151C'}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'ruby',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'earlyleaving',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'normal',
          },
          id: 'lines',
        },
      ]}
      legends={[]}
    />
  );
};

export default NivoPieChart;
