import moment from 'moment';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useGetAllClaimsQuery } from 'src/app/services/claim/claimApi';
import { Button } from 'antd';
import FuseLoading from '@fuse/core/FuseLoading';
import ClaimDialog from '../../../claims/components/ClaimDialog';
import './ScrollbarProfile.css';

const rows = 5;

const ProfileClaims = () => {
  const { t } = useTranslation('translations');
  const [page, setPage] = useState(1);
  const [selectedClaimId, setSelectedClaimId] = useState(null);
  const { data: claims, isLoading } = useGetAllClaimsQuery({ page, rows });

  const totalPages = useMemo(() => {
    return Math.ceil(claims?.data?.totalItems / rows);
  }, [claims?.data?.totalItems]);

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-24">
      <h4 className="font-inter font-600 text-2xl text-[#16151c] dark:text-white">
        {t('MyClaims')}
      </h4>
      <div className="mt-24 flex flex-col overflow-x-scroll scrollbar-horizontal max-h-[480px]">
        <div className="flex items-center justify-between sticky top-0 bg-white dark:bg-dark">
          <h5 className="text-lg font-inter min-w-[120px] leading-6 text-[#818181] dark:text-darkgrey  py-10 border-b-1 border-[#EDEEF3] grow">
            {t('ClaimType')}
          </h5>
          <h5 className="text-lg font-inter min-w-[120px] leading-6 text-[#818181] dark:text-darkgrey  py-10 border-b-1 border-[#EDEEF3] grow">
            {t('Subject')}
          </h5>
          <h5 className="text-lg font-inter min-w-[130px] leading-6 text-[#818181] dark:text-darkgrey py-10 border-b-1 border-[#EDEEF3] grow">
            {t('CreatedDate')}
          </h5>
          <h5 className="text-lg font-inter min-w-[130px] pl-10 leading-6 text-[#818181] dark:text-darkgrey py-10 border-b-1 border-[#EDEEF3] grow">
            {t('Status')}
          </h5>
        </div>
        <div className="grow">
          <ul>
            {claims?.data?.items?.map((claim) => (
              <li
                key={claim.id}
                className="flex items-center cursor-pointer hover:bg-blue-50 dark:hover:bg-darkblue"
                onClick={() => setSelectedClaimId(claim.id)}
              >
                <p className="py-16 font-inter text-lg font-400 basis-1/4 min-w-[120px]">
                  {t(claim.claimTypeName)}
                </p>
                <p className="py-16 lg:pl-24 font-inter text-lg dark:text-white font-400 basis-1/4 min-w-[120px]">
                  {claim.subject}
                </p>
                <p className="py-16 lg:pl-6 font-inter text-lg dark:text-white font-400 basis-1/4 min-w-[120px]">
                  {moment(claim.createTime).format('LL')}
                </p>
                <p className="py-16 lg:pl-24 font-inter text-lg dark:text-white font-400 basis-1/4 min-w-[120px]">
                  {t(claim.status)}
                </p>
              </li>
            ))}
          </ul>
        </div>
        {claims?.data?.totalItems > 0 && (
          <div className="mt-20 flex justify-center items-center">
            <Button
              type="text"
              onClick={() => setPage(page - 1)}
              disabled={page < 2}
              className="dark:text-white"
            >
              <ChevronLeft />
            </Button>
            <span>
              {page} / {totalPages}
            </span>
            <Button
              type="text"
              onClick={() => setPage(page + 1)}
              disabled={page == totalPages}
              className="dark:text-white"
            >
              <ChevronRight />
            </Button>
          </div>
        )}
      </div>
      <ClaimDialog
        isOpen={!!selectedClaimId}
        onClose={() => setSelectedClaimId(null)}
        id={selectedClaimId}
        refetch={() => {}}
        isViewMode={true}
      />
    </div>
  );
};

export default ProfileClaims;
