import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../helpers/rtkquery";

export const resourceApi = createApi({
  reducerPath: 'resourceApi',
  baseQuery: rtkBaseQuery('/resource/Resource'),
  tagTypes: ['Calendars', 'Resources', 'Events'],
  endpoints: (builder) => ({
    // Calendars
    createCalendar: builder.mutation({
      query: (body) => ({
        url: '/CreateCalendar',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Calendars']
    }),
    getCalendarList: builder.query({
      query: (id) => ({
        url: `/GetCalendarList?type=${id}`,
      }),
      providesTags: ['Calendars'],
    }),
    editCalendar: builder.mutation({
      query: (body) => ({
        url: '/EditCalendar',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Calendars']
    }),
    deleteCalendar: builder.mutation({
      query: (id)=>({
        url: `/DeleteCalendar?id=${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['Calendars']
    }),
    // Resources
    getResourceList: builder.mutation({
      query: (body) =>  ({
        url: '/GetResourceList',
        method: 'POST',
        body
      }),
      providesTags: ['Resources']
    }),
    createResource: builder.mutation({
      query: (body) => ({
        url: '/CreateResource',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Resources']
    }),
    getResourceById: builder.query({
      query: (id)=> `/GetResourceById/${id}`
    }),
    EditResource: builder.mutation({
      query: (body) => ({
        url: '/EditResource',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Resources']
      // invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],

    }),
    deleteResource: builder.mutation({
      query: (id)=>({
        url: `/DeleteResource?id=${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['Resources']
    }),

    // Events
    createEvent: builder.mutation({
      query: (body)=>({
        url: '/CreateEvent',
        method: 'POST',
        body
      })
    }),
    getEventList: builder.mutation({
      query: (body)=>({
        url: '/GetEventList',
        method: 'POST',
        body
      }),
      providesTags: ['Events']
    }),
    editEvent: builder.mutation({
      query: (body)=>({
        url: '/EditEvent',
        method: 'POST',
        body
      })
    }),
    deleteEvent: builder.mutation({
      query: (id) => ({
        url: `/DeleteEvent?id=${id}`,
        method: 'DELETE',
      })
    }),
    AddStaffToEvent: builder.mutation({
      query: (body) => ({
        url: '/AddStaffToEvent',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Events']
    }),
    RemoveStaffFromEvent: builder.mutation({
      query: (body) => ({
        url: 'RemoveStaffFromEvent',
        method: 'POST',
        body      
      })
    })
  }),
});

export const {
  useGetCalendarListQuery,
  useCreateCalendarMutation,
  useEditCalendarMutation,
  useDeleteCalendarMutation,
  useGetResourceListMutation,
  useGetResourceByIdQuery,
  useCreateResourceMutation,
  useEditResourceMutation,
  useDeleteResourceMutation,
  useCreateEventMutation,
  useGetEventListMutation,
  useEditEventMutation,
  useDeleteEventMutation,
  useAddStaffToEventMutation,
  useRemoveStaffFromEventMutation
} = resourceApi;