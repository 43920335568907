import SubEmployeeSkudDetail from './SubEmployeeSkudDetail';
import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const SkudDetail = lazy(() => import('./SkudDetail'));

const SkudDetailConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  authRoles: authRoles.user,
  routes: [
    {
      path: 'apps/skud/skud-detail',
      element: <SkudDetail />,
    },
    {
      path: 'apps/skud/skud-detail/:view',
      element: <SkudDetail />,
    },
    {
      path: 'apps/skud/employees/:id/:view',
      element: <SubEmployeeSkudDetail />,
    },
  ],
};

export default SkudDetailConfig;
