import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const OrgStructure = lazy(() => import('./OrgStructure'));

const OrgStructureConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/org-structure',
      element: <OrgStructure />,
    },
  ],
};

export default OrgStructureConfig;
