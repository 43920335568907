import { AccessTime, Check, DoneAll, InsertDriveFile } from '@mui/icons-material';
import { Avatar, Button, Popover, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useState } from 'react';
import moment from 'moment';
import useBlobFileDownload from 'src/app/hooks/useBlobFileDownload';
import { API } from 'app/configs/api';
import { capitalizeFirstLetter, getAbsoluteFileUrl, normalizeFIO } from 'src/app/helpers/utils';
import { useTranslation } from 'react-i18next';
import StaffContactDetails from 'app/shared-components/StaffContactDetails';
import SimpleDialog from '@fuse/core/SimpleDialog';

const StyledMessageRow = styled('div')(({ theme }) => ({
  '&.contact': {
    '& .bubble': {
      backgroundColor: '#6167a8',
      color: theme.palette.secondary.contrastText,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      '& .time': {
        marginLeft: 12,
      },
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 20,
      },
    },
    '&.last-of-group': {
      '& .bubble': {
        // borderBottomLeftRadius: 20,
        borderTopLeftRadius: 20,
      },
    },
  },
  '&.me': {
    paddingLeft: 40,
    marginLeft: 'auto',
    '& .bubble': {
      marginLeft: 'auto',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
      '& .time': {
        justifyContent: 'flex-end',
        right: 0,
        marginRight: 12,
      },
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopRightRadius: 20,
      },
    },

    '&.last-of-group': {
      '& .bubble': {
        // borderBottomRightRadius: 20,
        borderTopRightRadius: 20,
      },
    },
  },
  '&.first-of-group': {
    '& .bubble': {
      borderTopLeftRadius: 20,
    },
  },
  '&.last-of-group': {
    '& .bubble': {
      // borderBottomLeftRadius: 20,
      borderTopLeftRadius: 20,

      paddingBottom: 13,
      '& .time': {
        display: 'flex',
      },
    },
  },
}));

const Message = ({
  selectedChat,
  item,
  isMe,
  author,
  repliedMessage,
  repliedMessageAuthor,
  handleDownload,
  isFirstMessageOfGroup,
  index,
  isLastMessageOfGroup,
  chat,
  setRepliedMessage,
}) => {
  const { t } = useTranslation('translations');
  const { downloadFile } = useBlobFileDownload();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeEmployeeId, setActiveEmployeeId] = useState(null);
  const openReplyMenu = Boolean(anchorEl);
  const buttons = {
    left: 0,
    middle: 1,
    right: 2,
  };

  const handleContextMenu = (e, _item) => {
    if (e.button === buttons.right) {
      e.stopPropagation();
      setAnchorEl((prev) => (prev ? null : e.currentTarget));
    }
  };

  const handleUserClick = (staffId) => {
    setActiveEmployeeId(staffId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getStatus = () => {
    if (item.status === 1) return <DoneAll className="text-13 ml-5" />;
    else if (item.status === 4) return <Check className="text-13 ml-5" />;
    else if (item.status === 3) return <AccessTime className="text-13 ml-5" />;
  };

  return (
    <StyledMessageRow
      onContextMenu={(e) => {
        e.preventDefault();
      }}
      onMouseDown={(e) => handleContextMenu(e, item)}
      className={clsx(
        'flex grow-0 shrink-0 items-start relative px-16 pb-4 max-w-md',
        isMe ? 'me' : 'contact',
        { 'first-of-group': isFirstMessageOfGroup(item, index) },
        { 'last-of-group': isLastMessageOfGroup(item, index) },
        index + 1 === chat?.length && 'pb-60'
      )}
    >
      {!isMe && author && (
        <Avatar
          src={getAbsoluteFileUrl(author.staffFileUrl)}
          alt={author.fio || author.fullName}
          className={clsx('cursor-pointer', 'self-end', 'mb-20', isMe ? 'order-2 ml-5' : 'mr-5')}
          onClick={() => handleUserClick(author.staffID)}
        ></Avatar>
      )}

      <div className="flex p-0">
        <Box className="bubble relative pt-0 pb-8 px-12 mb-20 max-w-full ">
          {selectedChat.type === 1 && !isMe && author && (
            <p className={clsx('flex font-bold mb-3', isMe ? 'justify-end' : 'justify-start')}>
              {author.fio || author.fullName}
            </p>
          )}
          <p className="text-10 mt-0">
            {author &&
              (author.fio
                ? normalizeFIO(author.fio)
                : author.fullName_Name
                ? capitalizeFirstLetter(author.fullName_Name) +
                  ' ' +
                  author.fullName_Patronymic[0] +
                  '. ' +
                  capitalizeFirstLetter(author.fullName_Surname)
                : '')}
          </p>
          {item.messageType === 0 ? (
            <div className={clsx('leading-tight whitespace-pre-wrap')}>
              {repliedMessage && repliedMessageAuthor && (
                <div className="border-l-2 pl-5 font-mono text-sm ">
                  <p>
                    {repliedMessageAuthor?.fullName_Name
                      ? capitalizeFirstLetter(repliedMessageAuthor.fullName_Name) +
                        ' ' +
                        repliedMessageAuthor.fullName_Patronymic?.[0] +
                        '. ' +
                        capitalizeFirstLetter(repliedMessageAuthor.fullName_Surname)
                      : repliedMessageAuthor?.fio
                      ? normalizeFIO(repliedMessageAuthor.fio)
                      : ''}
                  </p>
                  <p>
                    {repliedMessage?.text?.length > 20
                      ? `${repliedMessage?.text.slice(0, 20)}...`
                      : repliedMessage?.text}
                  </p>
                </div>
              )}
              <div>
                <p>{item.text}</p>
                <p className="text-10 ml-5 text-grey-300 relative top-5">
                  {moment(item.createdDate).diff(moment(), 'days') < 0
                    ? moment(item.createdDate).format('DD.MM.YYYY HH:mm')
                    : moment(item.createdDate).format('HH:mm')}

                  {isMe && getStatus()}
                </p>
              </div>
              <Popover
                className="p-0 m-0"
                open={openReplyMenu}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Button
                  onClick={() => {
                    setRepliedMessage(item);
                    handleClose();
                  }}
                >
                  {t('Reply')}
                </Button>
              </Popover>
            </div>
          ) : (
            <Tooltip title={t('Download')}>
              <div>
                <div
                  role="button"
                  aria-hidden="true"
                  tabIndex={0}
                  className={clsx(
                    'leading-tight whitespace-pre-wrap flex items-center cursor-pointer',
                    isMe && 'text-end'
                  )}
                  onClick={() =>
                    downloadFile(API.getFile, {
                      id: item.fileId,
                      fileName: item.text,
                    })
                  }
                >
                  <InsertDriveFile
                    style={{ color: '#2b2892', width: 35, height: 35, padding: 5 }}
                    className="bg-white rounded-full mr-10 mt-4 ml-4"
                  />
                  <div>{item.text}</div>
                </div>

                <p className="text-10 ml-5 text-grey-300 relative top-5">
                  {moment(item.createdDate).diff(moment(), 'days') <= 1
                    ? moment(item.createdDate).format('DD.MM.YYYY HH:mm')
                    : moment(item.createdDate).format('HH:mm')}
                  {isMe && getStatus()}
                </p>
              </div>
            </Tooltip>
          )}
        </Box>
      </div>
      <SimpleDialog open={!!activeEmployeeId} onClose={() => setActiveEmployeeId(null)} noPadding>
        <StaffContactDetails id={activeEmployeeId} />
      </SimpleDialog>
    </StyledMessageRow>
  );
};

export default Message;
