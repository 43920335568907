import { useTranslation } from 'react-i18next';
import { StaffPositionRow } from './StaffPositionRow';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';

export const StaffDepartmentBlock = ({ department }) => {
  const { t } = useTranslation('translations');
  const { i18n } = useTranslation();
  const locale = i18n.language === 'ru' ? 'ru' : 'uzb_lat';

  return (
    <Accordion
      defaultExpanded
      sx={{
        '&.MuiAccordion-root.Mui-expanded': {
          m: 0,
        },
      }}
    >
      <AccordionSummary
        sx={{
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 48,
            '& .MuiAccordionSummary-content': {
              m: 0,
            },
          },
          '& .editButton': { opacity: 0 },
          '&:hover .editButton': { opacity: 1 },
        }}
      >
        <Box className="w-full flex justify-between">
          <span>{department.lang[locale]}</span>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Table
          sx={{
            '& .MuiTableCell-root': {
              border: 1,
              borderColor: 'divider',
              p: 1,
            },
          }}
        >
          <TableHead sx={{ bgcolor: 'divider' }}>
            <TableRow>
              <TableCell align="center" width={50}>
                №
              </TableCell>
              <TableCell width={300}>{t('PositionName')}</TableCell>
              <TableCell width={150}>{t('Category')}</TableCell>
              <TableCell width={200} align="center">
                {t('StaffUnits')}
              </TableCell>
              <TableCell>{t('Employee')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {department.positions.map((position, index) => (
              <StaffPositionRow key={position.id} position={position} index={index + 1} />
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TableCell colSpan={3} align="right">
                {t('Total')}
              </TableCell>
              <TableCell align="center">{department.positions.length}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
