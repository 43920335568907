import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const Roles = lazy(() => import('./Roles'));

const RolesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/roles',
      element: <Roles />,
    },
  ],
};

export default RolesConfig;
