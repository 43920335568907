import handleError from '@fuse/core/errorHandler';
import SimpleDialog from '@fuse/core/SimpleDialog/SimpleDialog';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import ClaimTypeForm from './ClaimTypeForm';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import {
  useDeleteClaimTypeMutation,
  useGetAllClaimTypesQuery,
} from 'src/app/services/claim/claimTypeApi';
import moment from 'moment';
import useMessage from 'src/app/hooks/useMessage';
import { Popconfirm } from 'antd';

const ClaimTypes = () => {
  const { t } = useTranslation('translations');
  const [currentClaimType, setCurrentClaimType] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: claimTypes, refetch } = useGetAllClaimTypesQuery();
  const [deleteClaimType] = useDeleteClaimTypeMutation();
  const { showMessage } = useMessage();

  const showEditDialog = (organization) => {
    setCurrentClaimType(organization);
    setIsDialogOpen(true);
  };

  const showNewItemDialog = () => {
    setCurrentClaimType(null);
    setIsDialogOpen(true);
  };

  const handleDelete = (id) => {
    deleteClaimType(id)
      .unwrap()
      .then(() => {
        showMessage({ message: t('ClaimTypeSuccessfullyDeleted'), variant: 'success' });
      })
      .catch(handleError);
  };

  return (
    <div style={{ display: 'grid' }}>
      <TableContainer>
        <div className="h-[40px] flex flex-row justify-between items-center dark:bg-dark">
          <h2 className="ml-5"> {t('ClaimTypes')}</h2>
          <Button
            className="mx-8"
            size="small"
            variant="outlined"
            color="secondary"
            onClick={showNewItemDialog}
          >
            <FuseSvgIcon size={20}>heroicons-outline:plus</FuseSvgIcon>
            <span className="mx-8">{t('Add')}</span>
          </Button>
        </div>
        <Table style={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow className='dark:bg-dark'>
              <TableCell>{t('Name')}</TableCell>
              <TableCell colSpan={2}>{t('CreatedDate')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className=" overflow-y-auto">
            {claimTypes &&
              claimTypes?.data?.map((item) => (
                <TableRow key={item.id} className="bg-white dark:bg-dark hover:bg-grey-200">
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    {item.createdDate ? moment(item.createdDate).format('MM.DD.YYYY HH.mm:ss') : ''}
                  </TableCell>
                  <TableCell>
                    <div className="flex">
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        className="mr-5"
                        color="info"
                        onClick={() => showEditDialog(item)}
                      >
                        <Edit />
                      </IconButton>
                      <Popconfirm
                        className="mr-10"
                        title={t('AreYouSureToDeleteClaim')}
                        onConfirm={() => handleDelete(item.id)}
                        okText={t('Yes')}
                        cancelText={t('No')}
                      >
                        <IconButton className="mr-10" color="error">
                          <Delete />
                        </IconButton>
                      </Popconfirm>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <SimpleDialog
          title={currentClaimType ? t('EditOrganization') : t('AddClaimType')}
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        >
          <ClaimTypeForm
            claimType={currentClaimType}
            refetch={refetch}
            onClose={() => setIsDialogOpen(false)}
            isEdit={currentClaimType ? true : false}
          />
        </SimpleDialog>
      </TableContainer>
    </div>
  );
};

export default ClaimTypes;
