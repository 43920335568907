import handleError from '@fuse/core/errorHandler';
import Employee from './Employee';
import { useParams } from 'react-router-dom';
import { Progress } from 'antd';
import { useEffect, useState } from 'react';
import {
  useFetchSubordinateDepartmentsMutation,
  useGetPositionsByDepartmentQuery,
} from 'src/app/services/shtatApi';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { useTranslation } from 'react-i18next';

const SkudEmployees = () => {
  const { t } = useTranslation('translations');
  const { i18n } = useTranslation();
  const { id } = useParams();
  const [employees, setEmployees] = useState([]);
  const user = useSelector(selectUser);
  const lang = i18n.language === 'ru' ? 'ru' : 'uzb_lat';

  const [fetchDepartments] = useFetchSubordinateDepartmentsMutation();
  const [currentDepartment, setCurrentDepartment] = useState(null);

  const getDepartment = () => {
    fetchDepartments({ superviserId: user.staffId })
      .unwrap()
      .then((res) => {
        const currentDep = res.find((dep) => dep.id === +id);
        setCurrentDepartment(currentDep);
      })
      .catch(handleError);
  };

  useState(() => {
    if (user?.staffId) {
      getDepartment();
    }
  }, [user]);

  const { data: department } = useGetPositionsByDepartmentQuery(id);

  // useEffect(() => {
  //   if (department) {
  //     const empls = department?.positions
  //       ?.filter((pos) => pos.staffList.length > 0)
  //       ?.map((pos) => ({ id: pos.id, ...pos.lang, ...pos.staffList[0] }));
  //     setEmployees(empls);
  //   }
  // }, [department]);

  if (!department?.staffs.length) return null;

  return (
    <div className="h-full overflow-y-auto">
      {currentDepartment?.id && (
        <div className="h-300 flex flex-row justify-around items-center flex-wrap gap-60 bg-white dark:bg-darker p-10 border-1">
          <div className="flex items-center w-[400px] text-16">
            {currentDepartment.lang?.[lang]}
          </div>
          <div className="flex flex-col justify-between items-center ">
            <div>{t('ByStaff')}:</div>
            <div className="text-40 text-black-900">{currentDepartment.positionCount}</div>
          </div>
          <div className="flex flex-col justify-between items-center ">
            <div>
              {t('Employees', { context: 'genitive' })}: {currentDepartment?.staffCount}
            </div>
            <div className="text-40 text-black-900">
              <Progress
                percent={Math.ceil(
                  (currentDepartment.staffCount * 100) / currentDepartment.positionCount
                )}
                format={(percent) => `${percent}%`}
                steps={5}
              />
            </div>
          </div>
          <div className="flex flex-col justify-between items-center ">
            <div>
              {t('InOffice')}: {currentDepartment.staffInOfficeCount}
            </div>
            <div className="text-40 text-green-900">
              <Progress
                type="dashboard"
                percent={Math.ceil(
                  (currentDepartment.staffInOfficeCount * 100) / currentDepartment.staffCount
                )}
                format={(percent) => `${percent}%`}
                size="small"
              />
            </div>
          </div>
          <div className="flex flex-col justify-between items-center ">
            <div>{t('Absent', { context: 'plural' })}:</div>
            <div className="text-40 text-orange-900">
              <span
                className={
                  currentDepartment.staffCount - currentDepartment.staffInOfficeCount === 0
                    ? 'text-green-500'
                    : 'text-orange-900'
                }
              >
                {currentDepartment.staffCount - currentDepartment.staffInOfficeCount}
              </span>
            </div>
          </div>
        </div>
      )}
      <div>
        {department.staffs.map((empl) => (
          <Employee key={empl.staffId} empl={empl} />
        ))}
      </div>
    </div>
  );
};

export default SkudEmployees;
