import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useState } from 'react';
import { handleWorkingDuration } from '../SkudUtils';
import { useTranslation } from 'react-i18next';
import SkudDetailTableHeader from './SkudDetailTableHeader';

const SkudDetailTable = ({ data, loading, calendarRef }) => {
  const { t } = useTranslation('translations');
  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  });

  function handleRequestSort(event, property) {
    const id = property;
    let direction = 'desc';

    if (order.id === property && order.direction === 'desc') {
      direction = 'asc';
    }

    setOrder({
      direction,
      id,
    });
  }

  return (
    <div>
      <div className="grow overflow-x-auto overflow-y-auto">
        {(loading && <FuseLoading />) ||
          (data?.length === 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.1 } }}
              style={{ width: '100%' }}
              className="flex flex-1 items-center justify-center h-full p-10"
            >
              <Typography variant="h6" className="mt-20">
                {t('NoData')}
              </Typography>
            </motion.div>
          )) || (
            <Table stickyHeader className="min-w-xl overflow-y-auto" aria-labelledby="tableTitle">
              <SkudDetailTableHeader
                order={order}
                onRequestSort={handleRequestSort}
                rowCount={data?.length}
              />

              <TableBody>
                {data?.map((item, idx) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={item?.date}>
                    <TableCell
                      className="w-52 pl-14 pr-4"
                      component="th"
                      scope="row"
                      padding="none"
                    >
                      {idx + 1}
                    </TableCell>

                    <TableCell className="p-4 md:p-16" component="th" scope="row">
                      {moment(item?.date).format('DD MMMM YYYY')}
                    </TableCell>

                    <TableCell className="p-4 md:p-16" component="th" scope="row">
                      {item.firstIn ? moment(item.firstIn).format('HH:mm') : '—'}
                    </TableCell>

                    <TableCell className="p-4 md:p-16" component="th" scope="row" align="left">
                      {item.lastOut ? moment(item.lastOut).format('HH:mm') : '—'}
                    </TableCell>

                    <TableCell className="p-4 md:p-16" component="th" scope="row" align="left">
                      {item.timeLate?.slice(0, 5)}
                    </TableCell>

                    <TableCell className="p-4 md:p-16" component="th" scope="row" align="left">
                      {item.firstIn && item.lastOut
                        ? handleWorkingDuration(item?.firstIn, item?.lastOut)
                        : '—'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
      </div>
    </div>
  );
};

export default SkudDetailTable;
