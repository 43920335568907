import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'app/configs/api';
import axios from 'axios';

const initialState = {
  isDarkMode: localStorage.getItem('isDarkMode') === 'true' ? true : false, // light mode is the default
};

export const changeThemeAsync = createAsyncThunk('themeSlice/changeTheme', async (body) => {
  const { userId, theme } = body;
  try {
    const response = await axios.post(API.updateUserTheme, {
      userId,
      theme,
    });
    return Boolean(theme);
  } catch (e) {
    handleError(e);
    return false;
  }
});

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleMode: (state) => {
      state.isDarkMode = !state.isDarkMode;
      localStorage.setItem('isDarkMode', state.isDarkMode);
    },
    setTheme: (state, action) => {
      state.isDarkMode = action.payload;
    },
  },
  extraReducers: {
    [changeThemeAsync.fulfilled]: (state, action) => {
      state.isDarkMode = action.payload;
    },
  },
});

export const { toggleMode, setTheme } = uiSlice.actions;
export default uiSlice.reducer;
