import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip, lighten } from '@mui/material';
import { useTranslation } from 'react-i18next';

const rows = [
  {
    id: '#',
    align: 'left',
    disablePadding: false,
    label: '#',
    sort: true,
  },
  {
    id: 'date',
    align: 'left',
    disablePadding: false,
    label: 'Date',
    sort: true,
  },
  {
    id: 'firstIn',
    align: 'left',
    disablePadding: false,
    label: 'Entrance',
    sort: true,
  },
  {
    id: 'lastout',
    align: 'left',
    disablePadding: false,
    label: 'Exit',
    sort: true,
  },
  {
    id: 'late',
    align: 'left',
    disablePadding: false,
    label: 'Lateness',
    sort: true,
  },
  {
    id: 'duration',
    align: 'left',
    disablePadding: false,
    label: 'TimeAtWork',
    sort: true,
  },
];

const EmployeesTableHead = (props) => {
  const {t} = useTranslation('translations');
  return (
    <TableHead>
      <TableRow>
        {rows.map((row) => {
          return (
            <TableCell
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02),
              }}
              className="p-4 md:p-16"
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? 'none' : 'normal'}
              sortDirection={props.order.id === row.id ? props.order.direction : false}
            >
              {row.sort && (
                <Tooltip
                  title="Sort"
                  placement={row.align === 'right' ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={props.order.id === row.id}
                    direction={props.order.direction}
                    className="font-semibold"
                  >
                    {t(row.label)}
                  </TableSortLabel>
                </Tooltip>
              )}
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
};

export default EmployeesTableHead;
