import _ from 'lodash';
import moment from 'moment';
import StaffContactDetails from 'app/shared-components/StaffContactDetails';
import HappyBirthdayDialog from 'app/shared-components/happyBirthdayDialog/HappyBirthdayDialog';
import SimpleDialog from '@fuse/core/SimpleDialog';
import {
  Avatar,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetTodayBirthdaysQuery, useGetUpcomingBirthdaysQuery } from 'src/app/services/staffApi';
import { capitalizeFirstLetter, getAbsoluteFileUrl } from 'src/app/helpers/utils';
import { useUser } from 'src/app/hooks/useUser';

const BirthdayList = () => {
  const { t } = useTranslation('translations');
  const [isToday, setIsToday] = useState(true);
  const { user } = useUser();
  const [isEmplDetailsOpen, setIsEmplDetailsOpen] = useState(false);
  const [activeEmplId, setActiveEmplId] = useState(null);
  const [isHappyBirthdayVisible, setIsHappyBirthdayVisible] = useState(false);
  const [resizeViewport, setResizeViewport] = useState(window.innerWidth);
  const { data: todayBirthdays, isLoading: isTodayBirthdaysLoading } = useGetTodayBirthdaysQuery();
  const { data: upcomingBirthdays, isLoading: isUpcomingBirthdaysLoading } =
    useGetUpcomingBirthdaysQuery({}, { skip: isToday });

  useEffect(() => {
    window.addEventListener('resize', function () {
      setResizeViewport(window.innerWidth);
    });
  }, [resizeViewport]);

  const handleEmplClick = (id) => {
    setActiveEmplId(id);
    setIsEmplDetailsOpen(true);
  };

  useEffect(() => {
    if (todayBirthdays && todayBirthdays.length) {
      if (todayBirthdays.some((empl) => empl.id === user.staffId)) {
        setIsHappyBirthdayVisible(true);
      }
    }
  }, [todayBirthdays]);

  return (
    <>
      <Card
        component={motion.div}
        variants={{
          hidden: { opacity: 0, y: 40 },
          show: { opacity: 1, y: 0 },
        }}
        className="w-full mb-16 rounded-16 p-24 shadow-2"
      >
        <div>
          <Typography component="p" className="flex flex-1 text-2xl leading-tight">
            {t('Birthdays', "Tug'ilgan kunlari")}
          </Typography>
        </div>

        <div className="flex items-center mt-20 flex-col sm:flex-row border-[1px] p-4 rounded-md">
          <Button
            onClick={() => setIsToday(true)}
            variant="outlined"
            sx={{ border: 0 }}
            className={`w-full basis-1/2 rounded-md text-base ${
              isToday
                ? 'text-white bg-blue hover:bg-blue'
                : 'text-darkgrey bg-white dark:bg-dark hover:bg-white'
            }`}
          >
            {t('Today')}
          </Button>
          <Button
            onClick={() => setIsToday(false)}
            variant="outlined"
            sx={{ border: 0 }}
            className={`w-full basis-1/2 rounded-md text-base mt-10 sm:mt-0 ${
              isToday
                ? 'text-darkgrey bg-white dark:bg-dark hover:bg-white'
                : 'text-white bg-blue hover:bg-blue'
            }`}
          >
            {t('InFewDays')}
          </Button>
        </div>

        <CardContent className="p-0 pt-12">
          {!isTodayBirthdaysLoading && isToday && todayBirthdays?.length === 0 && (
            <div className="text-center">{t('No')}</div>
          )}
          {!isUpcomingBirthdaysLoading && !isToday && upcomingBirthdays?.length === 0 && (
            <div className="text-center">{t('No')}</div>
          )}
          <List className="p-0">
            {_.map(isToday ? todayBirthdays : upcomingBirthdays, (birthday) => (
              <ListItem
                key={birthday.id}
                className="px-0 space-x-8 cursor-pointer hover:bg-grey-50 dark:hover:bg-lightblack border-b-1"
                onClick={() => handleEmplClick(birthday.id)}
              >
                <Avatar src={getAbsoluteFileUrl(birthday?.photo)} alt={birthday.name}>
                  {birthday?.fullName?.name.slice(0, 1)}
                </Avatar>
                <ListItemText>
                  <div className="flex flex-col">
                    <Typography
                      className="font-bold text-base font-lexend dark:text-white"
                      color="primary.main"
                      paragraph={false}
                    >
                      {capitalizeFirstLetter(birthday.fullName.name)}{' '}
                      {capitalizeFirstLetter(birthday.fullName.surname)}
                    </Typography>

                    <Typography
                      className="text-sm font-normal dark:text-darkgrey"
                      paragraph={false}
                      color="primary.light"
                    >
                      {moment(birthday.birthDate).format('Do MMMM')}
                    </Typography>
                  </div>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
      <SimpleDialog open={isEmplDetailsOpen} onClose={() => setIsEmplDetailsOpen(false)} noPadding>
        <StaffContactDetails id={activeEmplId} />
      </SimpleDialog>
      <HappyBirthdayDialog
        isVisible={isHappyBirthdayVisible}
        onClose={() => setIsHappyBirthdayVisible(false)}
      />
    </>
  );
};

export default BirthdayList;             
