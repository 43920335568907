import { TableCell, TableHead, TableRow, lighten } from '@mui/material';
import { useTranslation } from 'react-i18next';

const InternalDocumentsTableHeader = () => {
  const { t } = useTranslation('translations');
  const rows = [
    {
      id: 'photo',
      align: 'left',
      disablePadding: false,
      label: '',
      sort: false,
    },
    {
      id: 'docname',
      align: 'left',
      disablePadding: false,
      label: 'DocumentName',
      sort: true,
    },
    {
      id: 'createDate',
      align: 'left',
      disablePadding: false,
      label: t('CreationDate'),
      sort: true,
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {rows.map((row) => {
          return (
            <TableCell
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? lighten(theme.palette.background.default, 0.4)
                    : lighten(theme.palette.background.default, 0.02),
              }}
              className="p-4 md:p-16"
              key={row.id}
              align={row.align}
              padding={row.disablePadding ? 'none' : 'normal'}
            >
              {t(row.label)}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default InternalDocumentsTableHeader;
