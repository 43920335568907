import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import handleError from '@fuse/core/errorHandler';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import StaffAvatar from 'app/shared-components/StaffAvatar';
import moment from 'moment';
import StaffOnline from './StaffOnline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAbsoluteFileUrl, normalizeFIO } from 'src/app/helpers/utils';
import { useStaffByIdMutation } from 'src/app/services/staffApi';
import { useGetDepartmentByDepartmentIdQuery } from '../services/deparmentsApi';
import { Button, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeFloorOrder } from '../main/apps/map/store/floorsSlice';
import { userStatusState } from '../main/hr/users/UsersTable';

const StaffContactDetails = ({ id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations');
  const { i18n } = useTranslation();
  const lang = i18n.language === 'ru' ? 'ru' : 'uzb_lat';
  const [profileImages, setProfileImages] = useState([]);
  const [profile, setProfile] = useState(null);
  const [getStaff] = useStaffByIdMutation();
  const { data: locationDepartment } = useGetDepartmentByDepartmentIdQuery(
    { id: profile?.placeOfWork?.departmentId },
    { skip: !profile?.placeOfWork?.departmentId }
  );
  const navigate = useNavigate();
  const buildId = locationDepartment?.builds[0]?.id;

  useEffect(() => {
    getStaffById();
  }, [id]);

  useEffect(() => {
    const images = [profile?.applicationUserImageFileUrl, profile?.staffFileUrl].filter(Boolean);
    setProfileImages(images.length > 1 ? ['avatar', 'doc'] : ['avatar']);
  }, [profile]);

  function handleDispatchFloor() {
    dispatch(
      changeFloorOrder({
        buildingId: locationDepartment?.builds[0].id,
        floorOrder: locationDepartment?.floors[0].name,
        floorId: locationDepartment?.floors[0].id,
        floorImage: locationDepartment?.floors[0].picture,
        departmentId: locationDepartment?.departmentId,
      })
    );
  }

  function getStaffById() {
    if (id) getStaff({ id }).unwrap().then(setProfile).catch(handleError);
  }

  function swapImages() {
    setProfileImages((prev) => [...prev].reverse());
  }

  function renderStatus() {
    const label = profile ? userStatusState.find((s) => s.id === profile?.status)?.title : '';
    switch (profile?.status) {
      case 1:
        return <Chip label={label} color="info" />;
      case 2:
        return <Chip label={label} color="secondary" />;
      case 3:
        return <Chip label={label} color="success" />;
      case 4:
        return <Chip label={label} color="warning" />;
      default:
        return null;
    }
  }

  return (
    <div className="w-full max-w-640">
      <Box
        className="relative w-full h-96 px-32 sm:px-48"
        sx={{
          backgroundColor: 'background.default',
        }}
      ></Box>
      <div className="relative flex flex-col flex-auto items-center p-24 pt-0 sm:p-48 sm:pt-0 dark:bg-dark">
        <div className="w-50">
          <div className="flex items-end -mt-64">
            {profileImages.map((type, index) =>
              index === 1 ? (
                <button key={type} type="button" onClick={swapImages}>
                  <StaffAvatar
                    key={type}
                    staff={profile}
                    type={profileImages[1]}
                    className="w-40 h-40 text-24"
                  />
                </button>
              ) : (
                <StaffAvatar
                  key={type}
                  staff={profile}
                  type={type}
                  className="w-128 h-128 text-64 font-bold"
                />
              )
            )}
            <div className="ml-20">
              <StaffOnline staffId={+id} />
            </div>
            <div className="ml-20">{renderStatus()}</div>
          </div>
          <Typography className="mt-12 text-4xl mb-5">
            {profile?.fullName ? normalizeFIO(profile?.fullName?.fio) : ''}
          </Typography>
          <div className="flex flex-col gap-16">
            <div className="flex items-center">
              <FuseSvgIcon>heroicons-outline:office-building</FuseSvgIcon>
              <div className="ml-12 leading-6 text-black dark:text-white">
                {profile?.placeOfWork?.departmentName?.[lang]}
              </div>
              {buildId && (
                <Link
                  to={`/apps/map/${buildId}`}
                  onClick={handleDispatchFloor}
                  className="flex items-center ml-12"
                  style={{ textDecoration: 'none' }}
                >
                  <FuseSvgIcon className="w-28 h-28 text-darker dark:text-white">
                    heroicons-outline:map-pin
                  </FuseSvgIcon>
                </Link>
              )}
            </div>
            <div className="flex items-center">
              <FuseSvgIcon>heroicons-outline:briefcase</FuseSvgIcon>
              <div className="ml-12 leading-6">{profile?.placeOfWork?.positionName?.[lang]}</div>
            </div>
            <div className="flex items-center">
              <div className="flex flex-row flex-wrap gap-12 justify-center items-center">
                {profile?.staffSkills &&
                  profile.staffSkills.map((skill) => (
                    <div key={skill.skillId} className="flex flex-col justify-center items-center">
                      <img
                        src={getAbsoluteFileUrl(skill.pictureUrl)}
                        alt={skill.spSkillId}
                        className="w-32 h-32 object-fill"
                      />
                      <span className="text-center text-10">{skill.lang?.[lang]}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex items-start">
              <FuseSvgIcon>heroicons-outline:view-boards</FuseSvgIcon>
              <div className="ml-12 mt-2">
                <span className="font-600">{t('ParticipationInProjects')}:</span>
                <ul className="ml-16">
                  {profile?.projects?.map((project, index) => (
                    <li key={index} className="px-4 list-disc mb-3">
                      {project}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <Divider className="my-8" />
            <div className="flex items-center">
              <FuseSvgIcon>heroicons-outline:cake</FuseSvgIcon>
              <div className="ml-12 leading-6">
                {profile?.passport
                  ? moment(profile.passport.birthDate).format('DD.MM.YYYY')
                  : t('NotSpecified')}
              </div>
            </div>

            <div className="flex items-center">
              <FuseSvgIcon>heroicons-outline:phone</FuseSvgIcon>
              <div className="ml-12 leading-6">
                {[profile?.contact?.phoneMain, profile?.contact?.phoneAdditional]
                  .filter(Boolean)
                  .join(', ')}
              </div>
            </div>

            <div className="flex items-center">
              <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
              <div className="ml-12 leading-6">
                {[
                  profile?.contact?.eMail?.toLowerCase(),
                  profile?.contact?.eMailAdditional?.toLowerCase(),
                ]
                  .filter(Boolean)
                  .join(', ')}
              </div>
            </div>

            <div className="flex items-center">
              <Button
                className="ml-0"
                color="secondary"
                variant="contained"
                startIcon={<FuseSvgIcon>heroicons-outline:chat-alt</FuseSvgIcon>}
                onClick={() => navigate(`/apps/chat/${profile?.applicationUserId}`)}
              >
                {t('GoToEmployeeChat')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffContactDetails;
