import useMessage from './useMessage';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { contentTypes } from '../main/apps/blank/contentTypes';

const useBlobFileDownload = () => {
  const { t } = useTranslation('translations');
  const { showMessage } = useMessage();
  const downloadFile = async (url, file) => {
    const fileParts = file.fileName.split('.');
    const fileFormat = fileParts[fileParts.length - 1];
    const fileType = contentTypes[fileFormat];
    axios
      .post(url, undefined, {
        params: { id: file?.id },
        responseType: 'blob',
      })
      .then((res) => {
        const blobUrl = window.URL.createObjectURL(new Blob([res.data]), { type: fileType });
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.fileName;
        link.click();
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          showMessage({ message: t('FileNotFound'), variant: 'error' });
        } else {
          showMessage({ message: t('ErrorOccuredOpenBrowserConsole'), variant: 'error' });
        }
        console.error(error);
      });
  };
  return { downloadFile };
};

export default useBlobFileDownload;
