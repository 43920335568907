import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const HrmEmployees = lazy(() => import('./HrmEmployees'));

const HrmEmployeesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.hr,
  routes: [
    {
      path: 'hr/hrm-employees',
      element: <HrmEmployees />,
    },
  ],
};

export default HrmEmployeesConfig;
