import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const CreateUser = lazy(() => import('./CreateUser'));

const CreateUserConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.hr,
  routes: [
    {
      path: 'hr/create-user',
      element: <CreateUser />,
    },
  ],
};

export default CreateUserConfig;
