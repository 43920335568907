import { getAbsoluteFileUrl } from 'src/app/helpers/utils';

function Media({ name, fileSrc, className, type }) {
  if (!fileSrc)
    return (
      <img
        alt="app-download-qrcode"
        src="assets/images/apps/news/cover.jpg"
        className="w-full h-full object-cover object-top"
      />
    );

  return (
    <img
      alt={name}
      src={getAbsoluteFileUrl(fileSrc?.slice(1))}
      type={type}
      className={['w-full h-full object-cover object-top', className].join(' ')}
    />
  );
}

export default Media;
