import FuseLoading from '@fuse/core/FuseLoading/FuseLoading';
import SimpleDialog from '@fuse/core/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import axios from 'axios';
import { API } from 'app/configs/api';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddSkillsToUserMutation, useGetAllSkillsQuery } from 'src/app/services/staffApi';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import './SkillsCard.css';

function StuffSkills() {
  const { t } = useTranslation('translations');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const { data: skillsList } = useGetAllSkillsQuery();
  const [addSkillsToUser] = useAddSkillsToUserMutation();
  const { i18n } = useTranslation();
  const locale = i18n.language === 'ru' ? 'ru' : 'uzb_lat';
  const user = useSelector(selectUser);
  const { staffId } = user;

  const handleSkillChange = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills(selectedSkills.filter((selected) => selected !== skill));
    } else {
      setSelectedSkills([...selectedSkills, skill]);
    }
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  const getStaffData = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`${API.staffById}/${staffId}`);
      setProfileData(res?.data);
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getStaffData();
  }, []);

  const saveSkills = () => {
    setLoading(true);
    addSkillsToUser(
      selectedSkills.map((skillId) => ({
        staffId,
        skillId,
      }))
    )
      .unwrap()
      .then((res) => {
        getStaffData();
        setOpen(false);
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  };

  const { staffSkills } = profileData;

  useEffect(() => {
    if (staffSkills) {
      setSelectedSkills(staffSkills.map((skill) => skill.skillId));
    }
  }, [staffSkills]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <FuseLoading />
      </div>
    );
  }

  return (
    <Card component={motion.div} variants={item} className="w-full shadow-0 dark:bg-dark">
      <div className="px-32 pt-24">
        <Typography className="text-2xl font-semibold leading-tight flex justify-between items-center">
          {t('Skills')}
          {user && user.staffId > 0 && (
            <IconButton color="info" onClick={() => setOpen('general-info')}>
              <img className="w-24 h-28" src="assets/icons/profile/edit.svg" />
            </IconButton>
          )}
        </Typography>
      </div>
      <SimpleDialog
        open={open === 'general-info'}
        title={t('ChooseSkills')}
        onClose={() => setOpen(false)}
      >
        <div className="flex flex-wrap justify-center items-center mt-1 sm:w-full">
          {skillsList &&
            skillsList.toReversed().map((skill) => (
              <div key={skill.id} className="skill-item">
                <input
                  type="checkbox"
                  id={skill.id}
                  checked={selectedSkills.includes(skill.id)}
                  onChange={() => handleSkillChange(skill.id)}
                />
                <label htmlFor={skill.id}>
                  <img
                    src={getAbsoluteFileUrl(skill.pictureUrl)}
                    alt={skill.id}
                    className="skill-img"
                  />
                  {skill.lang?.[locale]}
                </label>
              </div>
            ))}
        </div>

        <Button
          disabled={loading}
          variant="outlined"
          color="secondary"
          className="w-full w-50 mt-16"
          onClick={saveSkills}
          size="large"
        >
          {t('Save')}
        </Button>
      </SimpleDialog>

      <CardContent className="px-32 py-24">
        <div className="flex flex-row flex-wrap justify-center items-center">
          {staffSkills &&
            staffSkills.map((skill) => (
              <div key={skill.skillId} className="skill-item">
                <label htmlFor={skill.skillId}>
                  <img
                    src={getAbsoluteFileUrl(skill.pictureUrl)}
                    alt={skill.spSkillId}
                    className="skill-img"
                  />
                  {skill.lang?.[locale]}
                </label>
              </div>
            ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default StuffSkills;
