import Organizations from './organizations/Organizations';
import Translations from './translations/Translations';
import Reference from './reference/Reference';
import ClaimTypes from './claimTypes/ClaimTypes';
import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import ClaimStatuses from './claimStatuses/ClaimStatuses';

const References = lazy(() => import('./References'));

const ReferencesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/references',
      element: <References />,
      children: [
        {
          path: 'organizations',
          element: <Organizations />,
        },
        {
          path: 'translations',
          element: <Translations />,
        },
        {
          path: 'claimtypes',
          element: <ClaimTypes />,
        },
        {
          path: 'claimstatuses',
          element: <ClaimStatuses />,
        },
        {
          path: ':tableName',
          element: <Reference />,
        },
      ],
    },
  ],
};

export default ReferencesConfig;
