import moment from 'moment';

export const handleWorkingDuration = (start, end) => {
  const startMoment = moment(start, 'YYYY-MM-DD HH:mm');
  const endMoment = moment(end, 'YYYY-MM-DD HH:mm');
  const durationInMinutes = endMoment.diff(startMoment, 'minutes');
  const duration = moment.duration(durationInMinutes, 'minutes');
  const hours = Math.floor(duration.asHours());
  const minutes = `${duration.minutes()}`;
  const fixedMinutes = minutes.length == 1 ? minutes.padStart(2, 0) : minutes;
  const formattedDuration = `${hours}:${fixedMinutes}`;

  return formattedDuration;
};

export const handleDurationValue = (start, end) => {
  const startMoment = moment(start, 'YYYY-MM-DD HH:mm');
  const endMoment = moment(end, 'YYYY-MM-DD HH:mm');
  const durationInMinutes = endMoment.diff(startMoment, 'minutes');
  const duration = moment.duration(durationInMinutes, 'minutes');
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  const convertHourToMin = hours * 60;
  const addMinutes = convertHourToMin + minutes;
  const workHours = 8 * 60;
  const durationValMin = addMinutes - workHours;
  const durationVal = moment.duration(durationValMin, 'minutes');
  const durationValHours = durationVal.hours();
  const duratioValMinutes = Math.abs(durationVal.minutes());
  const formatted = `${`0${durationValHours}`.slice(-2)}:${`0${duratioValMinutes}`.slice(-2)}`;
  const prefix = durationValHours > 0 ? '+' : '-';

  return (
    <div style={{ color: durationValHours > 0 ? 'green' : 'red' }}>
      {durationValHours > 0 && '+'}
      {formatted}
    </div>
  );
};

export const groupArrayForSkudTable = (originalArray) => {
  return originalArray.reduce((acc, obj) => {
    const { id, fullName, date, firstIn, lastOut, timeInOffice, timeLate, earlyLeaving, overTime } = obj;
    const existingEntry = acc.find(entry => entry.id === id);

    if (existingEntry) {
      existingEntry.dates.push({
        date,
        firstIn,
        lastOut,
        timeInOffice,
        timeLate,
        earlyLeaving,
        overTime
      });
    } else {
      acc.push({
        id,
        fullName,
        dates: [{
          date,
          firstIn,
          lastOut,
          timeInOffice,
          timeLate,
          earlyLeaving,
          overTime
        }]
      });
    }
    return acc;
  }, []);
}

export const calcDataForPieChart = (originalArray) => {
  const normal = {
    id: "normal",
    label: "WithoutViolations",
    value: 0,
    color: "hsl(166, 70%, 50%)"
  }
  const timeLate = {
    id: "timelate",
    label: "Latenesses",
    value: 0,
    color: "hsl(67, 70%, 50%)"
  }
  const earlyLeaving = {
    id: "earlyleaving",
    label: "EarlyLeavings",
    value: 0,
    color: "hsl(288, 70%, 50%)"
}

  const totalCount=originalArray.length || 1;
  originalArray.forEach((record)=>{
    if (record.earlyLeaving?.length>0) earlyLeaving.value++
    else if (record.timeLate?.length>0) timeLate.value++
    else normal.value++
  })
  normal.value = Math.round(normal.value*100 / totalCount);
  timeLate.value = Math.round(timeLate.value*100 / totalCount);
  earlyLeaving.value = 100 - normal.value - timeLate.value;
  return [normal, timeLate, earlyLeaving]
}

export const calcDataForLineChart = (originalArray) => {
  const counts = {};

  originalArray.forEach(employee => {
    const date = moment(employee.date).format("DD.MM"); 
  
    if (employee.timeLate && employee.timeLate !== "") {
      counts[date] = counts[date] || {};
      counts[date].timeLate = (counts[date].timeLate || 0) + 1;
    }
  
    if (employee.earlyLeaving && employee.earlyLeaving !== "") {
      counts[date] = counts[date] || {};
      counts[date].earlyLeaving = (counts[date].earlyLeaving || 0) + 1;
    }
  });
  
  const result = Object.entries(counts).map(([date, values]) => {
    return {
      x: date,
      y: {
        timeLate: values.timeLate || 0,
        earlyLeaving: values.earlyLeaving || 0,
      },
    };
  });
  
  const groupedResult = [
    {
      id: "EarlyLeavings",
      data: result.map(entry => ({ x: entry.x, y: entry.y.earlyLeaving })),
    },
    {
      id: "Latenesses",
      data: result.map(entry => ({ x: entry.x, y: entry.y.timeLate })),
    },
  ];
  return groupedResult;
}