import moment from 'moment';
import { useGetCurrencyQuery } from 'src/app/services/paymentsApi';
import { CURRENCY_TYPE, CurrencyTypes } from '../vars';

const PaymentAmount = ({ data }) => {
  const notUzs = +data.сurrencyType !== CURRENCY_TYPE.UZS;
  const amount = +data.amount;

  const { data: rates } = useGetCurrencyQuery(
    {
      date: moment(new Date(data.paymentDate)).format('YYYY-MM-DD'),
      currency: data.сurrencyType,
    },
    { skip: !notUzs }
  );

  if (!amount) return null;

  return (
    <div className="flex gap-4">
      <div>{`${amount.toLocaleString('ru')} ${CurrencyTypes[data.сurrencyType].label}`}</div>
      {notUzs && rates && <div>{`(~${(amount * rates[0]?.rate).toLocaleString('ru')} UZS)`}</div>}
    </div>
  );
};

export default PaymentAmount;
