import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const CRUDMap = lazy(() => import('./Buildings/AddBuild'));

const mapFloorAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/crud-map',
      element: <CRUDMap />,
    }
  ],
};

export default mapFloorAppConfig;