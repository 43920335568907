import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const Blank = lazy(() => import('./Blank'));

const BlankConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  authRoles: authRoles.admin,
  routes: [
    {
      path: 'apps/blanks',
      element: <Blank />,
    },
  ],
};

export default BlankConfig;
