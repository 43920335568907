import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const Logs = lazy(() => import('./Logs'));

const LogsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/logs',
      element: <Logs />,
    },
  ],
};

export default LogsConfig;
