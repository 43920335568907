import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const skudApi = createApi({
  reducerPath: 'skudApi',
  baseQuery: rtkBaseQuery('/staff/Skud'),
  tagTypes: ['Skud'],
  endpoints: (builder) => ({
    // POST  /api/staff/Skud/GetSessionByPINFL
    // POST  /api/staff/Skud/GetSessionByPINFL_ITPark
    // GET   /api/staff/Skud/ImportSessionsFromSkud
    // POST  /api/staff/Skud/InOffice/{staffId}
    // POST  /api/staff/Skud/SessionsByStaffId
    // POST  /api/staff/Skud/GetSessionsBySubordinate
    fetchSessionByPINFL: builder.mutation({
      query: (body) => ({
        url: '/GetSessionByPINFL',
        method: 'POST',
        body,
      }),
    }),
    fetchSessionByPinflItPark: builder.mutation({
      query: (body) => ({
        url: '/GetSessionByPINFL_ITPark',
        method: 'POST',
        body,
      }),
    }),
    getSessionsFromSkud: builder.query({
      query: (params) => ({
        url: '/ImportSessionsFromSkud',
        params,
      }),
    }),
    inOffice: builder.mutation({
      query: ({ staffId }) => ({
        url: `/InOffice/${staffId}`,
        method: 'POST',
      }),
    }),
    fetchSessionsByStaffId: builder.mutation({
      query: (body) => ({
        url: '/SessionsByStaffId',
        method: 'POST',
        body,
      }),
    }),
    getSessionsByStaffId: builder.query({
      query: (body) => ({
        url: '/SessionsByStaffId',
        method: 'POST',
        body,
      }),
    }),
    fetchSessionsBySubordinate: builder.mutation({
      query: (body) => ({
        url: '/GetSessionsBySubordinate',
        method: 'POST',
        body,
      }),
    }),
    getSessionsBySubordinate: builder.query({
      query: (body) => ({
        url: '/GetSessionsBySubordinate',
        method: 'POST',
        body,
      }),
      transformResponse: (response) => response.staffs,
    }),
    getSessionsAllStaff: builder.mutation({
      query: ({dateStart, dateStop, ...body}) => ({
        url: `SessionsAllStaff?dateStart=${dateStart}&dateStop=${dateStop}`,
        method: 'POST',
        body      
      })
    })
  }),
});

export const {
  useFetchSessionByPINFLMutation,
  useFetchSessionByPinflItParkMutation,
  useGetSessionsFromSkudQuery,
  useInOfficeMutation,
  useFetchSessionsByStaffIdMutation,
  useGetSessionsByStaffIdQuery,
  useFetchSessionsBySubordinateMutation,
  useGetSessionsBySubordinateQuery,
  useGetSessionsAllStaffMutation
} = skudApi;
