import { Stack } from '@mui/material';
import { StaffDepartmentBlock } from './StaffDepartmentBlock';

export default function StaffTableList({ schedule }) {
  return (
    <Stack>
      {schedule.map((department) => (
        <StaffDepartmentBlock key={department.id} department={department} />
      ))}
    </Stack>
  );
}
