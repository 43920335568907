import { Avatar, Chip } from '@mui/material';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Employee = ({ empl }) => {
  const { t, i18n } = useTranslation('translations');
  const lang = i18n.language === 'ru' ? 'ru' : 'uzb_lat';
  const navigate = useNavigate();

  return (
    <div
      className="grid grid-cols-2 sm:grid-cols-3 p-10 bg-white dark:bg-darker border-1 hover:border-blue-400 cursor-pointer "
      onClick={() => navigate(`/apps/skud/employees/${empl.id}`)}
    >
      <div className="flex items-center">
        <Avatar className="mx-10" src={getAbsoluteFileUrl(empl.photoFileUrl)} />
        <span>{empl.fio}</span>
      </div>
      <div className=" text-grey-600 hidden sm:flex sm:items-center ">{empl?.lang[lang]}</div>
      <div className="flex justify-center items-center">
        <Chip
          label={empl.inOffice ? t('InOffice') : t('NotInOffice')}
          color={empl.inOffice ? 'success' : 'error'}
          variant="outlined"
        />
      </div>
    </div>
  );
};

export default Employee;
