import SimpleDialog from '@fuse/core/SimpleDialog/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import ReferenceItemForm from './ReferenceItemForm';
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSpItemsListMutation } from 'src/app/services/spApi';
import { Delete, Edit } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';

const initialParams = {
  filters: '{}',
  first: 0,
  rows: 10,
};

const Reference = () => {
  const { t } = useTranslation('translations');
  const { tableName } = useParams();
  const [params, setParams] = useState(initialParams);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [open, setOpen] = useState(false);
  const [getSpItems, { isLoading }] = useSpItemsListMutation();

  const handlePageChange = (event, value) => {
    setPage(value);
    setParams((prev) => ({ ...prev, first: (value - 1) * prev.rows }));
  };

  const getItems = () => {
    getSpItems({ tableName, ...params })
      .unwrap()
      .then((res) => {
        setItems(res.items);
        setTotalPages(Math.ceil(res.totalItems / params.rows));
      })
      .catch(handleError);
  };

  const handleEdit = (position) => {
    setCurrentPosition(position);
    setOpen(true);
  };

  const showNewItemDialog = () => {
    setCurrentPosition(null);
    setOpen(true);
  };

  useEffect(getItems, [params, tableName]);

  return (
    <TableContainer>
      <div className="flex flex-row justify-between mt-5 dark:bg-dark">
        <h2 className="ml-5"> {t(tableName, 'Справочник')}</h2>
        <Button
          className="mx-10"
          size="small"
          variant="outlined"
          color="secondary"
          onClick={showNewItemDialog}
        >
          <FuseSvgIcon size={20}>heroicons-outline:plus</FuseSvgIcon>
          <span className="mx-8">{t('Add')}</span>
        </Button>
      </div>
      {isLoading && <FuseLoading />}
      {!isLoading && (
        <Table className="w-full">
          <TableHead>
            <TableRow className="dark:bg-dark">
              <TableCell>{t('Name')} (рус)</TableCell>
              <TableCell colSpan={2}>{t('Name')} (latin)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((item) => (
              <TableRow key={item.id} className="bg-white dark:bg-dark hover:bg-grey-200">
                <TableCell>{item.nameRu}</TableCell>
                <TableCell>{item.nameUz}</TableCell>
                <TableCell>
                  <div className="flex">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      className="mr-5"
                      color="info"
                      onClick={() => handleEdit(item)}
                    >
                      <Edit />
                    </IconButton>
                    {/* <IconButton
                    edge="end"
                    aria-label="delete"
                    className="mr-20"
                    color="error"
                    onClick={()=>handleDelete(item.id)}
                  >
                    <Delete />
                  </IconButton> */}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Pagination
        className="flex justify-center items-center"
        count={totalPages}
        page={page}
        onChange={handlePageChange}
      />
      <SimpleDialog
        title={currentPosition ? t('Editing') : t('Adding')}
        open={open}
        onClose={() => setOpen(false)}
      >
        <ReferenceItemForm
          position={currentPosition}
          refetchList={getItems}
          onClose={() => setOpen(false)}
          tableName={tableName}
        />
      </SimpleDialog>
    </TableContainer>
  );
};

export default Reference;
