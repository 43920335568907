import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const rolesApi = createApi({
  reducerPath: 'rolesApi',
  baseQuery: rtkBaseQuery('/auth/Roles'),
  endpoints: (builder) => ({
    addRoleToUser: builder.mutation({
      query: (body) => ({
        url: '/AddUserToRole',
        method: 'POST',
        body,
      }),
    }),
    removeRoleFromUser: builder.mutation({
      query: (body) => ({
        url: '/RemoveUserFromRole',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useAddRoleToUserMutation, useRemoveRoleFromUserMutation } = rolesApi;
