import { Add } from '@mui/icons-material';
import { Button, Pagination, Stack, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  useDeleteArticleMutation,
  useFetchArticleListMutation,
} from 'src/app/services/articlesApi';
import handleError from '@fuse/core/errorHandler';
import FuseLoading from '@fuse/core/FuseLoading';
import { selectUser } from 'app/store/userSlice';
import NewsDialog from './NewsDialog';
import NewsItem from './NewsItem';
import { useUser } from 'src/app/hooks/useUser';

const initialParams = {
  filters: '{}',
  first: 0,
  rows: 5,
};

const NewsList = () => {
  const { t } = useTranslation('translations');
  const [data, setData] = useState([]);
  const [dataPerPage, setDataPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState(initialParams);
  const [currentPost, setCurrentPost] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = useSelector(selectUser);
  const { isTop, isAdmin, isPR } = useUser();

  const [fetchArticleList, { isLoading }] = useFetchArticleListMutation();
  const [deleteArticle] = useDeleteArticleMutation();

  const getPosts = (start = false) => {
    fetchArticleList(start ? initialParams : params)
      .unwrap()
      .then((res) => {
        setData(res.items);
        setTotal(res.totalItems);
      });
  };

  useEffect(getPosts, [params]);

  const transition = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

  const handleCreatePost = () => {
    setSidebarOpen(true);
    setCurrentPost(null);
    setCurrentPage(1);
  };

  const handleDeletePost = (post) => {
    deleteArticle(post.id)
      .then(() => {
        setData((prev) => prev.filter((item) => item.id !== post.id));
        setCurrentPage(1);
        setTotal((prev) => prev - 1);
        getPosts(true);
      })
      .catch(handleError);
  };

  const paginationNumber = [];

  for (let i = 1; i <= Math.ceil(total / dataPerPage); i++) {
    paginationNumber.push(i);
  }

  const handleChangePagination = (e, value) => {
    setCurrentPage(value);
    setParams((prev) => {
      return {
        ...prev,
        first: (value - 1) * prev.rows,
        rows: 5,
      };
    });
  };

  const handleEditPost = () => {
    setCurrentPage(1);
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <Fragment>
      {!isLoading && data.length === 0 && (
        <Typography variant="h6" className="text-center text-14">
          {t('NoNews', "Yangiliklar yo'q ekan")}
        </Typography>
      )}
      {data.map((post, index) => (
        <NewsItem
          isMain={index === 0 && currentPage === 1}
          post={post}
          transition={transition}
          key={post.id}
          onEdit={handleEditPost}
          onDelete={handleDeletePost}
          user={user}
          refetchList={() => getPosts(true)}
        />
      ))}
      <Stack className="mb-20">
        <Pagination
          className="flex justify-center"
          count={paginationNumber.length}
          page={currentPage}
          onChange={handleChangePagination}
        />
      </Stack>
      <div className="flex justify-center mb-20">
        {(isAdmin || isTop || isPR) && (
          <Button
            onClick={handleCreatePost}
            variant="outlined"
            color="secondary"
            className="text-sm"
          >
            <Add />
            {t('AddNews')}
          </Button>
        )}
      </div>
      <NewsDialog
        post={currentPost}
        onOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        refetchList={() => getPosts(true)}
      />
    </Fragment>
  );
};

export default NewsList;
