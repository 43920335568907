import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import { Outlet } from 'react-router-dom';

const MapFloors = lazy(() => import('./MapFloors'));
const Floor = lazy(() => import('./buildings/Floor'));

const MapAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/map',
      element: <Outlet />, 
      children: [
        { index: true, element: <MapFloors /> },
        {
          path: ':buildId',
          element: <Floor />
        }
      ]
    },
  ],
};

export default MapAppConfig;