/* eslint import/no-extraneous-dependencies: off */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import settingsConfig from 'app/configs/settingsConfig';
import jwtService from '../auth/services/jwtService';
import { changeLanguage } from './i18nSlice';

export const setUser = createAsyncThunk('user/setUser', async (user, { dispatch, getState }) => {
  /*
    You can redirect the logged-in user to a specific route depending on his role
    */
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for example 'apps/academy'
  }
  dispatch(changeLanguage(user.localization === 0 ? 'uz' : 'ru'));

  return user;
});

export const updateUserSettings = createAsyncThunk(
  'user/updateSettings',
  async (settings, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = _.merge({}, user, { data: { settings } });

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const updateUserShortcuts = createAsyncThunk(
  'user/updateShortucts',
  async (shortcuts, { dispatch, getState }) => {
    const { user } = getState();
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(newUser));

    return newUser;
  }
);

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  // if (!user.role || user.role.length === 0) {
  //   // is guest
  //   return null;
  // }

  history.push({
    pathname: '/',
  });

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }

  jwtService
    .updateUserData(user)
    .then(() => {
      dispatch(showMessage({ message: 'User data saved with api' }));
    })
    .catch((error) => {
      dispatch(showMessage({ message: error.message }));
    });
};

const initialState = {
  role: [], // guest
  data: {
    displayName: 'user',
    photoURL: 'assets/images/avatars/brian-hughes.jpg',
    email: 'user@uzatom.uz',
    shortcuts: ['apps.home'],
  },
  isGreetingsVisible: false,
  isRefreshTokenInvoked: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    setUserPhoto: (state, action) => {
      state.photoURL = action.payload;
    },
    setUserBanner: (state, action) => {
      state.bannerURL = action.payload;
    },
    setIsGreetingsVisible: (state, action) => {
      state.isGreetingsVisible = action.payload;
    },
    setIsRefreshTokenInvoked: (state, action) => {
      state.isRefreshTokenInvoked = action.payload;
    },
  },
  extraReducers: {
    [updateUserSettings.fulfilled]: (state, action) => action.payload,
    [updateUserShortcuts.fulfilled]: (state, action) => action.payload,
    [setUser.fulfilled]: (state, action) => action.payload,
  },
});

export const {
  userLoggedOut,
  setUserPhoto,
  setUserBanner,
  setIsGreetingsVisible,
  setIsRefreshTokenInvoked,
} = userSlice.actions;

export const selectUser = ({ user }) => user;

export const selectIsGreetingsVisible = ({ user }) => user.isGreetingsVisible || false;

export const selectIsRefreshTokenInvoked = ({ user }) => user.isRefreshTokenInvoked || false;

export const selectUserShortcuts = ({ user }) => undefined;

export default userSlice.reducer;
