import axios from 'axios';
import i18n from 'src/i18n';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { setDefaultSettings } from './fuse/settingsSlice';
import { API } from 'app/configs/api';

export const changeLanguage = (languageId) => (dispatch, getState) => {
  const { direction } = getState().fuse.settings.defaults;

  const newLangDirection = i18n.dir(languageId);

  if (newLangDirection !== direction) {
    dispatch(setDefaultSettings({ direction: newLangDirection }));
  }

  return i18n.changeLanguage(languageId).then(() => {
    dispatch(i18nSlice.actions.languageChanged(languageId));
  });
};

export const changeLanguageAsync = createAsyncThunk('i18n/changeLang', async (body) => {
  const { userId, lng } = body;
  try {
    const response = await axios.post(API.updateUserLocale, {
      userId,
      localization: lng.code,
    });

    i18n.changeLanguage(lng.id);
    return lng.id;
  } catch (e) {
    handleError(e);
    return [];
  }
});

const i18nSlice = createSlice({
  name: 'i18n',
  initialState: {
    language: i18n.options.lng,
    languages: [
      { id: 'uz', title: "O'z", flag: 'UZ', code: 0 },
      { id: 'ru', title: 'Ру', flag: 'RU', code: 1 },
    ],
  },
  reducers: {
    languageChanged: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: {
    [changeLanguageAsync.fulfilled]: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const selectCurrentLanguageId = ({ i18n: _i18n }) => _i18n.language;

export const selectLanguages = ({ i18n: _i18n }) => _i18n.languages;

export const selectCurrentLanguageDirection = createSelector([selectCurrentLanguageId], (id) => {
  return i18n.dir(id);
});

export const selectCurrentLanguage = createSelector(
  [selectCurrentLanguageId, selectLanguages],
  (id, languages) => {
    return languages.find((lng) => lng.id === id);
  }
);

export default i18nSlice.reducer;
