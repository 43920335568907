import handleError from '@fuse/core/errorHandler';
import { useEffect, useState } from 'react';
import { useInOfficeMutation } from '../services/skudApi';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StaffOnline = ({ staffId, variant="filled" }) => {
  const {t} = useTranslation('translations');
  const [isInOffice, setIsInOffice] = useState(false);
  const [inOffice, { isLoading }] = useInOfficeMutation();

  useEffect(() => {
    inOffice({ staffId }).unwrap().then(setIsInOffice).catch(handleError);
  }, [inOffice, staffId]);

  if (isLoading) return null;

  if (!isInOffice) return <Chip label={t('NotInOffice')} color="error" variant={variant} />;

  return <Chip label={t('InOffice')} color="success" variant={variant} />;
};

export default StaffOnline;
