import { isRejectedWithValue } from '@reduxjs/toolkit';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { API_BASE_URL } from '../constants/vars';

export const extraHeaders = (headers) => {
  const token = localStorage.getItem('jwt_access_token');
  if (token) headers.set('Authorization', `Bearer ${token}`);
  return headers;
};

export const rtkBaseQuery = (path = '/') =>
  fetchBaseQuery({
    baseUrl: API_BASE_URL + path,
    prepareHeaders: extraHeaders,
  });

export const rtkQueryErrorLogger = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const status = action.payload.status || null;
    const message = action.payload.data?.message || 'We got a rejected action!';
    if (status === 401) {
      const language = localStorage.getItem('language');
      const isDarkMode = localStorage.getItem('isDarkMode');
      localStorage.clear();
      localStorage.setItem('language', language);
      localStorage.setItem('isDarkMode', isDarkMode);
      window.location.href = '/';
      console.error('Unauthorized! Redirecting to login...');
    } else if (status > 300 && status < 600) {
      console.warn(message);
    }
  }
  return next(action);
};

export const urlWithSearchParams = (url, params) => {
  const searchParams = new URLSearchParams(params);
  return `${url}?${searchParams.toString()}`;
};
