import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const ClaimsApp = lazy(() => import('./ClaimsApp'));

const ClaimsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/apps/claims-processing',
      element: <ClaimsApp />,
    },
  ],
};

export default ClaimsAppConfig;
