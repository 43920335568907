import Dialog from '@mui/material/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, selectFuseDialogOptions, selectFuseDialogState } from 'app/store/fuse/dialogSlice';
import { DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

function FuseDialog() {
  const dispatch = useDispatch();
  const state = useSelector(selectFuseDialogState);
  const options = useSelector(selectFuseDialogOptions);

  return (
    <Dialog
      open={state}
      // onClose={(ev) => dispatch(closeDialog())}
      aria-labelledby="fuse-dialog-title"
      sx={{ minWidth: 400 }}
      classes={{
        paper: 'rounded-8',
      }}>
      <DialogTitle
        component="h5"
        sx={{
          color: '#888888',
          background: '#ECEFFF;',
          fontSize: 18,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: '30px',
          pr: '30px',
        }}>
        {options.title || <span />}
        <IconButton onClick={() => dispatch(closeDialog())}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: '#ECEFFF', px: '30px', minWidth: 300 }}>{options.children}</DialogContent>
    </Dialog>
  );
}

export default FuseDialog;
