import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { API } from 'app/configs/api';

import axios from 'axios';
import handleError from '@fuse/core/errorHandler';

import { closeChatPanel } from './stateSlice';

export const getContacts = createAsyncThunk('chatStore/contacts/getContacts', async (params) => {
  try {
    const response = await axios.get(API.contacts, {
      params,
    });
    return response.data;
  } catch (e) {
    handleError(e);
    return [];
  }
});

const contactsAdapter = createEntityAdapter({});

export const { selectAll: selectContacts, selectById: selectContactById } =
  contactsAdapter.getSelectors((state) => state.chatStore.contacts);

const contactsSlice = createSlice({
  name: 'chatStore/contacts',
  initialState: contactsAdapter.getInitialState({
    selectedContactId: null,
    onlineUsers: [],
  }),
  reducers: {
    setSelectedContactId: (state, action) => {
      state.selectedContactId = action.payload;
    },
    removeSelectedContactId: (state, action) => {
      state.selectedContactId = null;
    },
    setOnlineUsers: (state, action) => {
      state.onlineUsers = action.payload;
    },
    setContactPhoto: (state, { payload: { id, photoUrl } }) => {
      state.entities[id].photoURL = photoUrl;
    },
    updateContactById: (state, action) => {
      state.entities[action.payload.id] = {
        ...state.entities[action.payload.id],
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [getContacts.fulfilled]: (state, action) => {
      contactsAdapter.setAll(state, action.payload);
      state.loading = false;
    },
    [getContacts.pending]: (state, action) => {
      state.loading = true;
    },
    [getContacts.rejected]: (state, action) => {
      state.loading = false;
    },
    [closeChatPanel]: (state, action) => {
      state.selectedContactId = null;
    },
  },
});

export const {
  setSelectedContactId,
  removeSelectedContactId,
  setOnlineUsers,
  setContactPhoto,
  updateContactById,
} = contactsSlice.actions;

export const selectSelectedContactId = ({ chatStore }) => chatStore.contacts.selectedContactId;

export const selectOnlineUsers = ({ chatStore }) => chatStore.contacts.onlineUsers;

export default contactsSlice.reducer;
