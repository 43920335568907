import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguageAsync, selectCurrentLanguage, selectLanguages } from 'app/store/i18nSlice';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'app/store/userSlice';
import JwtService from 'src/app/auth/services/jwtService';
import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/uz-latn';

function LanguageSwitcher(props) {
  const currentLanguage = useSelector(selectCurrentLanguage);
  const languages = useSelector(selectLanguages);
  const [menu, setMenu] = useState(null);
  const user = useSelector(selectUser);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('uz-latn');

  useEffect(() => {
    setLanguage(i18n.language === 'ru' ? 'ru' : 'uz-latn');
  }, [i18n.language]);
  moment.locale(language);

  const dispatch = useDispatch();

  const langMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const langMenuClose = () => {
    setMenu(null);
  };

  async function handleLanguageChange(lng) {
    await dispatch(
      changeLanguageAsync({
        userId: user.id,
        lng: lng,
      })
    );
    // await JwtService.refreshToken();
    localStorage.setItem('language', lng.id);
    langMenuClose();
  }

  return (
    <>
      <Button className="h-40 w-64" onClick={langMenuClick}>
        <Typography
          className="mx-4 text-16 font-semibold dark:text-lightgrey"
          color="text.secondary"
        >
          {currentLanguage.title}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {languages.map((lng) => (
          <MenuItem
            key={lng.id}
            className="dark:bg-darker py-8"
            onClick={() => handleLanguageChange(lng)}
          >
            {/* <ListItemIcon className="min-w-40">
              <img
                className="min-w-20 dark:bg-white"
                src={`assets/images/flags/${lng.flag}.svg`}
                alt={lng.title}
              />
            </ListItemIcon> */}
            <ListItemText primary={lng.title} className="dark:text-lightgrey" />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
