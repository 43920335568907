import { useStaffByIdMutation } from 'src/app/services/staffApi';
import handleError from '@fuse/core/errorHandler';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Skeleton } from '@mui/material';
import { capitalizeFirstLetter, getAbsoluteFileUrl } from 'src/app/helpers/utils';

const ClaimStaffCard = ({ staffId }) => {
  const { t, i18n } = useTranslation('translations');
  const [getStaff] = useStaffByIdMutation();
  const [claimStaff, setClaimStaff] = useState(null);
  const lang = i18n.language === 'ru' ? 'ru' : 'uzb_lat';

  useEffect(() => {
    if (staffId) {
      getStaff({ id: staffId })
        .unwrap()
        .then((res) => setClaimStaff(res))
        .catch(handleError);
    }
  }, [staffId]);

  if (!claimStaff) {
    return (
      <div className="flex flex-start gap-14">
        <Skeleton variant="rounded" width={64} height={64} />
        <div>
          <Skeleton variant="text" width={210} height={14} />
          <Skeleton variant="text" width={210} height={14} />
          <Skeleton variant="text" width={210} height={14} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-start gap-14">
      <Avatar
        src={getAbsoluteFileUrl(claimStaff?.staffFileUrl)}
        alt="staff-photo"
        className="mt-5"
        variant="rounded"
        sx={{ width: 64, height: 64 }}
      />

      <div>
        <div className="flex flex-col mt-2">
          <div className="text-base capitalize font-inter font-500">
            {capitalizeFirstLetter(claimStaff.fullName.name)}{' '}
            {capitalizeFirstLetter(claimStaff.fullName.surname)}
          </div>
          <div className="text-sm font-inter text-gray-600 mt-2">
            {claimStaff.placeOfWork.departmentName && claimStaff?.placeOfWork?.departmentName[lang]}
          </div>
          <div className="text-sm font-inter text-gray-600 mt-2">
            {claimStaff.placeOfWork.positionName && claimStaff?.placeOfWork?.positionName[lang]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimStaffCard;
