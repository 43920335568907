import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const paymentsApi = createApi({
  reducerPath: 'paymentsApi',
  baseQuery: rtkBaseQuery('/ticket/Payments'),
  tagTypes: ['Payments'],
  endpoints: (builder) => ({
    getCurrency: builder.query({
      query: (params) => ({
        url: '/CBRuGetCurrrency',
        params,
      }),
    }),
    fetchCurrency: builder.mutation({
      query: (params) => ({
        url: '/CBRuGetCurrrency',
        params,
      }),
    }),
    createPayment: builder.mutation({
      query: (body) => ({
        url: '/CreateContractPayment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Payments'],
    }),
    deletePayment: builder.mutation({
      query: (params) => ({
        url: '/DeleteContractPayment',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Payments'],
    }),
    editPayment: builder.mutation({
      query: (body) => ({
        url: '/EditContractPayment',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Payments', id }, 'Payments'],
    }),
    getPaymentsList: builder.query({
      query: (body) => ({
        url: '/ContractPaymentsList',
        method: 'POST',
        body,
      }),
      providesTags: ['Payments'],
    }),
    getContractPaymentsList: builder.mutation({
      query: (body) => ({
        url: '/ContractPaymentsList',
        method: 'POST',
        body,
      }),
    }),
    getPaymentById: builder.query({
      query: (params) => ({
        url: '/GetContractPaymentById',
        params,
      }),
      providesTags: (result, error, { id }) => [{ type: 'Payments', id }],
    }),
    fetchProjectPaymentsList: builder.mutation({
      query: (body) => ({
        url: '/ProjectPaymentsList',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetCurrencyQuery,
  useFetchCurrencyMutation,
  useCreatePaymentMutation,
  useDeletePaymentMutation,
  useEditPaymentMutation,
  useGetPaymentsListQuery,
  useGetContractPaymentsListMutation,
  useGetPaymentByIdQuery,
  useFetchProjectPaymentsListMutation,
} = paymentsApi;
