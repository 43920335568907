import React, { useState, useEffect, useRef } from 'react';
import handleError from '@fuse/core/errorHandler';
import { useFetchSessionsByStaffIdMutation } from 'src/app/services/skudApi';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectUser } from 'app/store/userSlice';
import { Typography, Tooltip, IconButton, Drawer } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import FuseLoading from '@fuse/core/FuseLoading';
// import MyEntriesAndExits from './MyEntriesAndExits';
import { useThemeMediaQuery } from '@fuse/hooks';
import { Close, HelpOutline } from '@mui/icons-material';
import './ScrollbarProfile.css';

const date = new Date();

const Attendance = () => {
  const { t } = useTranslation('translations');
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [data, setData] = useState([]);
  const [currentDate, setCurrentDate] = useState(date);
  // const [calendarDay, setCalendarDay] = useState(null);
  const [fetchSessions, { isLoading }] = useFetchSessionsByStaffIdMutation();
  const user = useSelector(selectUser);
  const { staffId } = user;

  useEffect(() => {
    fetchSessions({ staffId, dateStart: currentDate.toISOString(), period: 2 })
      .unwrap()
      .then((res) => {
        setData(res);
      })
      .catch(handleError);
  }, [currentDate, fetchSessions, staffId]);

  const gotoPrevMonth = () => {
    setCurrentDate((prev) => moment(prev).subtract(1, 'month').toDate());
  };

  const gotoNextMonth = () => {
    setCurrentDate((prev) => moment(prev).add(1, 'month').toDate());
  };

  if (isLoading) {
    return <FuseLoading />;
  }

  return (
    <div className="p-24">
      <div className="flex items-center justify-start flex-wrap gap-20">
        <h4 className="font-inter font-600 text-2xl text-[#16151c] dark:text-white">
          {t('MyAttendance')}
        </h4>
        <div className="flex items-start">
          <Tooltip title={t('Previous')}>
            <IconButton aria-label="Previous" onClick={gotoPrevMonth} className="p-0">
              <ArrowBackIosIcon className="w-20" />
            </IconButton>
          </Tooltip>
          <Typography className="text-lg font-semibold tracking-tight whitespace-nowrap">
            {moment(currentDate).format('MMMM YYYY')}
          </Typography>
          <Tooltip title={t('Next')}>
            <IconButton aria-label="Next" onClick={gotoNextMonth} className="p-0">
              <ArrowForwardIosIcon className="w-20" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="mt-24 pr-32 flex flex-col max-h-[480px] overflow-auto overflow-x-scroll scrollbar scrollbar-horizontal">
        <div className="flex items-center justify-between sticky top-0">
          <h5 className="text-lg basis-1/5 dark:text-darkgrey min-w-[120px] font-inter leading-6 text-[#818181] py-10 border-b-1 border-[#EDEEF3] grow bg-white dark:bg-dark">
            {t('Date')}
          </h5>
          <h5 className="text-lg basis-1/5 dark:text-darkgrey min-w-[120px] font-inter leading-6 text-[#818181] py-10  border-b-1 border-[#EDEEF3] grow bg-white dark:bg-dark">
            {isMobile ? (
              <>
                {t('Entrance')}
                <Tooltip
                  placement="top"
                  enterTouchDelay={0}
                  title={
                    <div className="flex flex-col justify-center items-center">
                      <p className="text-10 mb-0">{t('FirstEntranceInADay')}</p>
                      {/* <p className="text-10 mb-0">({t('PressOnDateButton').toLowerCase()}</p>
                  <p className="text-10 mb-0">{t('ForDetailedView')})</p> */}
                    </div>
                  }
                  arrow
                >
                  <HelpOutline className="ml-4 text-18" />
                </Tooltip>
              </>
            ) : (
              t('FirstEntrance')
            )}
          </h5>
          <h5 className="text-lg basis-1/5 dark:text-darkgrey min-w-[150px] font-inter leading-6 text-[#818181] py-10 border-b-1 border-[#EDEEF3] grow bg-white dark:bg-dark">
            {isMobile ? (
              <>
                {t('Exit')}
                <Tooltip
                  placement="top"
                  enterTouchDelay={0}
                  title={
                    <div className="flex flex-col justify-center items-center">
                      <p className="text-10 mb-0">{t('LastExitInADay')}</p>
                      {/* <p className="text-10 mb-0">({t('PressOnDateButton').toLowerCase()}</p>
                  <p className="text-10 mb-0">{t('ForDetailedView')})</p> */}
                    </div>
                  }
                  arrow
                >
                  <HelpOutline className="ml-4 text-18" />
                </Tooltip>
              </>
            ) : (
              t('LastExit')
            )}
          </h5>
          <p className="text-lg basis-1/5 dark:text-darkgrey min-w-[120px] font-inter leading-6 text-[#818181] py-10  border-b-1 border-[#EDEEF3] grow bg-white dark:bg-dark">
            {t('Lateness')}
          </p>
          <h5 className="text-lg basis-1/5 dark:text-darkgrey min-w-[120px] font-inter leading-6 text-[#818181] py-10 border-b-1 border-[#EDEEF3] grow bg-white dark:bg-dark">
            {t('EarlyLeaving')}
          </h5>
        </div>
        <div className="grow">
          <ul>
            {data.map((stuff) => (
              <li
                key={stuff.date}
                // onClick={() => setCalendarDay(stuff.date)}
                className="flex items-center  hover:bg-blue-50 dark:hover:bg-grey-800 rounded-8 "
              >
                <p className="basis-1/5 min-w-[120px] ml-5">{moment(stuff.date).format('D')}</p>
                <p className="py-16 font-inter text-lg dark:text-white font-400 basis-1/5 min-w-[120px]">
                  {stuff.firstIn === null ? '--:--' : moment(stuff.firstIn).format('LT')}
                </p>
                <p className="py-16 font-inter text-lg dark:text-white font-400 basis-1/5 min-w-[150px]">
                  {stuff.lastOut === null ? '--:--' : moment(stuff.lastOut).format('LT')}
                </p>
                <div className="basis-1/5 min-w-[120px]">
                  {stuff.firstIn == null ? (
                    <p className="py-16 font-inter text-lg dark:text-white font-400">--:--</p>
                  ) : stuff.timeLate ? (
                    <p className="p-8 py-3 text-[#F45B69] bg-[#f45b6919] inline-block rounded-4">
                      {stuff.timeLate}
                    </p>
                  ) : (
                    <p className="p-8 py-3 text-[#3FC28A] bg-[#ECF9F4] dark:bg-[#3FC28A] dark:text-[#ECF9F4] inline-block rounded-4">
                      {t('OnTime')}
                    </p>
                  )}
                </div>
                <div className="basis-1/5 min-w-[120px]">
                  {stuff.lastOut == null ? (
                    <p className="py-16 font-inter text-lg dark:text-white font-400">--:--</p>
                  ) : stuff.earlyLeaving ? (
                    <p className="p-8 py-3 text-[#F45B69] bg-[#f45b6919] inline-block rounded-4">
                      {stuff.earlyLeaving}
                    </p>
                  ) : moment(stuff.date).isSame(moment(), 'day') &&
                    moment(moment().format('YYYY-MM-DD') + 'T18:00:00+05:00').diff(
                      moment(stuff.lastOut),
                      'minutes'
                    ) > 0 ? (
                    '--:--'
                  ) : (
                    <p className="p-8 py-3 text-[#3FC28A] bg-[#ECF9F4] dark:bg-[#3FC28A] dark:text-[#ECF9F4] inline-block rounded-4">
                      {t('OnTime')}
                    </p>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <Drawer
        PaperProps={{
          sx: { width: isMobile ? '300px' : '500px' },
        }}
        anchor="right"
        open={!!calendarDay}
        onClose={() => setCalendarDay(null)}
      >
        <div>
          <IconButton onClick={() => setCalendarDay(null)}>
            <Close />
          </IconButton>
        </div>
        <MyEntriesAndExits calendarDay={calendarDay} />
      </Drawer> */}
    </div>
  );
};

export default Attendance;
