import * as yup from 'yup';
import _ from '@lodash';
import SignInTour from './SignInHelp';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import JwtService from 'src/app/auth/services/jwtService';
import TextField from '@mui/material/TextField';
import handleError from '@fuse/core/errorHandler';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { useState, useEffect } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectIsRefreshTokenInvoked, setIsRefreshTokenInvoked } from 'app/store/userSlice';
import QRCode from 'react-qr-code';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import './SignInPage.css';
import { useThemeMediaQuery } from '@fuse/hooks';

const defaultValues = {
  userName: '',
  password: '',
};

function SignInPage() {
  const { t, i18n } = useTranslation('translations');
  const schema = yup.object().shape({
    userName: yup.string().required(t('YouMustEnterUsername')),
    password: yup.string().required(t('EnterPassword')).min(4, t('PasswordIsTooShort')),
  });
  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('md'));
  const isRefreshTokenInvoked = useSelector(selectIsRefreshTokenInvoked);

  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('uz');

  const dispatch = useDispatch();

  // console.log('signInPage rendered');

  const handleChangeLanguage = (lang) => {
    window.localStorage.setItem('language', lang);
    setLanguage(lang);
  };

  const isRefreshTokenAttempted = localStorage.getItem('isRefreshTokenAttempted');

  useEffect(() => {
    const lang = window.localStorage.getItem('language');
    if (lang !== 'uz' && lang !== 'ru') {
      window.localStorage.setItem('language', 'uz');
      setLanguage('uz');
    }
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }, [language]);

  // const refreshToken = async () => {
  //   // const refreshTokenExpirationDate = JwtService.getRefreshTokenExpirationDate();
  //   const refreshTokenExpirationDate = localStorage.getItem('refresh_token_exp');
  //   console.log('refreshTokenExpirationDate = ', refreshTokenExpirationDate);
  //   if (refreshTokenExpirationDate && moment(refreshTokenExpirationDate) > moment()) {
  //     console.log('refreshToken invoked');
  //     try {
  //       await JwtService.refreshToken();
  //       dispatch(setIsRefreshTokenInvoked(true));
  //       localStorage.setItem('isRefreshTokenAttempted', true);
  //       window.location.href = '/';
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   console.log('isRefreshTokenAttempted = ', isRefreshTokenAttempted);
  //   if (isRefreshTokenAttempted !== 'true' && localStorage.getItem('refresh_token')) {
  //     refreshToken();
  //     console.log('SignInPage. refreshToken invoked');
  //   }
  // }, []);

  function onSubmit(formValues) {
    if (loading) return;
    const lang = window.localStorage.getItem('language');
    if (lang !== 'uz' && lang !== 'ru') {
      window.localStorage.setItem('language', 'uz');
    }
    setLoading(true);
    JwtService.signInWithEmailAndPassword(formValues)
      .then((data) => {
        if (!data.token) {
          dispatch(showMessage({ message: t('IncorrectLoginOrPassword'), variant: 'error' }));
        }
      })
      .catch(handleError)
      .finally(() => {
        setLoading(false);
      });
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleLoginWithMyId = () => {
    // Перенаправление на MyID
    window.location.href = `https://${process.env.REACT_APP_MYID_URI}/api/v1/oauth2/authorization?client_id=${process.env.REACT_APP_MYID_CLIENT_ID}&response_type=code&scope=address,contacts,doc_data,common_data&method=strong&state=xyzABC123`;
  };

  return (
    <div
      className="h-full w-full flex flex-col md:flex-row justify-between px-16 pt-16"
      style={{
        backgroundImage: 'url(assets/images/pages/sign-in/background.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        objectPosition: 'top',
      }}
    >
      <div className="flex flex-col md:flex-row justify-between  m-10 h-[120px] w-full">
        <img
          className="h-80 w-120 sm:mr-32 mt-2 ml-112 mb-80 sm:mb-5 mx-auto sm:mx-0"
          src="assets/images/logo/logo.svg"
          alt="logo"
        />

        <div className="text-center">
          <p className="mt-32 text-14 sm:text-24 md:text-28 text-[#15519D]">
            {t('UzAtom_fullname')}
          </p>
        </div>
        <div className="flex gap-20 mt-44 mx-32 text-black">
          <div onClick={() => handleChangeLanguage('uz')} className="cursor-pointer">
            <img className="bg-white" alt="uz flag" src="assets/images/flags/UZ.svg" />
            <span className={language === 'uz' ? 'font-900' : 'font-400'}>Uz</span>
          </div>
          <div onClick={() => handleChangeLanguage('ru')} className="cursor-pointer">
            <img className="bg-white" alt="rus flag" src="assets/images/flags/RU.svg" />
            <span className={language === 'ru' ? 'font-900' : 'font-400'}>Ру</span>
          </div>
        </div>
      </div>

      <div className="max-w-[150px] fixed top-2/3 sm:top-1/3 md:left-112">
        <div className="hidden">
          <form
            name="loginForm"
            noValidate
            className="flex flex-col justify-center gap-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="userName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Login"
                  placeholder="Login"
                  error={!!errors.userName}
                  helperText={errors?.userName?.message}
                  variant="outlined"
                  required
                  fullWidth
                  InputProps={{ sx: { borderRadius: 2.5 } }}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Password"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  error={!!errors.password}
                  size="small"
                  helperText={errors?.password?.message}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: { borderRadius: 2.5 },
                  }}
                  required
                  fullWidth
                />
              )}
            />

            <button
              type="submit"
              className={`signin__button outline-black ${
                loading ? 'cursor-not-allowed' : 'cursor-pointer'
              }`}
            >
              {loading && <LoadingOutlined className="ml-5" />}
              {t('SignIn')}
            </button>
          </form>
          {/* {!isMobile && (
          <>
            <QRCode
              value="https://my.uzatom.uz"
              size={150}
              bgColor="transparent"
              fgColor="#15519D"
            />
            <div className="text-center mt-10 text-[#15519D]">{t('ScanFromMobileToEnter')}</div>
          </>
        )} */}
          {/* <hr className="border-grey-900 my-20" /> */}
          <hr className="h-px my-20 bg-[#004DA7] border-0"></hr>
        </div>
        <img
          alt="myid identification"
          src="assets/images/pages/sign-in/myid-logo.png"
          className="max-w-[150px]"
        />
        <button
          disabled={loading}
          onClick={handleLoginWithMyId}
          className={`signin__button outline-black ${
            loading ? 'cursor-not-allowed' : 'cursor-pointer'
          }  my-12`}
        >
          {t('SignInWithMyId')}
        </button>
      </div>
    </div>
  );
}

export default SignInPage;
