export const PositionCategories = {
  0: { label: 'Specialists', key: 'specialists' },
  1: { label: 'Managers', key: 'top' },
  2: { label: 'Management', key: 'management' },
  3: { label: 'TechnicalStaff', key: 'technical' },
};

export const UPLOAD_FILE_TYPE = {
  AVATAR: 0,
  BANNER: 1,
  CHAT: 2,
  TICKET_ATTACHMENT: 3,
  CONTRACT: 4,
  TEMPLATE: 5,
  ARTICLE: 6,
  EVENT_FILE: 7,
  RESOURCE_FILE: 8,
};
