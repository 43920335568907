import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';
import { API } from 'app/configs/api';
import { getUserDeviceInfo } from 'src/app/helpers/utils';
import moment from 'moment';

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  tokenRefreshInterval = process.env.REACT_APP_TOKEN_REFRESH_INTERVAL || 36000000;

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
            this.setRefreshToken(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();
    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'Log in again, please');
    }
  };

  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.data.user) {
          this.setSession(response.data.access_token);
          this.setRefreshToken(response.data.refreshToken);
          this.setRefreshTokenExpirationDate(response.data.refreshTokenExpiration);
          resolve(response.data.user);
          this.emit('onLogin', response.data.user);
        } else {
          reject(response.data.error);
        }
      });
    });
  };

  signInWithEmailAndPassword = (data) => {
    const deviceInfo = getUserDeviceInfo();
    return axios
      .post(API.login, {
        deviceInfo,
        deviceType: 10345134,
        ...data,
      })
      .then((response) => {
        if (response.data.token) {
          this.setSession(response.data.token);
          this.setRefreshToken(response.data.refreshToken);
          this.setRefreshTokenExpirationDate(response.data.refreshTokenExpiration);
          this.handleAuthentication();
        }
        return response.data;
      });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(API.profile)
        .then((response) => {
          if (response.data) {
            this.setSession(localStorage.jwt_access_token);
            resolve(response.data);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  refreshToken = () => {
    const refreshToken = this.getRefreshToken();
    const refreshTokenExpiration = this.getRefreshTokenExpirationDate();
    axios
      .post(API.refreshAccessToken, { refreshToken, refreshTokenExpiration })
      .then((response) => {
        if (response.data) {
          this.setSession(response.data.token);
          this.setRefreshToken(response.data.refreshToken);
          this.setRefreshTokenExpirationDate(response.data.refreshTokenExpiration);
        } else {
          this.logout();
        }
      })
      .catch((error) => {
        console.error(error);
        this.logout();
      });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  setRefreshToken = (refresh_token) => {
    if (refresh_token) {
      localStorage.setItem('refresh_token', refresh_token);
    } else {
      localStorage.removeItem('refresh_token');
    }
  };

  setRefreshTokenExpirationDate = (date) => {
    if (date) {
      localStorage.setItem('refresh_token_exp', date);
    } else {
      localStorage.removeItem('refresh_token_exp');
    }
  };

  logout = () => {
    const language = localStorage.getItem('language');
    const isDarkMode = localStorage.getItem('isDarkMode');
    localStorage.clear();
    localStorage.setItem('language', language);
    localStorage.setItem('isDarkMode', isDarkMode);
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };

  getRefreshToken = () => {
    return window.localStorage.getItem('refresh_token');
  };

  getRefreshTokenExpirationDate = () => {
    return window.localStorage.getItem('refresh_token_exp');
  };

  isFewMinutesToTokenDeathLeft = () => {
    const token = this.getAccessToken();
    const parsedToken = jwtDecode(token);
    if (token) {
      const expirationTime = moment(parsedToken.exp * 1000);
      if (moment() + Number(this.tokenRefreshInterval) >= expirationTime) {
        return true;
      }
    } else if (!token) {
      return true;
    } else {
      return false;
    }
  };
}

const instance = new JwtService();

export default instance;
