import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsGreetingsVisible, setIsGreetingsVisible } from 'app/store/userSlice';
import { Dialog } from '@mui/material';
import { useDispatch } from 'react-redux';
import './GreetingsDialog.css';

const GreetingsDialog = () => {
  const { t } = useTranslation('translations');
  const isVisible = useSelector(selectIsGreetingsVisible);
  const dispatch = useDispatch();

  const handleClose = () => {
    localStorage.setItem('isFirstTime', false);
    dispatch(setIsGreetingsVisible(false));
  };
  return (
    <Dialog fullScreen open={isVisible} onClose={handleClose}>
      <div
        className="flex flex-col  justify-center items-center h-full p-24"
        style={{
          backgroundImage: 'url(assets/images/greetings/greetings.jpg)',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="text-center bg-white rounded-16  max-w-[550px] p-40">
          <div className="text-center">
            <h1 className="text-24 font-bold greetings__title mb-10">{t('Greeting')}!</h1>
            <hr className="greetings__sharp-line"></hr>
          </div>
          <p className="my-20">
            {t('GladToSeeYouInOurTeam')}. {t('YourEnergyAndTalentWillBecomeContribution')}.{' '}
            {t('ForwardToNewAchievements')}!
          </p>
          <button className="greetings__button" onClick={handleClose}>
            {t('Start_verb')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default GreetingsDialog;
