import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const ResourcesCalendar = lazy(() => import('./resources-calendar/ResourcesCalendar'));
const MyCalendar = lazy(() => import('./my-calendar/MyCalendar'));

const CalendarAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/calendar/resources',
      element: <ResourcesCalendar />,
    },
    {
      path: 'apps/calendar/my',
      element: <MyCalendar />,
    },
  ],
};

export default CalendarAppConfig;
