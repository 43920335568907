import Media from '@fuse/core/Media';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const NewsMedia = ({ media, className }) => {
  if (media.length === 0) {
    return <Media id={0} name={'NoImage'} fileSrc={null} type={null} className={className} />;
  }

  if (media.length === 1 || media.length === 2) {
    return (
      <Media
        id={media[0].id}
        name={media[0].fileTitle}
        fileSrc={media[0].contentPath}
        type={media[0].contentType}
        className={className + ' object-center'}
      />
    );
  }

  return (
    <div className="w-full">
      <Swiper modules={[Navigation]} navigation loop>
        {media.map((file) => (
          <SwiperSlide key={file.id}>
            <Media
              id={file.id}
              fileSrc={file.contentPath}
              name={file.fileTitle}
              type={file.contentType}
              className="max-w-full object-center"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default NewsMedia;
