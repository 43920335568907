import MyNotificationItem from './components/MyNotificationItem';
import { ChevronLeft, ChevronRight, NotificationsNone } from '@mui/icons-material';
import {
  getAlarmsList,
  getNewAlarmsCount,
  selectAlarms,
  selectNewAlarmsCount,
} from 'app/store/alarmsSlice';
import { Tooltip, Badge, Button, Popover } from '@mui/material';
import { useMemo, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const rows = 10;

const MyNotifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('translations');
  const ref = useRef(null);
  const [page, setPage] = useState(1);

  const notifications = useSelector(selectAlarms);
  const totalPages = useMemo(() => {
    if (notifications) {
      return Math.ceil(notifications.totalItems / rows);
    } else {
      return 1;
    }
  }, [notifications]);

  const newNotificationsCount = useSelector(selectNewAlarmsCount);
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    handleGetAlarms();
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [page]);

  const handleGetAlarms = () => {
    dispatch(
      getAlarmsList({
        filters: '{}',
        first: (page - 1) * rows,
        rows: rows,
        sortField: 'id',
        sortOrder: 0,
      })
    );
    dispatch(getNewAlarmsCount());
  };

  return (
    <>
      <Button aria-describedby={id} onClick={handleClick} sx={{ padding: 0 }}>
        <Tooltip title={t('Notifications')} placement="bottom">
          <Badge badgeContent={newNotificationsCount} color="error">
            <span size="small" onClick={handleGetAlarms} sx={{ padding: 0 }}>
              <NotificationsNone className="text-grey-700 dark:text-lightgrey" />
            </span>
          </Badge>
        </Tooltip>
      </Button>
      <Popover open={open} id={id} anchorEl={anchorEl} onClose={handleClose} className="mt-32 p-12">
        <div className="w-[330px] md:w-[540px] h-[500px] overflow-y-auto flex flex-col justify-start border-2 border-white dark:bg-dark dark:text-white p-12">
          <p className="text-center font-semibold mb-10">
            {notifications && notifications.items?.length === 0
              ? t('NoNotifications')
              : t('Notifications')}
          </p>
          <div ref={ref}>
            {notifications &&
              notifications.items?.map((notification) => (
                <MyNotificationItem key={notification.id} notification={notification} />
              ))}
          </div>
          {notifications &&
            notifications.items?.length < notifications.totalItems &&
            notifications.totalItems > rows && (
              <div>
                <div className="flex justify-center items-center">
                  <Button type="text" onClick={() => setPage(page - 1)} disabled={page < 2}>
                    <ChevronLeft style={{ color: isDarkMode ? '#fff' : '' }} />
                  </Button>
                  <span>
                    {page}/{totalPages}
                  </span>
                  <Button
                    type="text"
                    onClick={() => setPage(page + 1)}
                    disabled={page === totalPages}
                  >
                    <ChevronRight style={{ color: isDarkMode ? '#fff' : '' }} />
                  </Button>
                </div>
              </div>
            )}
        </div>
      </Popover>
    </>
  );
};

export default MyNotifications;
