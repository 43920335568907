import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectUser } from 'app/store/userSlice';
import { useTranslation } from 'react-i18next';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';

function UserMenu() {
  const { t } = useTranslation('translations');
  const user = useSelector(selectUser);
  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex dark:text-lightgrey">
            {user.fio}
          </Typography>
        </div>

        {user.staffImageFileUrl ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={getAbsoluteFileUrl(user.staffImageFileUrl)}
          />
        ) : (
          <Avatar className="md:mx-4">{user?.fio?.[0]}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          component={NavLink}
          to="/sign-out"
          onClick={() => {
            userMenuClose();
          }}
          className="dark:text-lightgrey dark:bg-darker py-12"
        >
          <ListItemIcon className="min-w-40 dark:text-lightgrey">
            <FuseSvgIcon>heroicons-outline:logout</FuseSvgIcon>
          </ListItemIcon>
          <ListItemText primary={t('Exit')} />
        </MenuItem>
      </Popover>
    </>
  );
}

export default UserMenu;
