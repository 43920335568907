import ContractList from './contract/ContractList';
import PaymentList from './payment/PaymentList';
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

const Projects = lazy(() => import('./Projects'));
const ProjectDetails = lazy(() => import('./project/ProjectDetails'));
const ProjectViews = lazy(() => import('./project/ProjectViews'));

const ProjectsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'apps/projects',
      element: <Outlet />,
      children: [
        { index: true, element: <Projects /> },
        {
          path: ':projectId',
          element: <ProjectDetails />,
          children: [
            {
              path: 'contracts',
              children: [
                { index: true, element: <ContractList /> },
                { path: 'payments', element: <PaymentList /> },
                { path: ':contractId', element: <ContractList /> },
              ],
            },
            { path: ':view', element: <ProjectViews /> },
            { path: ':view/:taskId', element: <ProjectViews /> },
            { path: ':view/:taskId/:mode', element: <ProjectViews /> },
          ],
        },
      ],
    },
  ],
};

export default ProjectsAppConfig;
