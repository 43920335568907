import { Avatar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { renderFullName } from 'src/app/helpers/utils';
import { useGetStaffListQuery } from 'src/app/services/staffApi';
import FuseLoading from '../FuseLoading/FuseLoading';
import Image from '../Image/Image';

const UserInfoModal = ({ staffId }) => {
  const [staffData, setStaffData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('profileApp');

  const { data: staffList } = useGetStaffListQuery();

  useEffect(() => {
    if (staffList) {
      const staff = staffList.find((item) => item.staffID === staffId);
      setStaffData(staff);
    }
  }, [staffList]);

  if (!staffList) return null;

  return (
    <>
      {(loading && <FuseLoading />) || (
        <div>
          <div className="flex justify-center items-center">
            {staffData?.staffImageFileId ? (
              <Image
                className="w-100 h-100"
                name={staffData?.contact?.fio}
                id={staffData?.staffImageFileId}
                variant="avatar"
              />
            ) : (
              <Avatar sx={{ width: 100, height: 100 }}>
                {staffData?.fullName?.name?.slice(0, 1)}
              </Avatar>
            )}
          </div>

          <Typography
            variant="h6"
            className={`mt-20 text-center ${staffData ? 'text-black' : 'text-red'}`}
          >
            {staffData ? renderFullName(staffData) : t('DoesNotExistInHRM')}
          </Typography>

          <div className="flex flex-row justify-between flex-wrap mt-4">
            <Typography variant="body2" color="gray">
              {t('Phone')}:
            </Typography>
            <Typography variant="body1">{staffData?.contact?.phoneMain}</Typography>
          </div>
          <div className="flex flex-row justify-between flex-wrap mt-4">
            <Typography variant="body2" color="gray">
              {t('PhoneAdditional')}:
            </Typography>
            <Typography variant="body1">{staffData?.contact?.phoneAdditional}</Typography>
          </div>
          <div className="flex flex-row justify-between flex-wrap mt-4">
            <Typography variant="body2" color="gray">
              {t('Email')}:
            </Typography>
            <Typography variant="body1">{staffData?.contact?.eMail}</Typography>
          </div>
          <div className="flex flex-row justify-between flex-wrap mt-4">
            <Typography variant="body2" color="gray">
              {t('EmailAdditional')}:
            </Typography>
            <Typography variant="body1"> {staffData?.contact?.eMailAdditional}</Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default UserInfoModal;
