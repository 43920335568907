import SkudDepartments from '../departments/SkudDepartments';
import SkudEmployees from './SkudEmployees';
import SubEmployeeSkudDetail from '../skud-detail/SubEmployeeSkudDetail';
import SkudReport from '../report/SkudReport';
import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const Employees = lazy(() => import('./Employees'));

const SkudConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  authRoles: authRoles.user,
  routes: [
    {
      path: 'apps/skud/departments',
      element: <SkudDepartments />,
    },
    {
      path: 'apps/skud/departments/:id',
      element: <SkudEmployees />,
    },
    {
      path: 'apps/skud/employees/:id',
      element: <SubEmployeeSkudDetail />,
    },
    {
      path: 'apps/skud/employers',
      element: <Employees />,
    },
    {
      path: 'apps/skud/report',
      element: <SkudReport />,
      auth: authRoles.hr || authRoles.top,
    },
  ],
};

export default SkudConfig;
