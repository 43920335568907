import handleError from '@fuse/core/errorHandler';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'app/configs/api';
import axios from 'axios';

const initialState = {
  staff: [],
  loading: false,
};

export const getStaffList = createAsyncThunk('staff/getStaffListAll', async () => {
  try {
    const response = await axios.get(API.getStaffListAll);
    return response.data;
  } catch (e) {
    handleError(e);
    return [];
  }
});

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {},
  extraReducers: {
    [getStaffList.fulfilled]: (state, action) => {
      state.staff = action.payload;
    },
    [getStaffList.pending]: (state, action) => {
      state.loading = true;
    },
    [getStaffList.rejected]: (state, action) => {
      state.loading = false;
      state.staff = [];
    },
  },
});

export const selectAllStaff = ({ staffSlice }) => staffSlice.staff;

export default staffSlice.reducer;
