import handleError from '@fuse/core/errorHandler';
import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useMessage from 'src/app/hooks/useMessage';
import {
  useCreateClaimTypeMutation,
  useUpdateClaimTypeMutation,
} from 'src/app/services/claim/claimTypeApi';

const defaultValues = {
  name: '',
};

const ClaimTypeForm = ({ claimType, isEdit, onClose, refetch }) => {
  const { t } = useTranslation('translations');
  const [update, { isLoading }] = useUpdateClaimTypeMutation();
  const [create] = useCreateClaimTypeMutation();
  const { showMessage } = useMessage();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: claimType ?? defaultValues,
  });

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const onSubmit = async (values) => {
    const message = isEdit ? t('ClaimTypeSuccessfullyUpdated') : t('ClaimTypeSuccessfullyAdded');
    const promise = isEdit ? update(values) : create(values);
    promise
      .unwrap()
      .then(() => {
        showMessage({ message: message, variant: 'success' });
        handleClose();
      })
      .catch(handleError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-10 max-w-7xl">
      <div className="mb-10">
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextField
                {...field}
                fullWidth
                label={t('Title')}
                error={!!errors.title}
                variant="outlined"
                required
              />
            );
          }}
        />
      </div>
      <Button
        disabled={isLoading || !isValid}
        type="submit"
        className="w-full"
        variant="contained"
        color="secondary"
      >
        <span className="mx-8">{t('Save')}</span>
      </Button>
    </form>
  );
};

export default ClaimTypeForm;
