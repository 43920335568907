import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { lighten } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Input, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react'

function UsersTableHead(props) {
  const { t } = useTranslation('translations');

  const [resizeViewport, setReziseViewport] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setReziseViewport(window.innerWidth);
    })
  }, [resizeViewport]);

  return (
    <TableHead>
      <TableRow
        style={{minHeight: resizeViewport < 1575 ? '120px' : ''}} 
        className={`${resizeViewport < 1575 ? 'flex flex-col' : ''} items-center h-48 sm:h-64`}
      >
        <TableCell
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
          }}
          className={`p-4 ${resizeViewport > 1575 ? 'md:p-16' : ''}`}
          key="fullname"
          padding="none"
        >
          <Paper 
            xs={12}
            className={`p-4 flex justify-between items-center border-1 h-40 rounded-full shadow-none ${resizeViewport > 1575 ? 'px-16 py-4' : ''}`}
          >
            <FuseSvgIcon color="action" size={20}>
              heroicons-solid:search
            </FuseSvgIcon>
            <Input
              onChange={props.handleSearchUser}
              value={props.searchUser}
              placeholder={t('FullName')}
              type="search"
              variant="outlined"
              disableUnderline
              className="flex flex-1 px-8"
              inputProps={{
                'aria-label': 'Search',
              }}
            />
          </Paper>
        </TableCell>
        {resizeViewport > 1575 && <TableCell
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
          }}
          className={`p-4 text-center ${resizeViewport > 1575 ? 'md:p-16' : ''}`}
          key="hrmusers"
          padding="none"
        >
          {t('HrmUsers')}
        </TableCell>}
        {resizeViewport > 1575 && <TableCell
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
          }}
          className={`${resizeViewport > 1575 ? 'p-4 pl-96' : ''}`}
          key="actions"
          padding="none"
        >
          {t('Actions')}
        </TableCell>}
      </TableRow>
    </TableHead>
  );
}

export default UsersTableHead;
