import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { useGetProjectByStaffIdQuery } from 'src/app/services/ticketsApi';
import { useNavigate } from 'react-router-dom';
import './ScrollbarProfile.css';

const StuffProjects = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const { t } = useTranslation('translations');
  const { staffId } = user;

  const { data: projects } = useGetProjectByStaffIdQuery({ staffId });

  return (
    <div className="p-24">
      <h4 className="font-inter font-600 text-2xl text-[#16151c] dark:text-white">
        {t('MyProjects')}
      </h4>
      <div className="mt-24 pr-32 flex flex-col max-h-[480px] overflow-x-scroll scrollbar-horizontal overflow-auto scrollbar">
        <div className="flex items-center justify-between sticky top-0 bg-white dark:bg-dark">
          <h5 className="text-lg font-inter leading-6 min-w-[40px] text-[#818181] dark:text-darkgrey py-10 border-b-1 border-[#EDEEF3] basis-1/12">
            №
          </h5>
          <h5 className="text-lg font-inter leading-6 min-w-[220px] text-[#818181] dark:text-darkgrey py-10 border-b-1 border-[#EDEEF3] basis-1/3">
            {t('ProjectTitle')}
          </h5>
          <h5 className="text-lg font-inter leading-6 min-w-[100px] text-[#818181] dark:text-darkgrey py-10 border-b-1 border-[#EDEEF3] basis-1/5">
            {t('StartDate')}
          </h5>
          <h5 className="text-lg font-inter leading-6 min-w-[100px] text-[#818181] dark:text-darkgrey py-10 pl-10 border-b-1 border-[#EDEEF3] basis-1/5">
            {t('EndDate')}
          </h5>
          <h5 className="text-lg font-inter leading-6 min-w-[120px] text-[#818181] dark:text-darkgrey py-10 border-b-1 border-[#EDEEF3] basis-1/5">
            {t('Status')}
          </h5>
        </div>
        <div>
          <ul>
            {projects?.map((project, index) => (
              <li key={project.id} className="flex items-center">
                <p className="py-16 font-inter text-lg dark:text-white font-400 basis-1/12 min-w-[40px]">
                  {index + 1}
                </p>
                <p
                  className="py-16 font-inter text-lg dark:text-white font-400 basis-1/3 min-w-[220px] cursor-pointer hover:underline"
                  onClick={() => navigate(`/apps/projects/${project.id}/board`)}
                >
                  {project.title}
                </p>
                <p className="py-16 font-inter text-lg dark:text-white font-400 basis-1/5 min-w-[100px]">
                  {project.startDate != null ? moment(project.startDate).format('DD.MM.YYYY') : ''}
                </p>
                <p className="py-16 font-inter text-lg dark:text-white font-400 basis-1/5 min-w-[100px]">
                  {project.endDate != null ? moment(project.endDate).format('DD.MM.YYYY') : ''}
                </p>
                <div className="basis-1/5 min-w-[120px]">
                  {project.status === 0 ? (
                    <p className="p-8 py-3 text-[#EFBE12] bg-[#efbe1219] inline-block">
                      {t('InProgress')}
                    </p>
                  ) : (
                    <p className="p-8 py-3 text-[#3FC28A] bg-[#ECF9F4] inline-block">
                      {t('Finished')}
                    </p>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StuffProjects;
