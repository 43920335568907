import Confetti from 'react-confetti';
import useWindowSize from 'src/app/hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { Dialog } from '@mui/material';
import { useUser } from 'src/app/hooks/useUser';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';
import './HappyBirthdayDialog.css';

const HappyBirthdayDialog = ({ isVisible, onClose }) => {
  const { t } = useTranslation('translations');
  const { width, height } = useWindowSize();
  const { user } = useUser();

  return (
    <Dialog fullScreen open={isVisible} onClose={onClose}>
      <>
        <div
          className="flex flex-col  justify-center items-center h-full w-full p-24"
          style={{
            backgroundImage: 'url(assets/images/greetings/greetings.jpg)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="text-center bg-white rounded-16  max-w-[650px] p-40 relative">
            <img
              className="w-[140px] h-[140px] rounded-8 object-cover absolute m-auto left-0 right-0 -top-[70px]"
              alt="user photo"
              src={getAbsoluteFileUrl(user?.staffImageFileUrl)}
            />
            <div className="text-center">
              <h1 className="text-24 font-600 happy-birthday__title mb-10 mt-32 text-blue-700">
                {t('HappyBirthday')}!
              </h1>
              <hr className="happy-birthday__sharp-line"></hr>
            </div>
            <div>
              <p className="text-16 my-12 font-500 text-grey-800">
                {t('HappyBirthdayСongratulations')}
              </p>
              <p className="font-600 text-16 mb-16 text-grey-800">{t('BestRegardsUzAtomTeam')}</p>
            </div>
            <button className="happy-birthday__button" onClick={onClose}>
              {t('Close')}
            </button>
          </div>
        </div>
        <Confetti width={width} height={height} />
      </>
    </Dialog>
  );
};

export default HappyBirthdayDialog;
