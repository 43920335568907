import dayjs from 'dayjs';
import NivoLineChart from '../../home/components/NivoLIneChart';
import NivoPieChart from '../../home/components/NivoPieChart';
import { DatePicker } from 'antd';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './SkudReportHeader.css';

const { RangePicker } = DatePicker;

const SkudReportHeader = forwardRef(
  ({ onRangeChange, fromDate, toDate, dataForPieChart, dataForLineChart, showTable }, ref) => {
    const { t } = useTranslation('translations');

    const rangePresets = [
      {
        label: t('LastWeek'),
        value: [dayjs().add(-7, 'd'), dayjs()],
      },
      {
        label: t('LastTwoWeeks'),
        value: [dayjs().add(-14, 'd'), dayjs()],
      },
      {
        label: t('LastThirtyDays'),
        value: [dayjs().add(-30, 'd'), dayjs()],
      },
      {
        label: t('LastNinetyDays'),
        value: [dayjs().add(-90, 'd'), dayjs()],
      },
    ];

    const handlePieChartClick = (sector) => {
      showTable();
    };

    const { isDarkMode } = useSelector((state) => state.themeSlice);

    return (
      <div className="bg-white dark:bg-darker pt-10">
        <div className="flex justify-center items-center mb-20">
          <div className="text-24 mr-10">
            <div>{t('SummaryReport')}</div>
          </div>
          <div ref={ref}>
            <RangePicker
              presets={[
                {
                  label: <span aria-label="Current Time to End of Day">{t('Today')}</span>,
                  value: () => [dayjs(), dayjs().endOf('day')],
                },
                ...rangePresets,
              ]}
              value={[dayjs(fromDate, 'YYYY-MM-DD'), dayjs(toDate, 'YYYY-MM-DD')]}
              format="DD.MM.YYYY"
              onChange={onRangeChange}
              allowClear={false}
              className={isDarkMode ? 'createDateRangePicker dark:bg-darker' : ''}
              dropdownClassName={isDarkMode ? 'createDateRangePicker' : ''}
            />
          </div>
        </div>
        <div className="bg-white dark:bg-dark flex flex-wrap justify-around">
          <div className="text-center mt-5">
            {t('LateComingsAndEarlyLeavings')}
            <div className="h-[300px] w-[300px] md:h-[400px] md:w-[400px] lg:h-[500px] lg:w-[500px] cursor-pointer">
              <NivoPieChart data={dataForPieChart} onClick={handlePieChartClick} />
            </div>
          </div>
          <div className="text-center mt-5">
            {t('LateComingsAndEarlyLeavingsCharts')}
            <div className="h-[300px] w-[500px] md:h-[400px] md:w-[600px] lg:h-[500px] lg:w-[800px]">
              <NivoLineChart
                data={dataForLineChart}
                xTitle={t('Dates')}
                yTitle={t('ViolationsCount')}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SkudReportHeader;
