import { Progress } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SkudDepartment = ({ dep }) => {
  const { t } = useTranslation('translations');
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const lang = i18n.language === 'ru' ? 'ru' : 'uzb_lat';

  const handleDepClick = () => {
    navigate(`/apps/skud/departments/${dep.id}`);
  };

  if (!dep?.id) return null;

  return (
    <div
      className="h-90 flex flex-row justify-around items-center flex-wrap gap-60 bg-white dark:bg-darker p-10 border-2 rounded-lg my-5 hover:border-blue-400 hover:cursor-pointer"
      onClick={handleDepClick}
    >
      <div className="flex items-center w-[300px] text-16">{dep.lang?.[lang]}</div>
      <div className="flex flex-col justify-between items-center ">
        <div>{t('ByStaff')}:</div>
        <div className="text-40 text-black-900">{dep.positionCount}</div>
      </div>
      <div className="flex flex-col justify-between items-center ">
        <div>
          {t('Employees', { context: 'genitive' })}: {dep?.staffCount}
        </div>
        <div className="text-40 text-black-900 dark:text-white">
          <Progress
            percent={Math.ceil((dep.staffCount * 100) / dep.positionCount)}
            format={(percent) => `${percent}%`}
            steps={5}
          />
        </div>
      </div>
      <div className="flex flex-col justify-between items-center ">
        <div>
          {t('InOffice')}: {dep?.staffInOfficeCount}
        </div>
        <div className="text-40 text-green-900">
          <Progress
            type="dashboard"
            percent={Math.ceil((dep.staffInOfficeCount * 100) / dep.staffCount)}
            format={(percent) => `${percent}%`}
            size="small"
          />
        </div>
      </div>
      <div className="flex flex-col justify-between items-center ">
        <div>{t('Absent', { context: 'plural' })}:</div>
        <div className="text-40">
          <span
            className={
              dep.staffCount - dep.staffInOfficeCount === 0 ? 'text-green-500' : 'text-orange-900'
            }
          >
            {dep.staffCount - dep.staffInOfficeCount}
          </span>
        </div>
      </div>
    </div>
  );
};

export default SkudDepartment;
