import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const articlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: rtkBaseQuery('/staff/Articles'),
  tagTypes: ['Articles'],
  endpoints: (builder) => ({
    // GET /api/staff/Articles/GetArticleList
    // POST  /api/staff/Articles/CreateArticle
    // POST  /api/staff/Articles/DeleteArticle
    // POST  /api/staff/Articles/EditArticle
    // GET /api/staff/Articles/GetArticleById
    getArticleList: builder.query({
      query: () => '/GetArticleList',
      providesTags: ['Articles'],
    }),
    fetchArticleList: builder.mutation({
      query: (body) => ({
        url: '/GetArticleList',
        method: 'POST',
        body,
      }),
    }),
    createArticle: builder.mutation({
      query: (body) => ({
        url: '/CreateArticle',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    deleteArticle: builder.mutation({
      query: (id) => ({
        url: `/DeleteArticle?id=${id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Articles'],
    }),
    editArticle: builder.mutation({
      query: (body) => ({
        url: '/EditArticle',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Articles', id }, 'Articles'],
    }),
    getArticleById: builder.query({
      query: (id) => `/GetArticleById?id=${id}`,
      providesTags: (result, error, id) => [{ type: 'Articles', id }],
    }),
  }),
});

export const {
  useGetArticleListQuery,
  useFetchArticleListMutation,
  useCreateArticleMutation,
  useDeleteArticleMutation,
  useEditArticleMutation,
  useGetArticleByIdQuery,
} = articlesApi;
