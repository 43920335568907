import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const claimExecutorApi = createApi({
  reducerPath: 'claimExecutorApi',
  baseQuery: rtkBaseQuery('/claim/Executor'),
  tagTypes: ['Executors'],
  endpoints: (builder) => ({
    getAllClaimExecutors: builder.query({
      query: () => ({
        url: '/GetAll',
      }),
      providesTags: ['Executors'],
    }),
    getClaimExecutorById: builder.query({
      query: (id) => ({
        url: '/GetById',
      }),
      providesTags: (result, error, { id }) => [{ type: 'Executors', id }],
    }),
    createClaimExecutor: builder.mutation({
      query: (body) => ({
        url: '/Create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Executors'],
    }),
    updateClaimExecutor: builder.mutation({
      query: (body) => ({
        url: '/Update',
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Executors', id }, 'Executors'], //Need to check if invalidating is working
    }),
    deleteClaimExecutor: builder.mutation({
      query: (body) => ({
        url: '/Delete',
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Executors'],
    }),
  }),
});

export const {
  useGetAllClaimExecutorsQuery,
  useGetClaimExecutorByIdQuery,
  useCreateClaimExecutorMutation,
  useUpdateClaimExecutorMutation,
  useDeleteClaimExecutorMutation,
} = claimExecutorApi;
