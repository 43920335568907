import SimpleDialog from '@fuse/core/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import useMessage from 'src/app/hooks/useMessage';
import { Add } from '@mui/icons-material';
import { Autocomplete, Button, FormLabel, IconButton, List, ListItem, ListItemText, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBindContractToProjectMutation, useUnBindContractFromProjectMutation } from 'src/app/services/contractsApi';
import { useGetAllProjectListQuery } from 'src/app/services/ticket/projectsApi';

const ContractProjectList = ({ data }) => {
  const { t } = useTranslation('translations');
  const { projectIds, mainProjectId } = data;
  const { projectId, contractId } = useParams();
  const [open, setOpen] = useState(false);
  const { data: projects } = useGetAllProjectListQuery();
  const [selectedProject, setSelectedProject] = useState(null);
  const [bind] = useBindContractToProjectMutation();
  const [unbind] = useUnBindContractFromProjectMutation();
  const { showMessage } = useMessage();

  const canEdit = mainProjectId === +projectId;

  const handleAddProject = () => setOpen(true);

  const handleCloseDialog = () => {
    setOpen(false);
    setSelectedProject(null);
  };

  const handleConfirm = () => {
    bind({ contractId: +contractId, projectIdCurrent: +projectId, projectIdNew: selectedProject.key })
      .unwrap()
      .then(() => {
        showMessage({ message: t('ProjectSuccessfullyAdded'), variant: 'success'});
        handleCloseDialog();
      })
      .catch(handleError);
  };

  const handleUnbindProject = (id) => {
    if (window.confirm(t('AreYouSureToDeleteProject'))) {
      unbind({ contractId: +contractId, projectIdCurrent: +projectId, projectIdToDelete: id })
        .unwrap()
        .then(() => {
          showMessage({ message: t('ProjectSuccessfullyDeleted')});
        })
        .catch(handleError);
    }
  };

  return (
    <div>
      <div className="flex items-center">
        <FormLabel>{t('Projects')}</FormLabel>
      </div>

      {projectIds && (
        <List className="p-0">
          {projectIds.map((project) => (
            <ListItem className="py-0 px-8 hover:bg-grey-100" key={project}>
              <ListItemText>{projects?.find((p) => p.key === project)?.value}</ListItemText>
              <div>
                {project !== +projectId && canEdit && (
                  <button type="button" className="text-xs text-red hover:underline" onClick={() => handleUnbindProject(project)}>
                    {t('Delete')}
                  </button>
                )}
              </div>
            </ListItem>
          ))}
        </List>
      )}
      {canEdit && (
          <button type="button" className="text-11 text-green hover:underline"  onClick={handleAddProject}>
            + {t('AddProject')}
          </button>
        )}

      <SimpleDialog
        open={open}
        onClose={handleCloseDialog}
        title={t('AddProjectToContract')}
      >
        <div className="flex items-center gap-8">
          <Autocomplete
            options={projects?.filter((p) => !projectIds.includes(p.key))||[]}
            getOptionLabel={(option) => option.value}
            label={t('Organization')}
            isOptionEqualToValue={(option, value) => option.key === value}
            renderOption={(_props, option) => (
              <li {..._props} key={option.key}>
                {option.value}
              </li>
            )}
            value={selectedProject}
            onChange={(e, value) => {
              setSelectedProject(value);
            }}
            size="small"
            fullWidth
            renderInput={(params) => <TextField {...params} />}
          />

          <Button variant="outlined" color="success" onClick={handleConfirm}>
            {t('Add')}
          </Button>
        </div>
      </SimpleDialog>
    </div>
  );
};

export default ContractProjectList;
