import { useTranslation } from 'react-i18next';

const { Tour } = require('antd');

const SignInTour = ({ isOpen, onClose, ref1, ref2, ref3, ref4, contentSwitched }) => {
  const { t } = useTranslation('translations');

  const steps = [
    {
      title: t('EnterLogin'),
      description: t('EnterUzAtomMailLogin'),
      target: () => ref1.current,
      nextButtonProps: {
        children: t('Further'),
      },
    },
    {
      title: t('EnterPassword'),
      description: t('EnterUzAtomMailPassword'),
      target: () => ref2.current,
      nextButtonProps: {
        children: t('Further'),
      },
      prevButtonProps: {
        children: t('Previous'),
      },
    },
    {
      title: t('PressButton'),
      description: t('PressThisButtonOrEnterButtonOnKeyboard'),
      target: () => ref4.current,
      prevButtonProps: {
        children: contentSwitched ? `Назад` : 'Oldingi',
      },
    },
  ];

  return <Tour open={isOpen} onClose={onClose} steps={steps} />;
};

export default SignInTour;
