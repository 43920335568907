export const ProjectRoles = {
  0: { label: 'Executor', key: 'executor' },
  1: { label: 'Owner', key: 'owner' },
  2: { label: 'ResponsibleUnderContract', key: 'contractResponsible' },
  3: { label: 'Observer', key: 'observer' },
};

export const PROJECT_ROLE = {
  EXECUTOR: 0,
  OWNER: 1,
  CONTRACT_RESPONSIBLE: 2,
  OBSERVER: 3,
};

export const TASK_STATUS_TYPE = {
  NEW: 0,
  IN_PROGRESS: 1,
  CLOSED: 2,
  ON_HOLD: 3,
  REJECTED: 4,
  SCHEDULED: 5,
};

export const TaskStatusTypes = {
  0: { key: 'new', label: 'New_female' },
  1: { key: 'inProgress', label: 'InWork' },
  2: { key: 'closed', label: 'Closed' },
  3: { key: 'onHold', label: 'Paused' },
  4: { key: 'rejected', label: 'Rejected' },
  5: { key: 'scheduled', label: 'Planned' },
};

export const TASK_PRIORITY_TYPE = {
  LOW: 0,
  NORMAL: 1,
  HIGH: 2,
  IMMEDIATE: 3,
};

export const TaskPriorityTypes = {
  0: { key: 'low', label: 'Low' },
  1: { key: 'normal', label: 'Usual' },
  2: { key: 'high', label: 'High' },
  3: { key: 'immediate', label: 'Urgent' },
};

export const TaskMemberRoles = {
  0: { label: 'Responsible', key: 'responsible' },
  1: { label: 'Producer', key: 'producer' },
  2: { label: 'Observer', key: 'observer' },
  3: { label: 'Executor', key: 'executor' },
};

export const TASK_MEMBER_ROLE = {
  RESPONSIBLE: 0,
  PRODUCER: 1,
  OBSERVER: 2,
  EXECUTOR: 3,
};

export const TASK_TYPE = {
  TICKET: 0,
  PHASE: 1,
  MILESTONE: 2,
};

// public enum СurrencyType
// {
//     [Description("USD")]
//     USD = 0,

//     [Description("EUR")]
//     EUR = 1,

//     [Description("RUB")]
//     RUB = 2,

//     [Description("UZS")]
//     UZS = 3,
// }
export const CURRENCY_TYPE = {
  USD: 0,
  EUR: 1,
  RUB: 2,
  UZS: 3,
};

export const CURRENCY_TYPES_ARRAY = [
  {
    id:0,
    label: 'USD'
  },
  {
    id:1,
    label: 'EUR'
  },
  {
    id:2,
    label: 'RUB'
  },
  {
    id:3,
    label: 'UZS'
  }
];

export const CurrencyTypes = {
  0: { key: 'usd', label: 'USD' },
  1: { key: 'eur', label: 'EUR' },
  2: { key: 'rub', label: 'RUB' },
  3: { key: 'uzs', label: 'UZS' },
};
