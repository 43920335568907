export const INTERNAL_DOCUMENTS_CONSTANTS = [
  // {
  //   id: 1,
  //   contentPath: 'https://my.uzatom.uz/Acts/kd_uz.pdf',
  //   contentType: null,
  //   fileName: 'kd_ru.pdf',
  //   fileTitle: 'Jamoa shartnomasi',
  //   createdDate: '2024-06-06T10:51:56.811611',
  //   fileType: 10,
  // },
  // {
  //   id: 2,
  //   contentPath: 'https://my.uzatom.uz/Acts/kd_ru.pdf',
  //   contentType: null,
  //   fileName: 'kd_ru.pdf',
  //   fileTitle: 'Коллективный договор',
  //   createdDate: '2024-06-06T10:52:56.811611',
  //   fileType: 10,
  // },
  // {
  //   id: 3,
  //   contentPath: 'https://my.uzatom.uz/Acts/welcome.pdf',
  //   contentType: null,
  //   fileName: 'welcome.pdf',
  //   fileTitle: 'Welcome',
  //   createdDate: '2024-06-06T10:53:56.811611',
  //   fileType: 10,
  // },
];
