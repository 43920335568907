import clsx from 'clsx';
import axios from 'axios';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ContactListItem from '../main/ContactListItem';
import Toolbar from '@mui/material/Toolbar';
import SimpleDialog from '@fuse/core/SimpleDialog';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import MyAutocomplete from '@fuse/core/MyAutocomplete';
import Typography from '@mui/material/Typography';
import ContactAvatar from '../../ContactAvatar';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { lighten } from '@mui/material/styles';
import { getContacts, selectContacts, selectContactById } from 'app/store/chatStore/contactsSlice';
import { API } from 'app/configs/api';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { selectUser } from 'app/store/userSlice';
import { ChatAppContext } from '../../ChatApp';
import { useTranslation } from 'react-i18next';
import { addNewMember, selectChatById } from 'app/store/chatStore/chatsSlice';

function ContactSidebar(props) {
  const { t } = useTranslation('translations');
  const { setContactSidebarOpen } = useContext(ChatAppContext);
  const routeParams = useParams();
  const chatId = routeParams.id;

  const user = useSelector(selectUser);
  const contacts = useSelector(selectContacts);
  const contact = useSelector((state) => selectContactById(state, chatId));
  const group = useSelector((state) => selectChatById(state, chatId));
  const chat = group ?? contact;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    if (chat?.type === 1) {
      setMembers(
        chat.members.map((member) => ({
          ...member,
          photoURL:
            contacts.find((_contact) => _contact.id === member.id)?.photoURL ??
            (member.id === user.id ? user.photoURL : null),
        }))
      );
    }
  }, [chat, contacts]);

  useEffect(() => {
    if (open && contacts?.length === 0) {
      dispatch(getContacts({ userId: user.id }));
    }
  }, [contacts, open]);

  const { handleSubmit, control, formState, reset } = useForm();
  const { errors } = formState;

  const dispatch = useDispatch();

  const onSubmit = async (formValues) => {
    setLoading(true);
    try {
      const newMembers = formValues.userId;
      formValues.userId = formValues.userId.map((el) => el.id);
      formValues.groupId = chatId;
      await axios.post(API.addUserToGroup, formValues);

      if (!(newMembers[0].id === members.at(-1).id)) {
        dispatch(addNewMember({ chatId, newMembers }));
      }
      reset({ userId: [] });
    } catch (e) {
      console.log(e.message);
    }
    setLoading(false);
    setOpen(false);
  };

  if (!chat) {
    return null;
  }

  return (
    <div className="flex flex-col flex-auto h-full">
      <Box
        className="border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <Toolbar className="flex items-center px-4">
          <IconButton onClick={() => setContactSidebarOpen(false)} color="inherit" size="large">
            <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>
          <Typography className="px-4 font-medium text-16" color="inherit" variant="subtitle1">
            {chat.type === 1 ? t('GroupInformation') : t('ContactInformation')}
          </Typography>
        </Toolbar>
      </Box>

      <div className="flex flex-col justify-center items-center mt-32">
        <ContactAvatar className="w-160 h-160 text-64" contact={chat} />
        <Typography className="mt-16 text-16 font-medium">{chat.name || chat.fullName}</Typography>

        <Typography color="text.secondary" className="mt-2 text-13">
          {chat.about}
        </Typography>
      </div>
      <div className="w-full p-24">
        {chat.attachments?.media && (
          <>
            <Typography className="mt-16 text-16 font-medium">Media</Typography>
            <div className="grid grid-cols-4 gap-4 mt-16">
              {chat.attachments?.media.map((url, index) => (
                <img key={index} className="h-80 rounded object-cover" src={url} alt="" />
              ))}
            </div>
          </>
        )}

        {chat.type === 1 && (
          <>
            <Typography
              className="font-medium text-20 px-32 py-24 flex items-center justify-between"
              color="secondary.main"
            >
              <span className="flex">
                <FuseSvgIcon className="mr-14">heroicons-outline:user-group</FuseSvgIcon>{' '}
                {t('Members')}: {members.length}
              </span>
              {user.fio && (
                <IconButton color="secondary" onClick={() => setOpen(true)}>
                  <FuseSvgIcon>heroicons-outline:user-add</FuseSvgIcon>
                </IconButton>
              )}
            </Typography>

            {members.map((member, index) => (
              <div key={member.id}>
                <div
                  className={clsx(
                    members.length !== index + 1 ? 'border-b-1 list-none' : 'list-none'
                  )}
                >
                  <ContactListItem contact={member} group={group} />
                </div>
              </div>
            ))}

            <SimpleDialog
              open={open}
              onClose={() => {
                setOpen(false);
                reset({ userId: [] });
              }}
              title={t('AddUsersToGroup')}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <MyAutocomplete
                  multiple
                  disableCloseOnSelect
                  defaultValue={[]}
                  className="mt-6"
                  rules={{ required: true }}
                  control={control}
                  name="userId"
                  label={t('Users')}
                  error={!!errors.userId}
                  options={contacts
                    .filter((_user) => !members.find((mb) => mb.id === _user.id))
                    .map((el) => ({ ...el, title: el.fullName }))}
                />

                <LoadingButton
                  loading={loading}
                  variant="contained"
                  color="secondary"
                  className=" w-full mt-28"
                  type="submit"
                  size="large"
                >
                  {t('Add')}
                </LoadingButton>
              </form>
            </SimpleDialog>
          </>
        )}
      </div>
    </div>
  );
}

export default ContactSidebar;
