import SimpleDialog from '@fuse/core/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import PaymentForm from '../payment/PaymentForm';
import Tooltip from '@mui/material/Tooltip';
import useMessage from 'src/app/hooks/useMessage';
import { DeleteOutline } from '@mui/icons-material';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDeleteContractMutation } from 'src/app/services/contractsApi';
import { useGetAllProjectListQuery } from 'src/app/services/ticket/projectsApi';
import { Popconfirm } from 'antd';

const ContractRow = ({ data: contract }) => {
  const { t } = useTranslation('translations');
  const { projectId } = useParams();
  const { showMessage } = useMessage();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [deleteContract] = useDeleteContractMutation();
  const { data: projectList } = useGetAllProjectListQuery();
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  const handleDeleteContract = () => {
    deleteContract({ id: contract.id })
      .unwrap()
      .then(() => {
        showMessage({ message: t('ContractSuccessfullyDeleted'), autoHideDuration: 3000 });
      })
      .catch(handleError);
  };

  const handleOpenContract = () => navigate(`/apps/projects/${projectId}/contracts/${contract.id}`);

  return [
    <TableRow
      hover
      sx={{
        '&:nth-of-type(odd)': {
          backgroundColor: 'grey.50',
        },
      }}
    >
      <TableCell className="dark:bg-dark">
        <span
          className="cursor-pointer dark:text-white underline text-blue-700"
          onClick={handleOpenContract}
        >
          {contract.contractNumber}
        </span>
      </TableCell>
      <TableCell className="dark:bg-dark">
        {contract.projectIds.map((project, index) => (
          <Link
            key={project}
            to={`/apps/projects/${project}/passport`}
            onClick={(e) => e.stopPropagation()}
            style={{ textDecoration: 'none', color: isDarkMode ? '#fff' : '#000' }}
            className=" cursor-text"
          >
            {projectList?.find((p) => p.key === project).value}
          </Link>
        ))}
      </TableCell>
      <TableCell
        className="dark:bg-dark dark:text-white  cursor-text"
        onClick={(e) => e.stopPropagation()}
      >
        {format(new Date(contract.contractDate), 'dd.MM.yyyy')}
      </TableCell>
      <TableCell align="right" className="dark:bg-dark">
        <Popconfirm
          className="mr-10"
          title={t('AreYouSureToDeleteContract')}
          onConfirm={handleDeleteContract}
          okText={t('Yes')}
          cancelText={t('No')}
        >
          <Tooltip title={t('DeleteContract')} placement="left" arrow>
            <IconButton size="small" color="error">
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        </Popconfirm>
      </TableCell>
    </TableRow>,
    <SimpleDialog open={open} onClose={() => setOpen(false)} title={t('Payment')}>
      <PaymentForm contract={contract} onClose={() => setOpen(false)} />
    </SimpleDialog>,
  ];
};

export default ContractRow;
