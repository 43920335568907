import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const floorApi = createApi({
  reducerPath: 'floorsApi',
  baseQuery: rtkBaseQuery('/staff/Floor'),
  tagTypes: ['Floors'],
  endpoints: (builder) => ({
    createFloor: builder.mutation({
      query: (body) => ({
        url: '/Create',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Floors'],
    }),
    editFloor: builder.mutation({
      query: (body) => ({
        url: `/Update`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Floors'],
    }),
    addImageFloor: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("formFile", file);
        return {
          url: '/AddImage',
          method: 'POST',
          body: formData,
          formData: true,
          responseHandler: (response) => response.text()
        }
      }
    }),
    getFloorsList: builder.query({
      query: () => '/GetAll',
      providesTags: ['Floors']
    }),
    getFloorsByBuildId: builder.query({
      query: ({id}) => ({
        url: `GetFloorByBuildId?id=${id}`
      })
    }),
    getFloorById: builder.query({
      query: ({id}) => ({
        url: `/GetById?id=${id}`,
      })
    }),
    deleteFloorById: builder.mutation({
      query: (id) => ({
        url: `/Delete?id=${id}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Floors'],
    }),
  })
});

export const {
  useCreateFloorMutation,
  useAddImageFloorMutation,
  useGetFloorsListQuery,
  useGetFloorsByBuildIdQuery,
  useDeleteFloorByIdMutation,
  useGetFloorByIdQuery,
  useEditFloorMutation
} = floorApi;