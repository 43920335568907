import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PositionCategories } from 'src/app/constants/enums';

export const StaffPositionRow = ({ position, index }) => {
  const { t } = useTranslation('translations');
  const { i18n } = useTranslation();
  const lang = i18n.language === 'ru' ? 'ru' : 'uzb_lat';

  let positionStaffList = position.staffList || [];

  if (!positionStaffList.length) {
    positionStaffList = [
      {
        earlyLeaving: null,
        fio: '',
        staffId: 0,
        stavka: 1,
      },
    ];
  }

  return positionStaffList.map((staff, staffIndex) => {
    const assigned = staff.id || staff.fio;
    return (
      <TableRow
        sx={{ bgcolor: 'grey' }}
        key={staff.staffId}
        className={staff.supervisor ? 'bg-green-100 dark:bg-green-500' : ''}
      >
        {staffIndex === 0 && (
          <>
            <TableCell
              align="center"
              rowSpan={positionStaffList.length > 0 ? positionStaffList.length : 1}
            >
              {index}
            </TableCell>
            <TableCell rowSpan={positionStaffList.length > 0 ? positionStaffList.length : 1}>
              <p>{position?.lang?.[lang]}</p>
              <p>{staff.supervisor && <span>({t('SubdivisionManager')})</span>}</p>
            </TableCell>
            <TableCell rowSpan={positionStaffList.length > 0 ? positionStaffList.length : 1}>
              {t(PositionCategories[position.positionCategory].label)}
            </TableCell>
          </>
        )}
        <TableCell align="center">{staff.stavka || 1}</TableCell>
        <TableCell> {assigned ? staff.fio : '-'}</TableCell>
      </TableRow>
    );
  });
};
