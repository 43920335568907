import { authRoles } from 'src/app/auth';
import StaffTable from './StaffTable';

const StaffTableConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.hr,
  routes: [
    {
      path: 'hr/staff-table',
      element: <StaffTable />,
    },
  ],
};

export default StaffTableConfig;
