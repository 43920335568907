import React from 'react';
import { motion } from 'framer-motion';
import { useGetTicketCountByStaffIdQuery } from 'src/app/services/ticketsApi';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import './Profile.css';

const ProfileWorkLoad = () => {
  const { staffId } = useSelector(selectUser);
  const { t } = useTranslation('translations');
  const { data: myTickets } = useGetTicketCountByStaffIdQuery({ id: staffId }, { skip: !staffId });
  const [profileData] = useOutletContext();
  const { projects } = profileData;

  const workLoadCard = [
    {
      id: 1,
      title: 'Closed',
      text: 'YouExecutedTasks',
      icon: 'assets/icons/profile/arrowClockWise.svg',
      amount: myTickets && myTickets.taskCountClosed,
      bgColor: '#3068E1',
      bgColorImage: 'rgba(255, 255, 255, 0.20)',
      classSign: 'closed-card',
      classFirstSign: 'closed-card--first',
      classSecondSign: 'closed-card--second',
    },
    {
      id: 2,
      title: 'InWork',
      text: 'TasksYouAreCurrentlyDoing',
      icon: 'assets/icons/profile/hourGlass.svg',
      amount: myTickets && myTickets.taskCountInProgress,
      bgColor: '#EA9414',
      bgColorImage: 'rgba(255, 255, 255, 0.20)',
      classSign: 'developing-card',
      classFirstSign: 'developing-card--first',
      classSecondSign: 'developing-card--second',
    },
    {
      id: 3,
      title: 'New',
      text: 'YouNeedToExecuteTheTask',
      icon: 'assets/icons/profile/arrowClockWise.svg',
      amount: myTickets && myTickets.taskCountNew,
      bgColor: '#2EAE77',
      bgColorImage: 'rgba(255, 255, 255, 0.20)',
      classSign: 'new-card',
      classFirstSign: 'new-card--first',
      classSecondSign: 'new-card--second',
      signBackGround: 'assets/images/apps/profile/union.svg',
    },
  ];

  return (
    <div className="p-18">
      <ul className="flex items-center justify-center flex-wrap md:flex-nowrap gap-12 p-12">
        {workLoadCard.map((val) => (
          <li
            key={val.id}
            style={{ backgroundColor: val.bgColor }}
            className="relative basis-3/4 lg:basis-1/3 min-h-[207px] xl:min-h-auto rounded-12 p-12 flex flex-col items-start overflow-hidden"
          >
            {val.signBackGround ? (
              <div className="absolute right-0 top-6 flex items-center">
                <img src={val.signBackGround} className="translate-x-[90%] -translate-y-30" />
                <img src={val.signBackGround} className="translate-x-28 -translate-y-36" />
              </div>
            ) : (
              <div className="absolute right-0 top-6 flex items-center">
                <span className={`${val.classSign} ${val.classFirstSign}`}></span>
                <span className={`${val.classSign} ${val.classSecondSign}`}></span>
              </div>
            )}
            <div
              className="py-5 px-6 rounded-full hover:cursor-pointer min-h-32 min-w-32"
              style={{ backgroundColor: val.bgColorImage }}
            >
            </div>
            <div className="mt-[37px] flex flex-col items-start gap-4">
              <h4 className="text-2xl font-700 text-inter text-white ">{t(val.title)}</h4>
              <p className="text-sm font-400 text-white">{t(val.text)}</p>
            </div>
            <div className="w-full h-1 bg-white opacity-30 rounded-16 mt-16"></div>
            <p className="text-base text-inter text-white font-400 mt-12">
              {t('Quantity')}: {val.amount}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProfileWorkLoad;
