import handleError from '@fuse/core/errorHandler';
import SimpleDialog from '@fuse/core/SimpleDialog/SimpleDialog';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import OrganizationsForm from './OrganizationsForm';
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useGetOrganizationsMutation } from 'src/app/services/ticketsApi';
import { useTranslation } from 'react-i18next';
import { Delete, Edit } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';

const initialParams = {
  filters: '{}',
  first: 0,
  rows: 10,
  sortField: 'id',
  sortOrder: 0,
};

const Organizations = () => {
  const { t } = useTranslation('translations');
  const [params, setParams] = useState(initialParams);
  const [currentOrganization, setCurrentOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [getSpOrganizations, { isLoading }] = useGetOrganizationsMutation(params);

  const handlePageChange = (event, value) => {
    setPage(value);
    setParams((prev) => ({ ...prev, first: (value - 1) * prev.rows }));
  };

  const getOrganizations = () => {
    getSpOrganizations(params)
      .unwrap()
      .then((res) => {
        setOrganizations(res.items);
        setTotalPages(Math.ceil(res.totalItems / params.rows));
      })
      .catch(handleError);
  };

  const showEditDialog = (organization) => {
    setCurrentOrganization(organization);
    setOpen(true);
  };

  const showNewItemDialog = () => {
    setCurrentOrganization(null);
    setOpen(true);
  };

  useEffect(getOrganizations, [params]);

  return (
    <div style={{ display: 'grid' }}>
      <TableContainer>
        <div className="h-[40px] flex flex-row justify-between items-center dark:bg-dark">
          <h2 className="ml-5"> {t('Organizations')}</h2>
          <Button
            className="mx-8"
            size="small"
            variant="outlined"
            color="secondary"
            onClick={showNewItemDialog}
          >
            <FuseSvgIcon size={20}>heroicons-outline:plus</FuseSvgIcon>
            <span className="mx-8">{t('Add')}</span>
          </Button>
        </div>
        <Table style={{ overflow: 'auto' }}>
          <TableHead>
            <TableRow className="dark:bg-dark">
              <TableCell>{t('Name')}</TableCell>
              <TableCell>{t('ShortName')}</TableCell>
              <TableCell>{t('WorkAddress')}</TableCell>
              <TableCell colSpan={2}>{t('WorkPhone')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="h-[1000px] overflow-y-auto">
            {organizations?.map((item) => (
              <TableRow key={item.id} className="bg-white dark:bg-dark hover:bg-grey-200">
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.shortName}</TableCell>
                <TableCell>{item.workAddress}</TableCell>
                <TableCell>{item.workPhone}</TableCell>
                <TableCell>
                  <div className="flex">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      className="mr-5"
                      color="info"
                      onClick={() => showEditDialog(item)}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          className="flex justify-center items-center dark:bg-dark"
          count={totalPages}
          page={page}
          onChange={handlePageChange}
        />
        <SimpleDialog
          title={currentOrganization ? t('EditOrganization') : t('AddOrganization')}
          open={open}
          onClose={() => setOpen(false)}
        >
          <OrganizationsForm
            position={currentOrganization}
            refetchList={getOrganizations}
            onClose={() => setOpen(false)}
            isEdit={currentOrganization ? true : false}
          />
        </SimpleDialog>
      </TableContainer>
    </div>
  );
};

export default Organizations;
