import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import ProfileStuff from './tabs/ProfileTabs/ProfileStuff';
import Attendance from './tabs/ProfileTabs/Attendance';
import ProfileProjects from './tabs/ProfileTabs/ProfileProjects';
import ProfileSkills from './tabs/ProfileTabs/ProfileSkills';
import ProfileWorkLoad from './tabs/ProfileTabs/ProfileWorkLoad';
import { Navigate } from 'react-router-dom';
import ProfileClaims from './tabs/ProfileTabs/ProfileClaims';

const ProfileApp = lazy(() => import('./ProfileApp'));

const profileAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/profile',
      element: <ProfileApp />,
      children: [
        {
          path: '',
          element: <Navigate to={'/apps/profile/profile-detail'} />,
        },
        {
          path: 'profile-detail',
          element: <ProfileStuff />,
        },
        {
          path: 'attendance',
          element: <Attendance />,
        },
        {
          path: 'projects',
          element: <ProfileProjects />,
        },
        {
          path: 'skills',
          element: <ProfileSkills />,
        },
        {
          path: 'workload',
          element: <ProfileWorkLoad />,
        },
        {
          path: 'claims',
          element: <ProfileClaims />,
        },
      ],
    },
  ],
};

export default profileAppConfig;
