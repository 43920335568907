import FuseLoading from '@fuse/core/FuseLoading';
import FuseUtils from '@fuse/utils';
import settingsConfig from 'app/configs/settingsConfig';
import { Navigate } from 'react-router-dom';
import Error404Page from '../main/404/Error404Page';
import adminConfig from '../main/admin/adminConfig';
import appsConfigs from '../main/apps/appsConfigs';
import hrConfigs from '../main/hr/hrConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import HomeApp from '../main/apps/home/HomeApp';
import GreetingsConfig from '../main/greetings/GreetingsConfig';

const routeConfigs = [
  ...appsConfigs,
  ...hrConfigs,
  ...adminConfig,
  SignOutConfig,
  SignInConfig,
  GreetingsConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <HomeApp />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;
