import handleError from '@fuse/core/errorHandler';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'app/configs/api';
import axios from 'axios';

const initialState = {
  newAlarmsCount:0,
  alarms: null
};

export const getAlarmsList = createAsyncThunk('alarms/getAlarms', async (body) => {
  try {
    const response = await axios.post(API.getAlarmsList, body);
    return response.data;
  } catch (e) {
    handleError(e);
    return [];
  }
});

export const getNewAlarmsCount = createAsyncThunk('alarms/newAlarmsCount', async () => {
  try{
    const response = await axios.get(API.getNewAlarmsCount);
    return response.data;
  } catch (e) {
    handleError(e);
    return 0;
  }
})

export const setAlarmAsRead = createAsyncThunk('alarms/setAlarmAsRead', async (params) => {
  try{
    const response = await axios.post(API.updateAlarmStatus+`?alarmId=${params.id}`,);
    if (response.data) {
      return params.id;
    } else {
      return null;
    }
  } catch (e) {
    handleError(e);
    return null;
  }
})

const alarmsSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    increaseAlarmsCount: (state) => {
      state.newAlarmsCount +=1;
    },
    decreaseAlarmsCount: (state) => {
      if (state.newAlarmsCount>0){
        state.newAlarmsCount-=1;
      } else {
        state.newAlarmsCount = 0;
      }
    }
  },
  extraReducers: {
    [getAlarmsList.fulfilled]: (state, action) => {
      state.alarms=action.payload;
      state.loading = false;
    },
    [getAlarmsList.pending]: (state, action) => {
      state.loading = true;
    },
    [getAlarmsList.rejected]: (state, action) => {
      state.loading = false;
      state.alarms=null;
    },
    [getNewAlarmsCount.fulfilled]: (state, action) => {
      state.newAlarmsCount = action.payload;
    },
    [setAlarmAsRead.fulfilled]: (state, action) => {
      if (action.payload) {
        const index = state.alarms?.items.findIndex(alarm=>alarm.id===action.payload);
        if (index>-1){
          state.alarms.items[index].alarmStatus = true;
        }
      } 
    },
  },
});

export const { increaseAlarmsCount, decreaseAlarmsCount } = alarmsSlice.actions;

export const selectNewAlarmsCount = ({alarms}) => alarms.newAlarmsCount;

export const selectAlarms = ({alarms}) => alarms.alarms;

export default alarmsSlice.reducer;
