import { Tour } from "antd"; 
import { useTranslation } from "react-i18next";

const ContractTourHelp = ({onOpen, onClose, ref1}) => {
  const {t} = useTranslation('translations');
  const steps = [
    {
      title: t('AddContract'),
      description: t('PressButtonToAddContract'),
      target: () => ref1.current
    }
  ];

  return <Tour 
    steps={steps}
    open={onOpen}
    onClose={onClose}
  />
}

export default ContractTourHelp;