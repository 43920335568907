import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import FuseLoading from '@fuse/core/FuseLoading';
import SimpleDialog from '@fuse/core/SimpleDialog/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import UserInfoModal from '@fuse/core/UserInfoModal/UserInfoModal';
import UsersTableHead from './UsersTableHead';
import AddUserStatusForm from './AddUserStatusForm';
import Image from '@fuse/core/Image/Image';
import axios from 'axios';
import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { API } from 'app/configs/api';
import { Button, Pagination } from '@mui/material';
import { useEffect, useState } from 'react';
import LinkHRMForm from './components/LinkHRMForm';

const initialState = {
  filters: '{}',
  first: 0,
  rows: 10,
  sortOrder: 1,
  sortField: 'id',
  globalFilter: null,
};

export const userStatusState = [
  {
    id: 0,
    statusName: 'None',
    title: 'Нет статуса',
  },
  {
    id: 1,
    statusName: 'Top',
    title: 'Топ',
  },
  {
    id: 2,
    statusName: 'Volonter',
    title: 'Волонтер',
  },
  {
    id: 3,
    statusName: 'Stajer',
    title: 'Стажер',
  },
  {
    id: 4,
    statusName: 'Probation',
    title: 'Испытательный срок',
  },
];

function UsersTable({ getAllUsers }) {
  const { t } = useTranslation('translations');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ items: [], totalItems: 0 });
  const [searchUser, setSearchUser] = useState('');
  const [order, setOrder] = useState({
    direction: 'asc',
    id: null,
  });

  const [pagination, setPagination] = useState(initialState);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [staffList, setStaffList] = useState([]);

  const getUsers = async (param) => {
    setLoading(true);
    try {
      const response = await axios.post(API.users, param);
      setData(response?.data);
      setTotalPages(Math.ceil(response?.data.totalItems / pagination.rows));
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const getUsersChanged = async (param) => {
    try {
      const response = await axios.post(API.users, param);
      getAllUsers(response?.data.items);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsers({
      ...pagination,
      filters: searchUser ? JSON.stringify({ fullName: { value: searchUser } }) : '{}',
    });
  }, [pagination]);

  useEffect(() => {
    getUsersChanged({
      ...initialState,
      rows: 1000,
    });
  }, []);

  const handleSearchUser = (e) => {
    setSearchUser(e.target.value);

    getUsers({
      ...pagination,
      first: 0,
      filters: JSON.stringify({ fullName: { value: e.target.value } }),
    });
  };

  useEffect(() => {
    axios
      .post(API.staffList, { ...initialState, rows: 1000 })
      .then((res) => setStaffList(res.data.items.map((el) => ({ ...el, title: el.fullName.fio }))))
      .catch(handleError);
  }, []);

  const handlePageChange = (event, value) => {
    setPage(value);
    setPagination((prev) => ({ ...prev, first: (value - 1) * prev.rows }));
  };

  const [open, setOpen] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ roles: [] });
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [staffId, setStaffId] = useState(null);
  const [resizeViewport, setReziseViewport] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setReziseViewport(window.innerWidth);
    });
  }, [resizeViewport]);

  const handleClickSettings = (user) => () => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClickStatus = (user) => () => {
    setSelectedUser(user);
    setOpenStatusModal(true);
  };

  const handleClose = async () => {
    await getUsers({
      ...pagination,
      first: searchUser ? 0 : pagination.first,
      filters: searchUser ? JSON.stringify({ fullName: { value: searchUser } }) : '{}',
    });
    setOpen(false);
  };

  return (
    <div className="w-full flex flex-col">
      <SimpleDialog open={open} onClose={() => setOpen(false)} title={selectedUser.fullName}>
        <LinkHRMForm selectedUser={selectedUser} onCloseAndRefresh={handleClose} />
      </SimpleDialog>
      <SimpleDialog
        open={openStatusModal}
        onClose={() => setOpenStatusModal(false)}
        title={t('SelectUserStatus')}
      >
        <AddUserStatusForm
          selectedUser={selectedUser}
          onClose={() => setOpenStatusModal(false)}
          userStatusState={userStatusState}
        />
      </SimpleDialog>
      {!!staffId && (
        <SimpleDialog
          open={openInfoModal}
          onClose={() => setOpenInfoModal(false)}
          title={`#${staffId}`}
        >
          <UserInfoModal staffId={staffId} />
        </SimpleDialog>
      )}
      <div>
        {loading && (
          <div className="flex items-center justify-center md:justify-start h-full">
            <FuseLoading />
          </div>
        )}
        {data?.items?.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.1 } }}
            style={{ width: '100%' }}
            className="flex flex-1 items-center justify-center h-full p-10"
          >
            <Typography color="text.secondary" variant="h5">
              {t('ThereAreNoUsers')}
            </Typography>
          </motion.div>
        )}
        <Table
          stickyheaderaria-labelledby="tableTitle"
          className={`${resizeViewport < 1575 ? 'flex flex-col items-center' : ''}`}
        >
          <UsersTableHead
            order={order}
            rowCount={data.length}
            handleSearchUser={handleSearchUser}
            searchUser={searchUser}
          />
          {
            <TableBody>
              {_.orderBy(
                data.items,
                [
                  (o) => {
                    switch (order.id) {
                      case 'categories': {
                        return o.categories[0];
                      }
                      default: {
                        return o[order.id];
                      }
                    }
                  },
                ],
                [order.direction]
              )?.map((n) => {
                return (
                  <TableRow
                    className="border-b-2 py-8 border-gray-700 bg-white dark:bg-dark md:border-none md:table-row flex flex-col items-center"
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={n.id}
                  >
                    <TableCell className="p-4 md:p-16" component="th" scope="row">
                      <div className="flex items-center">
                        <Image
                          className="w-40 h-40"
                          name={n.fullName}
                          id={n.staffImageFileId}
                          variant="avatar"
                        />
                        <Typography className="ml-8">{n.fullName}</Typography>
                      </div>
                    </TableCell>

                    <TableCell className="p-4 md:p-16 text-center" component="th" scope="row">
                      {n.staffId ? staffList?.find((staff) => staff.id === n.staffId)?.title : '-'}
                    </TableCell>
                    <TableCell
                      className="p-4 md:p-16 flex justify-start items-start"
                      component="th"
                      scope="row"
                      align="left"
                    >
                      {!n.staffId && (
                        <Button variant="outlined" color="info" onClick={handleClickSettings(n)}>
                          {t('ConnectWithHRM')}
                        </Button>
                      )}
                      <Button
                        sx={{ ml: 2 }}
                        variant="outlined"
                        color="success"
                        onClick={handleClickStatus(n)}
                      >
                        {t('AddStatus')}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          }
        </Table>
      </div>
      <div className="flex justify-center items-center">
        <Pagination
          className="mb-8 mx-auto"
          count={totalPages}
          page={page}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
}

export default UsersTable;
