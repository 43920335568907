import handleError from '@fuse/core/errorHandler';
import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAddUpdateMutation } from 'src/app/services/spApi';

const defaultValues = {
    active: true,
    belongCivilService: null,
    capacity: 0,
    categoryTypeId: null,
    code: "",
    comment: "",
    createDateStr: null,
    createdBy: null,
    createdByStr: null,
    createdDate: null,
    dateTimeField: null,
    districtId: null,
    groupId: null,
    id: 0,
    lastModifiedBy: null,
    lastModifiedByStr: null,
    lastModifiedDate: null,
    lastModifiedDateStr: null,
    nameCyr: "",
    nameKar: "",
    nameRu: "",
    nameUz: "",
    regionId: null,
    spRootCode: ""
};

const ReferenceItemForm = ({ position, onClose, refetchList, tableName }) => {
  const { t } = useTranslation('translations');
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: position ?? defaultValues,
  });

  const [save, { isLoading }] = useAddUpdateMutation();

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const onSubmit = async (values) => {
    save({tableName, ...values})
      .unwrap()
      .then(() => {
        refetchList();
        handleClose();
      })
      .catch(handleError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-10">
      <div className="flex flex-col gap-16 max-w-[1000px] mb-10">
      <Controller
        name="nameRu"
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          return <TextField className='w-full md:w-sm' {...field} fullWidth label={t('Title')+' (рус.)'} error={!!errors.nameRu} variant="outlined" required />;
        }}
      />
      <Controller
        name="nameUz"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <TextField className='w-full md:w-sm' {...field} fullWidth label={t('Title')+' (лат.)'} error={!!errors.nameUz} variant="outlined" required />
        )}
      />
      </div>
      <Button disabled={isLoading || !isValid} type="submit" className="w-full" variant="contained" color="secondary">
        <span className="mx-8">{t('Save')}</span>
      </Button>
    </form>
  );
};

export default ReferenceItemForm;
