import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import './GreetingsPage.css';

const GreetingsPage = () => {
  const { t } = useTranslation('translations');

  useEffect(() => {
    localStorage.setItem('isFirstTime', false);
  }, []);
  return (
    <div
      className="flex flex-col  justify-center items-center h-full p-24"
      style={{
        backgroundImage: 'url(assets/images/greetings/greetings.jpg)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="text-center bg-white rounded-16  max-w-[550px] p-40">
        <div className="text-center">
          <h1 className="text-24 font-bold greetings__title mb-10">{t('Greeting')}!</h1>
          <hr className="greetings__sharp-line"></hr>
        </div>
        <p className="my-20 text-black">
          {t('GladToSeeYouInOurTeam')}. {t('YourEnergyAndTalentWillBecomeContribution')}.{' '}
          {t('ForwardToNewAchievements')}!
        </p>
        <a className="greetings__button" href="/">
          {t('Start_verb')}
        </a>
      </div>
    </div>
  );
};

export default GreetingsPage;
