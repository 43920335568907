import handleError from '@fuse/core/errorHandler';
import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useUpdateSpOrganizationMutation,
  useCreateSpOrganizationMutation,
} from 'src/app/services/ticketsApi';

const defaultValues = {
  email: '',
  guid: '',
  headOrgId: null,
  id: 0,
  okpo: '',
  shortName: '',
  title: '',
  webPage: '',
  workAddress: '',
  workCity: 'Ташкент',
  workCountry: 'Узбекистан',
  workFax: '',
  workPhone: '',
  workState: 'Узбекистан',
};

const OrganizationsForm = ({ position, isEdit, onClose, refetchList }) => {
  const { t } = useTranslation('translations');
  const [save, { isLoading }] = useUpdateSpOrganizationMutation();
  const [create] = useCreateSpOrganizationMutation();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: position ?? defaultValues,
  });

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const handleCreate = async (values) => {
    create(values)
      .unwrap()
      .then(() => {
        refetchList();
        handleClose();
      })
      .catch(handleError);
  };

  const handleEdit = async (values) => {
    save(values)
      .unwrap()
      .then(() => {
        refetchList();
        handleClose();
      })
      .catch(handleError);
  };

  const onSubmit = (values) => {
    if (isEdit) {
      handleEdit(values);
    } else {
      handleCreate(values);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-10 max-w-5xl">
      <div className="flex flex-row justify-between  gap-16 mb-5">
        <div className=" flex flex-col gap-16">
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  fullWidth
                  label={t('Title')}
                  error={!!errors.title}
                  variant="outlined"
                  required
                />
              );
            }}
          />
          <Controller
            name="shortName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('ShortTitle')}
                error={!!errors.shortName}
                variant="outlined"
                required
              />
            )}
          />
          <Controller
            name="workAddress"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('WorkAddress')}
                error={!!errors.workAddress}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="workCountry"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('WorkCountry')}
                error={!!errors.workPhone}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="workCity"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('WorkCity')}
                error={!!errors.workPhone}
                variant="outlined"
              />
            )}
          />
        </div>
        <div className="flex flex-col gap-16">
          <Controller
            name="webPage"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <TextField
                  {...field}
                  fullWidth
                  label={t('WebPage')}
                  error={!!errors.title}
                  variant="outlined"
                />
              );
            }}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('Email')}
                error={!!errors.shortName}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="workPhone"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('Phone')}
                error={!!errors.workPhone}
                variant="outlined"
              />
            )}
          />
          <Controller
            name="workFax"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t('Fax')}
                error={!!errors.workAddress}
                variant="outlined"
              />
            )}
          />
        </div>
      </div>
      <Button
        disabled={isLoading || !isValid}
        type="submit"
        className="w-full"
        variant="contained"
        color="secondary"
      >
        <span className="mx-8">{t('Save')}</span>
      </Button>
    </form>
  );
};

export default OrganizationsForm;
