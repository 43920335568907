import { CloudUpload, InsertDriveFile } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Avatar,
  FormLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller } from 'react-hook-form';
// import { handleError } from 'app/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@mui/styles';
import { API } from 'app/configs/api';
import handleError from '../errorHandler';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#eee',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#333',
    padding: '10px',
  },
  icon: {
    color: '#888888',
    fontSize: '42px',
  },
}));

const FileUpload = ({
  multiple = false,
  label,
  imagePreview = false,
  type,
  folder,
  parentId,
  fileTitle,
  onSuccess,
  list = true,
  acceptedFileTypes,
  ...props
}) => {
  const styles = useStyles();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('translations');

  const onDrop = (onChange, value) => async (acceptedFiles, rejectedFiles) => {
    if (multiple && acceptedFiles.length + value?.length > 10) return setError('maxNumberOverflow');
    if (rejectedFiles.length > 0) return setError('notAccepted');
    setLoading(true);

    let prevState = value;
    const promises = acceptedFiles.map(async (file) => {
      try {
        const form = new FormData();
        form.append('file', file);
        form.append('fileType', type);

        if (parentId) {
          form.append('parentId', parentId);
        }

        if (fileTitle) {
          form.append('fileTitle', fileTitle);
        }
        const { data } = await axios.post(folder ? API.contractFileUpload : API.uploadFile, form);

        if (multiple) {
          // setFiles((prevState) => {
          //   const isDublicate = prevState.find((f) => f.id === data[0]);
          //   const newState = isDublicate ? prevState : [{ name: file.name, id: data[0] }, ...prevState];
          //   onChange(newState);
          //   return newState;
          // });

          const isDublicate = prevState?.find((f) => f.id === data[0]);

          if (imagePreview) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (e) => {
              const newState = isDublicate
                ? prevState
                : [{ name: file.name, id: data[0], src: reader.result }, ...prevState];
              onChange(newState);
              prevState = newState;
            };
          } else {
            const newState = isDublicate
              ? prevState
              : [{ name: file.name, id: data[0] }, ...prevState];
            onChange(newState);
            prevState = newState;
          }
        } else {
          const newState = { name: file.name, id: data[0] };

          if (imagePreview) {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = (e) => {
              newState.src = reader.result;
              onChange(newState);
            };
          } else {
            onChange(newState);
          }
        }

        if (onSuccess) {
          onSuccess();
        }
        setError(undefined);
      } catch (e) {
        handleError(e);
      }
    });

    await Promise.all(promises);
    return setLoading(false);
  };

  return (
    <Controller
      {...props}
      defaultValue={multiple ? [] : null}
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <FormLabel>{label}</FormLabel>
          <Dropzone onDrop={onDrop(onChange, value)} multiple={multiple} accept={acceptedFileTypes}>
            {({ getRootProps, getInputProps }) => (
              <Paper variant="outlined" className={styles.root} {...getRootProps()}>
                <CloudUpload className={styles.icon} />
                <input {...getInputProps()} onBlur={onBlur} />
                <p>{t('DragHereOrPressToChoose')}</p>
              </Paper>
            )}
          </Dropzone>
          {list && (
            <List>
              {multiple
                ? (value || []).map((file, index) =>
                    imagePreview ? (
                      <div key={file.id} className="photo-box">
                        <img className="photo" src={file.src} alt="preview" />
                      </div>
                    ) : (
                      <ListItem
                        key={index}
                        secondaryAction={<CheckCircleIcon color="success" sx={{ mt: 0.5 }} />}
                      >
                        <ListItemIcon>
                          <InsertDriveFile style={{ color: '#2b2892' }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={file.name}
                          // secondary={
                          //   <Typography sx={{ cursor: 'pointer', color: 'red' }} onClick={removeUploadedFile(file, onChange)}>
                          //     Ўчириш
                          //   </Typography>
                          // }
                          sx={{ overflowX: 'hidden', mr: 1.5 }}
                        />
                      </ListItem>
                    )
                  )
                : value &&
                  (imagePreview ? (
                    <div className="flex justify-center flex-wrap">
                      {imagePreview === 'avatar' ? (
                        <Avatar
                          src={value.src}
                          alt="Profile Photo Preview"
                          className="w-200 h-200 "
                        />
                      ) : (
                        <img src={value.src} alt="preview" className="w-screen" />
                      )}
                    </div>
                  ) : (
                    <ListItem
                      secondaryAction={<CheckCircleIcon color="success" sx={{ mt: 0.5 }} />}
                    >
                      <ListItemIcon>
                        <InsertDriveFile style={{ color: '#2b2892' }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={value.name}
                        // secondary={
                        //   <Typography sx={{ cursor: 'pointer', color: 'red' }} onClick={removeUploadedFile(file, onChange)}>
                        //     Ўчириш
                        //   </Typography>
                        // }
                        sx={{ overflowX: 'hidden', mr: 1.5 }}
                      />
                    </ListItem>
                  ))}
            </List>
          )}
          {error && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {error}
            </Alert>
          )}
          {loading && (
            <>
              <LinearProgress sx={{ my: 2 }} />
              <LinearProgress color="secondary" sx={{ mb: 2 }} />
            </>
          )}
        </>
      )}
    />
  );
};

export default FileUpload;
