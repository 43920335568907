import SimpleDialog from '@fuse/core/SimpleDialog/SimpleDialog';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Pagination,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllTranslationsQuery } from 'src/app/services/spApi';
import { Close, Edit } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import TranslationsForm from './TranslationsForm';

const rows = 10;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Translations = () => {
  const { t } = useTranslation('translations');
  const [column, setColumn] = useState('Code');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [currentTranslation, setCurrentTranslation] = useState(null);
  const [open, setOpen] = useState(false);

  const {
    data: translations,
    isLoading,
    refetch: getTranslationsList,
  } = useGetAllTranslationsQuery(
    { page, rows, column, name },
    { skip: name.length > 1 && name.length < 3 }
  );

  const totalPages = translations ? Math.ceil(translations.totalItems / rows) : 0;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleEdit = (position) => {
    setCurrentTranslation(position);
    setOpen(true);
  };

  const showNewTranslationDialog = () => {
    setCurrentTranslation(null);
    setOpen(true);
  };

  return (
    <TableContainer component={Paper}>
      <div className="flex flex-col md:flex-row gap-10 justify-between items-center mt-10 flex-wrap">
        <h2 className="ml-5"> {t('Translations')}</h2>
        <div className="flex gap-10 items-center">
          <div className="flex items-center">{t('Filter')}:</div>
          <div>
            <FormControl size="small">
              <InputLabel id="statuses-checkbox-label">{t('Column')}</InputLabel>
              <Select
                value={column}
                onChange={(e) => setColumn(e.target.value)}
                input={<OutlinedInput label={t('Column')} />}
                MenuProps={MenuProps}
              >
                <MenuItem value={'Code'}>Code</MenuItem>
                <MenuItem value={'NameRu'}>NameRu</MenuItem>
                <MenuItem value={'NameUz'}>NameUz</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div>
            <TextField
              size="small"
              label={t('Search')}
              value={name}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {name.length > 0 && (
                      <Close className="text-14 cursor-pointer" onClick={() => setName('')} />
                    )}
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
        </div>
        <Button
          className="mx-10"
          size="small"
          variant="outlined"
          color="secondary"
          onClick={showNewTranslationDialog}
        >
          <FuseSvgIcon size={20}>heroicons-outline:plus</FuseSvgIcon>
          <span className="mx-8">{t('Add')}</span>
        </Button>
      </div>
      {isLoading && <FuseLoading />}
      {!isLoading && (
        <Table className="w-full">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>{t('Name')} (рус)</TableCell>
              <TableCell>{t('Name')} (latin)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {translations?.items?.map((item) => (
              <TableRow key={item.id} className="bg-white dark:bg-dark hover:bg-grey-200">
                <TableCell>{item.code}</TableCell>
                <TableCell>{item.nameRu}</TableCell>
                <TableCell>{item.nameUz}</TableCell>
                <TableCell>
                  <div className="flex">
                    <IconButton
                      edge="end"
                      aria-label="edit"
                      className="mr-5"
                      color="info"
                      onClick={() => handleEdit(item)}
                    >
                      <Edit />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Pagination
        className="flex justify-center items-center"
        count={totalPages}
        page={page}
        onChange={handlePageChange}
      />
      <SimpleDialog
        title={currentTranslation ? t('Editing') : t('Adding')}
        open={open}
        onClose={() => setOpen(false)}
      >
        <TranslationsForm
          translation={currentTranslation}
          refetchList={getTranslationsList}
          onClose={() => setOpen(false)}
        />
      </SimpleDialog>
    </TableContainer>
  );
};

export default Translations;
