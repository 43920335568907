import { ResponsiveLine } from '@nivo/line';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const NivoLineChart = ({ data, xTitle, yTitle }) => {
  const { t } = useTranslation('translations');
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  const axisBottomLine =
    data?.[0]?.data?.length > 20
      ? null
      : {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: xTitle,
          legendOffset: 36,
          legendPosition: 'middle',
        };

  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 50, right: 115, bottom: 50, left: 60 }}
      xScale={{ type: 'point' }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      curve="catmullRom"
      axisTop={null}
      axisRight={null}
      axisBottom={axisBottomLine}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yTitle,
        legendOffset: -40,
        legendPosition: 'middle',
      }}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={true}
      enableSlices="x"
      theme={{
        text: {
          fill: '#fff',
        },
        axis: {
          ticks: {
            text: {
              fontSize: 11,
              fill: isDarkMode ? '#fff' : '#16151C',
              outlineWidth: 0,
              outlineColor: 'transparent',
            },
          },
        },
      }}
      sliceTooltip={({ slice }) => {
        return (
          <div
            style={{
              background: isDarkMode ? '#16151C' : 'white',
              padding: '9px 12px',
              border: '1px solid #ccc',
            }}
          >
            <div className="text-left mb-3">
              {t('Date')}: {slice.points[0].data.x}
            </div>
            {slice.points.map((point) => (
              <div
                key={point.id}
                style={{
                  color:
                    point.serieColor === 'rgba(255, 255, 255, 0)' ? line1Color : point.serieColor,
                  padding: '3px 0',
                  textAlign: 'left',
                }}
              >
                <strong>{t(point.serieId)}:</strong> {point.data.y}
              </div>
            ))}
          </div>
        );
      }}
    />
  );
};

export default NivoLineChart;
