import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './translations/ru';
import uz from './translations/uz';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translations: {
      'Welcome to React': 'Welcome to React and react-i18next',
    },
  },
  ru: {
    translations: ru,
  },
  uz: {
    translations: uz,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ru',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
