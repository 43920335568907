import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const claimApi = createApi({
  reducerPath: 'claimApi',
  baseQuery: rtkBaseQuery('/claim/Claim'),
  tagTypes: ['Claims'],
  endpoints: (builder) => ({
    getAllClaims: builder.query({
      query: ({ page, rows }) => `/GetAll?page=${page}&rows=${rows}`,
    }),
    getClaimById: builder.query({
      query: ({ id }) => `GetById/${id}`,
    }),
    getClaimCreator: builder.query({
      query: () => 'GetClaimCreator',
    }),

    getClaimExecutor: builder.query({
      query: (params) => ({
        url: 'GetClaimForExecutor/get-claim-executor',
        method: 'GET',
        params,
      }),
    }),

    createClaim: builder.mutation({
      query: (body) => ({
        url: '/Create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Claims'],
    }),

    updateClaim: builder.mutation({
      query: ({ claimId, ...params }) => ({
        url: `/Update/${claimId}`,
        method: 'PUT',
        params,
      }),
      invalidatesTags: ['Claims'],
    }),
  }),
});

export const {
  useGetAllClaimsQuery,
  useGetClaimByIdQuery,
  useGetClaimCreatorQuery,
  useGetClaimExecutorQuery,
  useCreateClaimMutation,
  useUpdateClaimMutation,
} = claimApi;
