import handleError from '@fuse/core/errorHandler';
import { Button, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCreateLangMutation, useUpdateLangMutation } from 'src/app/services/spApi';

const defaultValues = {
  id: 0,
  code: '',
  nameRu: '',
  nameUz: '',
};

const TranslationsForm = ({ translation, onClose, refetchList }) => {
  const { t } = useTranslation('translations');
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: translation ?? defaultValues,
  });

  const [create, { isLoading }] = useCreateLangMutation();
  const [update] = useUpdateLangMutation();

  const handleClose = () => {
    onClose();
    reset(defaultValues);
  };

  const onSubmit = async (data) => {
    const promise = translation ? update(data) : create(data);
    promise
      .unwrap()
      .then(() => {
        refetchList();
        handleClose();
      })
      .catch(handleError);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="pt-10">
      <div className="flex flex-col gap-16 max-w-[1000px] mb-10">
        <Controller
          name="code"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextField
                className="w-sm"
                {...field}
                fullWidth
                label="Code"
                error={!!errors.code}
                variant="outlined"
                required
              />
            );
          }}
        />
        <Controller
          name="nameRu"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextField
                className="w-sm"
                {...field}
                fullWidth
                label={t('Title') + ' (рус.)'}
                error={!!errors.nameRu}
                variant="outlined"
                required
              />
            );
          }}
        />
        <Controller
          name="nameUz"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              className="w-sm"
              {...field}
              fullWidth
              label={t('Title') + ' (лат.)'}
              error={!!errors.nameUz}
              variant="outlined"
              required
            />
          )}
        />
      </div>
      <Button
        disabled={isLoading || !isValid}
        type="submit"
        className="w-full"
        variant="contained"
        color="secondary"
      >
        <span className="mx-8">{t('Save')}</span>
      </Button>
    </form>
  );
};

export default TranslationsForm;
