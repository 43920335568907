import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

function MyAutocomplete(props) {
  const {
    options = [],
    label,
    error,
    multiple,
    onInputChange,
    onSelect,
    loading,
    groupBy,
    noOptionsText,
    sx = {},
    className = '',
    helperText = null,
    fullWidth = false,
    freeSolo = false,
    disableCloseOnSelect = false,
    readOnly = false,
    ...custom
  } = props;

  const [open, setOpen] = useState(false);
  const asyncLoading = open && loading;

  const filterOptions = (_options, { inputValue }) => {
    if (inputValue === '') {
      return _options.slice(0, 1000); // show first 1000 options when input field is empty
    }

    return _options.filter((option) => option.title.toLowerCase().includes(inputValue.toLowerCase())).slice(0, 1000); // show only first 1000 matching options
  };

  return (
    <Controller
      {...custom}
      render={({ field: { onChange, ...field } }) => (
        <Autocomplete
          sx={sx}
          open={open}
          filterOptions={filterOptions}
          options={options}
          groupBy={groupBy}
          className={className}
          multiple={multiple}
          limitTags={5}
          freeSolo={freeSolo}
          readOnly={readOnly}
          fullWidth={fullWidth}
          loading={asyncLoading}
          noOptionsText={noOptionsText}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          getOptionLabel={(option) => option.title ?? ''}
          disableCloseOnSelect={disableCloseOnSelect}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, data) => {
            onChange(data);
            if (onSelect) onSelect(data);
          }}
          renderOption={(_props, option) => (
            <li {..._props} key={option.id}>
              {option.title}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              helperText={helperText}
              onChange={(e) => {
                params.inputProps.onChange(e);
                if (onInputChange) onInputChange(e.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {asyncLoading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          {...field}
        />
      )}
    />
  );
}

export default MyAutocomplete;
