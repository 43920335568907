/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['Администратор'],
  hr: ['Кадры'],
  user: ['Пользователь'],
  pr: ['PR'],
  top: ['Топ'],
  onlyGuest: [],
};

export default authRoles;
