import { showMessage } from 'app/store/fuse/messageSlice';
import store from 'app/store/index';

const handleError = (error) => {
  let errorMessage = 'Ошибка';
  if (error.status === 401) {
    console.error('Authorization error');
    errorMessage = 'Authorization error. Log in again...';
  } else if (error.response) {
    console.log(error.response);
    if (error.response.status === 401) {
      errorMessage = 'Authorization error. Log in again...';
    } else if (error.response.data) {
      errorMessage = JSON.stringify(error.response.data);
    } else {
      errorMessage = `На сервере ведутся работы. Попробуйте позже... ${
        error.response?.status ? error.response.status : ''
      } ${error.response.statusText}`;
    }
  } else {
    errorMessage = error.message || 'Error';
  }

  store.dispatch(
    showMessage({
      message: <div style={{ fontWeight: 'bold', fontSize: 20 }}>{errorMessage}</div>,
      variant: 'error',
      autoHideDuration: 5000,
    })
  );
};

export default handleError;
