import handleError from '@fuse/core/errorHandler';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'app/configs/api';
import axios from 'axios';
import { getChats, readUnreadMessages } from './chatsSlice';
import { setSelectedContactId } from './contactsSlice';

export const getChat = createAsyncThunk(
  'chatStore/chat/getChat',
  async (body, { dispatch, getState }) => {
    try {
      const response = await axios.post(API.messages, body);

      const chatId = JSON.parse(body.filters).chatgroupid.value;
      dispatch(setSelectedContactId(chatId));
      dispatch(readUnreadMessages(chatId));
      dispatch(setMessagesCount(response.data.totalItems));
      return response.data.items;
    } catch (e) {
      handleError(e);
      return [];
    }
  }
);

export const loadMoreMessages = createAsyncThunk(
  'chatStore/chat/loadMoreMessages',
  async (body, { dispatch, getState }) => {
    const { messages, totalMessagesCount } = getState().chatStore.chat;
    if (messages.length >= totalMessagesCount) return [];
    try {
      const response = await axios.post(API.messages, {
        ...body,
        first: messages.length,
      });
      const chatId = JSON.parse(body.filters).chatgroupid.value;
      dispatch(setSelectedContactId(chatId));
      dispatch(readUnreadMessages(chatId));
      dispatch(setMessagesCount(response.data.totalItems));
      return response.data.items;
    } catch (e) {
      handleError(e);
      return [];
    }
  }
);

export const sendMessage = createAsyncThunk(
  'chatStore/chat/sendMessage',
  async ({ messageText, chatId, contactId }, { dispatch, getState }) => {
    const response = await axios.post(`/api/chat/chats/${contactId}`, messageText);

    const data = await response.data;

    dispatch(getChats());

    return data;
  }
);

const chatSlice = createSlice({
  name: 'chatStore/chat',
  initialState: {
    messages: [],
    totalMessagesCount: 0,
  },
  reducers: {
    removeChat: (state, action) => {
      state.messages = action.payload;
      state.totalMessagesCount = 0;
    },
    addMessage: (state, action) => {
      const index = state.messages.findIndex((message) => {
        if (message.tempId && message.tempId === action.payload.tempId) return true;
        else return false;
      });
      if (index === -1) {
        state.messages = [action.payload, ...state.messages];
        state.totalMessagesCount++;
      } else {
        state.messages[index].id = action.payload.id;
        state.messages[index].status = 0;
      }
    },
    setMessagesCount: (state, action) => {
      state.totalMessagesCount = action.payload;
    },
    updateMessagesStatuses: (state, action) => {
      const { messageId, messageStatus, messageTempId } = action.payload;
      if (messageTempId && messageStatus) {
        const index = state.messages.findIndex((message) => message.tempId === messageTempId);
        if (index >= 0) {
          state.messages[index].status = messageStatus;
          state.messages[index].id = messageId;
        }
      } else if (messageStatus) {
        state.messages.forEach((message) => {
          message.status = messageStatus;
        });
      }
    },
  },
  extraReducers: {
    [getChat.fulfilled]: (state, action) => {
      state.messages = action.payload;
    },
    [sendMessage.fulfilled]: (state, action) => {
      state.messages = [action.payload, ...state.messages];
    },
    [loadMoreMessages.fulfilled]: (state, action) => {
      state.messages = [...state.messages, ...action.payload];
    },
  },
});

export const selectChat = ({ chatStore }) => chatStore.chat.messages;

export const selectChatTotalMessagesCount = ({ chatStore }) => chatStore.chat.totalMessagesCount;

export const selectUnsentMessages = ({ chatStore }) =>
  chatStore.chat.messages.filter((m) => m.status === 3);

export default chatSlice.reducer;

export const { addMessage, removeChat, setMessagesCount, updateMessagesStatuses } =
  chatSlice.actions;
