const locale = {
  Chats: 'Chats',
  CrteateNewGroup: 'Create New Group',
  Cancel: 'Cancel',
  Save: 'Save',
  Contacts: 'Contacts',
  Blanks: 'Blanks',
  Attachments: 'Attachments',
  DocsName: 'Docs Name',
  CreatedDate: 'Created Date',
  Employers: 'Employers',
  MySkud: 'My Skud',
  Departments: 'Departments',
  Users: 'Users',
  FullName: 'Full Name',
  HrmUsers: 'HRM Users',
  Actions: 'Actions',
  ConnectToHRM: 'Connect To HRM',
  AddStatus: 'Add Status',
  AddUser: 'Add User',
  Roles: 'Roles',
  Add: 'Add',
  Submit: 'Submit',
  Download: 'Download',
  Search: 'Search'
};

export default locale;
