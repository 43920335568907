import { createSlice } from "@reduxjs/toolkit";

const floorSlice = createSlice({
  name: 'floor',
  initialState: {
    buildingId: null,
    floorOrder: '',
    floorId: null,
    floorImage: '',
    departmentId: null
  },
  reducers: {
    changeFloorOrder: (state, action) => {
      state.buildingId = action.payload.buildingId;
      state.floorOrder = action.payload.floorOrder;
      state.floorId = action.payload.floorId;
      state.floorImage = action.payload.floorImage;
      state.departmentId = action.payload.departmentId;
    }
  }
});

export const { changeFloorOrder } = floorSlice.actions;

export default floorSlice.reducer;