import createCache from '@emotion/cache';
import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import withAppProviders from './withAppProviders';
import settingsConfig from 'app/configs/settingsConfig';
import rtlPlugin from 'stylis-plugin-rtl';
import handleError from '@fuse/core/errorHandler';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import dayjs from 'dayjs';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import { selectMainThemeDark } from 'app/store/fuse/settingsSlice';
import { selectMainThemeLight } from 'app/store/fuse/settingsSlice';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './auth/AuthContext';
import { selectUser } from './store/userSlice';
import { CacheProvider } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { removeNavigationItem } from './store/fuse/navigationSlice';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isBetween from 'dayjs/plugin/isBetween';
import { useSpItemsListMutation } from './services/spApi';
import { useFetchSubordinateDepartmentsMutation } from './services/shtatApi';
import { useUser } from './hooks/useUser';
import 'dayjs/locale/ru';
import JwtService from './auth/services/jwtService';

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

const initialParams = {
  filters: '{}',
  first: 0,
  rows: 1000,
  sortField: 'id',
  sortOrder: 0,
};

const App = () => {
  dayjs.locale('ru');
  dayjs.extend(isBetween);
  const [isTranslationsFetched, setIsTranslationsFetched] = useState(false);
  const [getTranslationsList, { isLoading: isTranslationsLoading }] = useSpItemsListMutation();
  const [fetchDepartments, { isLoading: isFetchingDepartments }] =
    useFetchSubordinateDepartmentsMutation();
  const user = useSelector(selectUser);
  const { isHR, isTop } = useUser();
  const isDarkMode = useSelector((state) => state.themeSlice.isDarkMode);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const selectedTheme = useSelector(isDarkMode ? selectMainThemeDark : selectMainThemeLight);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  if (user && user.staffId === 0) {
    dispatch(removeNavigationItem('apps.skud'));
    dispatch(removeNavigationItem('apps.projects'));
    dispatch(removeNavigationItem('apps.calendar'));
  }

  const fetchTranslations = () => {
    getTranslationsList({ tableName: 'Lang18s', ...initialParams })
      .unwrap()
      .then((response) => {
        const translations = response.items.reduce(
          (acc, t) => ({
            ru: {
              ...acc['ru'],
              [t.code]: t.nameRu,
            },
            uz: {
              ...acc['uz'],
              [t.code]: t.nameUz,
            },
          }),
          {}
        );
        i18n.addResourceBundle('ru', 'translations', translations?.ru, true, true);
        i18n.addResourceBundle('uz', 'translations', translations?.uz, true, true);
        setIsTranslationsFetched(true);
      })
      .catch(handleError);
  };

  useEffect(() => {
    let interval = null;
    if (user && user.staffId >= 0 && isTranslationsFetched) {
      if (JwtService.isFewMinutesToTokenDeathLeft()) {
        JwtService.refreshToken();
      }
      interval = setInterval(() => {
        if (JwtService.isFewMinutesToTokenDeathLeft()) {
          JwtService.refreshToken();
        }
      }, JwtService.tokenRefreshInterval);
    }
    return () => clearInterval(interval);
  }, [user, isTranslationsFetched]);

  const checkForSubordinateDepartments = (superviserId) => {
    fetchDepartments({ superviserId })
      .unwrap()
      .then((res) => {
        if (!(isHR || isTop) && res?.length === 0) {
          dispatch(removeNavigationItem('apps.skud'));
        }
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (user?.id && !isTranslationsFetched && !isTranslationsLoading) {
      fetchTranslations();
    }
  }, [user]);

  useEffect(() => {
    if (!isFetchingDepartments && user?.staffId) {
      checkForSubordinateDepartments(user.staffId);
    }
  }, [user]);

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <FuseTheme theme={selectedTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter>
            <FuseAuthorization
              userRole={user.roles}
              loginRedirectUrl={settingsConfig.loginRedirectUrl}
            >
              <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
                }}
              >
                <FuseLayout layouts={themeLayouts} />
              </SnackbarProvider>
            </FuseAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
