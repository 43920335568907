import moment from "moment";
import { Drawer, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import './SkudReportDrawer.css';

const SkudReportDrawer = ({isOpen, onClose, dates, emplStats}) => {
  const {t} = useTranslation('translations');
  const { isDarkMode } = useSelector(state => state.themeSlice);
  return (
    <Drawer
      PaperProps={{
        sx: {height:"90%"}
      }}
      anchor="bottom"
      open={isOpen}
      onClose={onClose}
    >
      <div className="flex justify-between items-center h-[60px] px-5 dark:bg-dark">
        <h2 className="grow text-center">{t('WorkingHoursSummaryReport')}</h2>
        <IconButton className="mr-10" onClick={onClose}>
          <Close/>
        </IconButton>
      </div>
      <div className="skud-table-container">
        <table className="skud-report-table">
          <thead className={`skud-report-thead ${isDarkMode ?  'skud-report-thead--dark' : ''}`}>
            <tr className={`skud-report-tr ${isDarkMode ?  'skud-report-tr--dark' : ''}`}>
              <th className={`skud-report-th ${isDarkMode ?  'skud-report-th--dark' : ''}`}>Ф.И.О.</th>
              {dates.map(date => (
                <th className={`skud-report-th ${isDarkMode ?  'skud-report-th--dark' : ''}`} key={date}>
                  {moment(date).format('DD.MM.YYYY')}<br/>
                  {moment(date).format( 'dddd' )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {emplStats.map((entry, index) => (
              <tr className={`skud-report-tr ${isDarkMode ?  'skud-report-tr--dark' : ''}`} key={entry.id}>
                <td className={`skud-report-td ${isDarkMode ?  'skud-report-td--dark' : ''}`}>{entry.fullName}</td>
                {dates.map(date => {
                  const dateEntry = entry.dates.find(d => d.date === date) || {};
                  return (
                    <td 
                      key={date} 
                      className={`skud-report-td ${isDarkMode ?  'skud-report-td--dark' : ''}`}
                      style={moment(dateEntry.date).weekday()==6 || moment(dateEntry.date).weekday()==5?{backgroundColor: isDarkMode ? '#3c3b40' : '#ffebee'}:null}
                    >
                      <p>{dateEntry.firstIn ? moment.utc(dateEntry.firstIn).format('HH:mm:ss') : ''} - {dateEntry.lastOut ? moment.utc(dateEntry.lastOut).format('HH:mm:ss') : ''}</p>
                      <p> {dateEntry.timeLate ? <><span>{t('Lateness')}: </span><span className="text-red">{dateEntry.timeLate.replaceAll("-", "")}</span></> : ''}</p>
                      <p> {dateEntry.earlyLeaving ? <><span>{t('EarlyLeaving')}: </span> <span className="text-red">{dateEntry.earlyLeaving}</span></> : ''}</p>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Drawer>
  )
}

export default SkudReportDrawer;