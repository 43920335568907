import SimpleDialog from '@fuse/core/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import PaymentAmount from './PaymentAmount';
import PaymentForm from './PaymentForm';
import moment from 'moment';
import useMessage from 'src/app/hooks/useMessage';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import {
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetContractByIdQuery } from 'src/app/services/contractsApi';
import { Popconfirm } from 'antd';
import {
  useDeletePaymentMutation,
  useFetchProjectPaymentsListMutation,
  useGetPaymentByIdQuery,
  useGetPaymentsListQuery,
} from 'src/app/services/paymentsApi';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useProjectContext } from '../ProjectContext';

const initialParams = {
  filters: '{}',
  first: 0,
  rows: 100,
  sortField: 'id',
  sortOrder: 0,
};

const PaymentList = () => {
  const { t } = useTranslation('translations');
  const { projectId } = useParams();
  const { showMessage } = useMessage();
  const [page, setPage] = useState(1);
  const [queryParams, setQueryParams] = useState({
    filters: `{}`,
    first: 0,
    rows: 10,
  });
  const [paymentList, setPaymentList] = useState([]);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [contractId, setContractId] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const { data: payments } = useGetPaymentsListQuery(queryParams);
  const { data: payment } = useGetPaymentByIdQuery({ id: paymentId }, { skip: !paymentId });
  const { data: contract } = useGetContractByIdQuery({ id: contractId }, { skip: !contractId });
  const [fetchProjectPayments] = useFetchProjectPaymentsListMutation();
  const { isOwner } = useProjectContext();

  const [deletePayment] = useDeletePaymentMutation();
  const { isDarkMode } = useSelector((state) => state.themeSlice);

  const getProjectPayments = () => {
    fetchProjectPayments({
      ...initialParams,
      filters: JSON.stringify({ projectid: { value: +projectId } }),
    })
      .unwrap()
      .then((res) => {
        setPaymentList(res.items);
        setTotalItems(res.totalItems);
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (payments) {
      getProjectPayments();
    }
  }, [payments]);

  const cols = [
    {
      key: 'contractNumber',
      label: t('Contract'),
      render: (row) => (
        <Link
          to={`/apps/projects/${projectId}/contracts/${row.contract.id}`}
          style={{ textDecoration: 'none', color: isDarkMode ? '#fff' : '#000' }}
          className="font-500"
        >
          {row.contract.contractNumber}
        </Link>
      ),
    },
    {
      key: 'paymentDate',
      label: t('PaymentDate'),
      render: (row) => moment(new Date(row.paymentDate)).format('DD.MM.YYYY HH:mm'),
    },
    {
      key: 'amount',
      label: t('Sum'),
      render: (row) => <PaymentAmount data={row} />,
    },
  ];

  const handleEditPayment = (_payment) => {
    setPaymentId(_payment.id);
    setContractId(_payment.contractId);
    setPaymentOpen(true);
  };

  const handleDeletePayment = (id) => {
    deletePayment({ id })
      .unwrap()
      .then(() => {
        showMessage({ message: t('PaymentSuccessfullyDeleted'), autoHideDuration: 3000 });
      })
      .catch(handleError);
  };

  const handleClosePayment = () => {
    setPaymentOpen(false);
    setPaymentId(null);
  };

  return (
    <div className="w-full bg-white dark:bg-dark">
      <Table>
        <TableHead>
          <TableRow>
            {cols.map((col) => (
              <TableCell key={col.key}>{col.label}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-root': {
              py: 1,
            },
          }}
        >
          {paymentList.map((row) => (
            <TableRow hover key={row.id}>
              {cols.map((col) => (
                <TableCell key={col.key}>{col.render ? col.render(row) : row[col.key]}</TableCell>
              ))}
              <TableCell>
                {isOwner && (
                  <>
                    <IconButton onClick={() => handleEditPayment(row)} size="small" color="info">
                      <EditOutlined />
                    </IconButton>
                    <Popconfirm
                      className="mr-10"
                      title={t('AreYouSureToDeletePayment')}
                      onConfirm={() => handleDeletePayment(row.contractPaymentId)}
                      okText={t('Yes')}
                      cancelText={t('No')}
                    >
                      <IconButton size="small" color="error">
                        <DeleteOutline />
                      </IconButton>
                    </Popconfirm>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        sx={{ alignSelf: 'center' }}
        hidden={totalItems < queryParams.rows}
        count={Math.ceil(totalItems / queryParams.rows)}
        page={page + 1}
        onChange={(e, value) => {
          setPage(value - 1);
          setQueryParams((prev) => ({ ...prev, first: (value - 1) * prev.rows }));
        }}
      />
      <SimpleDialog open={paymentOpen} onClose={handleClosePayment} title={t('Payment')}>
        <PaymentForm contract={contract || {}} data={payment} onClose={handleClosePayment} />
      </SimpleDialog>
    </div>
  );
};

export default PaymentList;
