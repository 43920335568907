const locale = {
  Chats: 'Чаты',
  CrteateNewGroup: 'Создать новую группу',
  Cancel: 'Отмена',
  Save: 'Сохранить',
  Contacts: 'Контакты',
  Blanks: 'Бланки заявлений',
  Attachments: 'Вложения',
  DocsName: 'Название документа',
  CreatedDate: 'Дата создания',
  Employers: 'Работодатели',
  MySkud: 'Мой Скуд',
  Departments: 'Департаменты',
  Users: 'Пользователи',
  FullName: 'Полное имя',
  HrmUsers: 'Пользователи HRM',
  Actions: 'Действия',
  ConnectToHRM: 'Связать с HRM',
  AddStatus: 'Добавить статус',
  AddUser: 'Добавить пользователя',
  Roles: 'Роли',
  Add: 'Добавить',
  Submit: 'Отравить',
  Download: 'Скачать',
  Search: 'Поиск',
  Profile: 'Профиль',
  Message: 'Сообщение',
  Employees: 'Сотрудники',
  Notifications: 'Оповещения',
  FullscreenToggle: 'Вкл/выкл полный экран',
  Welcome: 'Добро пожаловать',
  WelcomeToOurCommunity: 'Добро пожаловать в наше сообщество',
  WeСreateUniqueItSolutions:
    'Мы создаём уникальные IT - решения, которые помогают обществу и нашим партнёрам достигать своих целей, обеспечивают комфорт и наполняют жизнь новыми возможностями',
  SignIn: 'Вход',
  Username: 'Имя пользователя',
  Password: 'Пароль',
  EnterLogin: 'Введите логин',
  Hint: 'Подсказка',
  Press: 'Нажмите',
  here: 'сюда',
  toShowEducationTour: 'для просмотра обучающего тура',
  Further: 'Далее',
  Previous: 'Назад',
  EnterPassword: 'Введите пароль',
  EnterUzAtomMailLogin: 'Введите логин, полученный от администраторов для входа в почту UzAtom',
  EnterUzAtomMailPassword: 'Введите пароль, полученный от администраторов для входа в почту UzAtom',
  PressButton: 'Нажмите на кнопку',
  PressThisButtonOrEnterButtonOnKeyboard:
    'Нажмите на эту кнопку или на клавишу Enter на клавиатуре компьютера',
  Today: 'Сегодня',
  InFewDays: 'Близко',
  Greeting: 'Приветствую',
  GladToSeeYouInOurTeam: 'Рады видеть Вас в нашей команде',
  YourEnergyAndTalentWillBecomeContribution:
    'Ваша энергия и талант станут бесценным вкладом в наше общее дело',
  ForwardToNewAchievements: 'Вперед к новым свершениям',
  Start_verb: 'Начать',
  YouMustEnterUsername: 'Введите имя пользователя',
  PasswordIsTooShort: 'Слишком короткий пароль - должно быть не менее 4 символов',
  IncorrectLoginOrPassword: 'Неверное имя пользователя или пароль',
  InternalDocuments: 'Внутренние документы',
  DocumentName: 'Название документа',
  CreationDate: 'Дата создания',
  DownloadMobileApplication: 'Скачать мобильное приложение',
  HappyBirthdayСongratulations:
    'С днем рождения! Всем нашим дружным коллективом искренне поздравляем Вас со светлым, замечательным днем Вашего рождения! Желаем Вам больших успехов, постоянной удачи, везения, хорошего здоровья и бесконечного счастья в жизни. Пусть любимая работа Вам всегда приносит радость и удовольствие. Ваши профессиональные знания, большой опыт, мудрое, чуткое руководство и новые полезные идеи позволяют достигать нам в нашей совместной деятельности высоких результатов.',
  BestRegardsUzAtomTeam: 'C уважением, команда UzAtom.',
  SelectPhase: 'Выберите фазу',
  UzAtom_fullname: 'Агентство по атомной энергии при Кабинете министров Республики Узбекистан',
  ScanFromMobileToEnter: 'Отсканируйте с мобильного приложения для входа',
  SignInWithMyId: 'Войти с помощью MyID',
  GettingPermissionForEnter: 'Получение разрешения на вход',
  AddNews: 'Добавить новость',
  MyIDAuthError: 'Ошибка входа через MyID',
  BackToLoginPage: 'Вернуться на страницу входа',
  No: 'Нет'
};

export default locale;
