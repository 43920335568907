import FuseScrollbars from '@fuse/core/FuseScrollbars';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import FuseUtils from '@fuse/utils';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { API } from 'app/configs/api';
import { selectUser } from 'app/store/userSlice';

import axios from 'axios';

import SimpleDialog from '@fuse/core/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import FuseLoading from '@fuse/core/FuseLoading';

import { useTranslation } from 'react-i18next';
import ContactListItem from './ContactListItem';

import { ChatAppContext } from '../../ChatApp';
import ContactAvatar from '../../ContactAvatar';
import { useThemeMediaQuery } from '@fuse/hooks';
import { getContacts, selectContacts } from 'app/store/chatStore/contactsSlice';
import { getChats, selectChats } from 'app/store/chatStore/chatsSlice';

function MainSidebar() {
  const { setUserSidebarOpen, setMainSidebarOpen } = useContext(ChatAppContext);
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const contacts = useSelector(selectContacts);
  const chats = useSelector(selectChats);
  const user = useSelector(selectUser);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('md'));
  const [isContactsVisible, setIsContactsVisible] = useState(false);

  const [searchText, setSearchText] = useState('');

  function handleSearchText(event) {
    setSearchText(event.target.value);
    if (searchText.length > 0 && !isContactsVisible) {
      setIsContactsVisible(true);
    }
  }

  const [open, setOpen] = useState(false);

  const { handleSubmit, control, formState, setValue } = useForm({
    defaultValues: { name: '', type: 1, createdBy: user.id, LastMessageText: '', Members: [] },
  });
  const { errors } = formState;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isContactsVisible && user?.fio && contacts && contacts.length === 0) {
      dispatch(getContacts({ userId: user.id }));
    }
  }, [isContactsVisible, contacts]);

  const onSubmit = async (formValues) => {
    setLoading(true);
    try {
      await axios.post(API.createGroup, formValues);
      dispatch(getChats({ userId: user.id }));
      setOpen(false);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
    setValue('name', '');
  };

  const isLoading = useSelector((state) => state.chatStore.contacts.loading);

  return (
    <div className="flex flex-col flex-auto h-full">
      <Box
        className="py-16 px-32 border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <div className="flex justify-between items-center mb-16">
          {user && (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => setUserSidebarOpen(true)}
              onKeyDown={() => setUserSidebarOpen(true)}
              role="button"
              tabIndex={0}
            >
              <ContactAvatar className="relative" contact={user} />
              <Typography className="mx-16 font-medium">{user.fio}</Typography>
            </div>
          )}
          {isMobile && (
            <IconButton onClick={() => setMainSidebarOpen(false)}>
              <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
            </IconButton>
          )}
        </div>
        <div className="flex items-center gap-10">
          {useMemo(
            () => (
              <Paper className="flex p-4 items-center w-full px-16 py-4 border-1 h-40 rounded-full shadow-none">
                <FuseSvgIcon color="action" size={20}>
                  heroicons-solid:search
                </FuseSvgIcon>

                <Input
                  placeholder={t('Search', 'Поиск')}
                  className="flex flex-1 px-8"
                  disableUnderline
                  fullWidth
                  value={searchText}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchText}
                />
              </Paper>
            ),
            [searchText]
          )}
        </div>
      </Box>
      <FuseScrollbars className="overflow-y-auto flex-1">
        <List className="w-full">
          {useMemo(() => {
            function getFilteredArray(arr, _searchText) {
              if (_searchText.length === 0) {
                return arr;
              }
              return FuseUtils.filterArrayByString(arr, _searchText);
            }
            const filteredContacts = getFilteredArray([...contacts], searchText);
            const filteredChatList = getFilteredArray([...chats], searchText);

            const container = {
              show: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            };

            const item = {
              hidden: { opacity: 0, y: 20 },
              show: { opacity: 1, y: 0 },
            };

            return (
              <div
                className="flex flex-col shrink-0"
                variants={container}
                initial="hidden"
                animate="show"
              >
                <div variants={item} className="flex justify-between items-center pr-20">
                  <Typography className="font-medium text-20 px-32 py-24" color="secondary.main">
                    {t('Chats')}
                  </Typography>
                  <IconButton color="secondary" onClick={() => setOpen(true)}>
                    <Add />
                  </IconButton>
                </div>
                {filteredChatList.map((contact, index) => {
                  return (
                    <div variants={item} key={contact.id}>
                      <div className={clsx(filteredChatList.length !== index + 1 && 'border-b-1')}>
                        <ContactListItem chat contact={contact} />
                      </div>
                    </div>
                  );
                })}

                <div variants={item} className="flex justify-between items-center px-5">
                  <Typography className="font-medium text-20 px-32 py-24" color="secondary.main">
                    {t('Contacts')}
                  </Typography>
                  <span
                    className="cursor-pointer text-blue-700  mr-10"
                    onClick={() => setIsContactsVisible((prev) => !prev)}
                  >
                    {isContactsVisible ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                  </span>
                </div>

                {isLoading ? (
                  <FuseLoading />
                ) : !isContactsVisible ? null : filteredContacts.length > 0 ? (
                  filteredContacts.map(
                    (contact, index) =>
                      contact.id !== user.id && (
                        <div variants={item} key={contact.id}>
                          <div
                            className={clsx(filteredContacts.length !== index + 1 && 'border-b-1')}
                          >
                            <ContactListItem chat contact={contact} />
                          </div>
                        </div>
                      )
                  )
                ) : (
                  <FuseLoading />
                )}
              </div>
            );
          }, [contacts, chats, searchText, user.id, isContactsVisible])}
        </List>
        <SimpleDialog open={open} onClose={handleClose} title={t('CrteateNewGroup')}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              defaultValue=""
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mb-20 mt-6"
                  label="Название группы"
                  error={!!errors.name}
                  variant="outlined"
                  fullWidth
                />
              )}
            />
            <LoadingButton
              loading={loading}
              variant="contained"
              color="secondary"
              className=" w-full mt-16"
              type="submit"
              size="large"
            >
              {t('Save')}
            </LoadingButton>
          </form>
        </SimpleDialog>
      </FuseScrollbars>
    </div>
  );
}

export default MainSidebar;
