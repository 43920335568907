import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../../helpers/rtkquery';

export const claimStatusApi = createApi({
  reducerPath: 'claimStatusApi',
  baseQuery: rtkBaseQuery('/claim/Status'),
  tagTypes: ['Statuses'],
  endpoints: (builder) => ({
    getAllClaimStatuses: builder.query({
      query: () => ({
        url: '/GetAll',
      }),
      providesTags: ['Statuses'],
    }),
    getClaimStatusById: builder.query({
      query: (id) => ({
        url: '/GetById',
      }),
      providesTags: (result, error, { id }) => [{ type: 'Statuses', id }],
    }),
    createClaimStatus: builder.mutation({
      query: ({ name }) => ({
        url: `/Create?name=${name}`,
        method: 'POST',
      }),
      invalidatesTags: ['Statuses'],
    }),
    updateClaimStatus: builder.mutation({
      query: ({ id, name }) => ({
        url: '/Update',
        method: 'PUT',
        params: { id, name },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Statuses', id }, 'Statuses'], //Need to check if invalidating is working
    }),
    deleteClaimStatus: builder.mutation({
      query: (id) => ({
        url: `/Delete?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Statuses'],
    }),
  }),
});

export const {
  useGetAllClaimStatusesQuery,
  useGetClaimStatusByIdQuery,
  useCreateClaimStatusMutation,
  useUpdateClaimStatusMutation,
  useDeleteClaimStatusMutation,
} = claimStatusApi;
