import moment from 'moment';
import { QueryBuilder, NotificationsNone } from '@mui/icons-material';
import { decreaseAlarmsCount, setAlarmAsRead } from 'app/store/alarmsSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useThemeMediaQuery } from '@fuse/hooks';

const MyNotificationItem = ({ notification }) => {
  const isPhone = useThemeMediaQuery((theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation('translations');
  const dispatch = useDispatch();

  const handleSetOpened = (e) => {
    e.stopPropagation();
    if (notification.alarmStatus === 0) {
      dispatch(setAlarmAsRead({ id: notification.id }));
      dispatch(decreaseAlarmsCount());
    }
  };

  return (
    <div
      className="flex hover:bg-blue-50 dark:hover:bg-lightblack border-b-1 py-5 gap-10 max-w-[300px] md:max-w-[500px]"
      onMouseEnter={handleSetOpened}
    >
      {!isPhone && (
        <div className="w-32 flex items-center">
          <NotificationsNone className="text-grey-700 text-32 dark:text-white" />
        </div>
      )}
      <div className="flex flex-col">
        <div className="font-semibold text-grey-700 dark:text-white">
          {notification.senderSystem?.name}
          {notification.alarmStatus === 0 && (
            <span className="py-2 px-7 text-white bg-red ml-5 text-11 rounded-full">new</span>
          )}
        </div>
        {notification.text && (
          <div
            dangerouslySetInnerHTML={{
              __html: notification.text.replace(
                /(https?:\/\/[^\s]+)/g,
                `<a href="$1">(${t('Follow').toLowerCase()})</a>`
              ),
            }}
          />
        )}
        <div className="mt-5 text-grey-600">
          <QueryBuilder className="text-16 mb-3 mr-5" />
          <span className="text-12">
            {moment(notification.createdDate).format('DD.MM.YYYY HH:mm:ss')}
          </span>
        </div>
        <div className="flex justify-between mt-5 text-12 text-blue-900"></div>
      </div>
    </div>
  );
};

export default MyNotificationItem;
