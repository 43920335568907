import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useUser } from 'src/app/hooks/useUser';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { useThemeMediaQuery } from '@fuse/hooks';
import NewsList from '../news/NewsList';
import BirthdayList from './components/BirthdayList';
import EmployeesList from './components/EmployeesList';
import SkudCharts from './components/SkudCharts';
import GreetingsDialog from 'app/shared-components/greetingsDialog/GreetingsDialog';
import './swiper-custom.css';
import 'swiper/css';
import 'swiper/css/navigation';

function HomeApp() {
  const { t } = useTranslation('translations');
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('md'));
  const { isTop } = useUser();

  return (
    <FusePageSimple
      content={
        <Container maxWidth={false}>
          <Grid
            container
            sx={{ flexDirection: { lg: 'row', xs: 'column-reverse' } }}
            spacing={2}
            pt={2}
          >
            <Grid item lg={9} md={12}>
              {isTop && !isMobile && (
                <div>
                  {/* <SkudCharts /> */}
                  <h2 className="text-center mt-10">
                    {t('CompanyNews', 'Kompaniya yangiliklari')}
                  </h2>
                </div>
              )}
              <NewsList />
            </Grid>
            <Grid item lg={3} md={12}>
              <Grid
                container
                spacing={1}
                sx={{ flexDirection: { lg: 'column', md: 'row', xs: 'column' } }}
              >
                <Grid item lg={12} md={6} xs={12}>
                  <BirthdayList />
                </Grid>
                {!isMobile && (
                  <Grid item lg={12} md={6} xs={12}>
                    <EmployeesList />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <GreetingsDialog />
        </Container>
      }
      scroll="normal"
    />
  );
}

export default HomeApp;
