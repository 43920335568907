// const host = 'http://192.168.7.151:55722/api';
// const host = 'http://192.168.8.152:55722/api';
const host = process.env.REACT_APP_BASE_API_URL;

export const API = {
  //Account
  login: `${host}/auth/Account/Login`,
  profile: `${host}/auth/Account/UserProfile`,
  saveProfileImage: `${host}/auth/Account/OnSaveProfileImage`,
  refreshAccessToken: `${host}/auth/Account/RefreshAccessToken`,
  getAllUsersListWithHrm: `${host}/auth/Account/GetAllUserList`,

  changePassword: `${host}/auth/Account/ChangeUserPassword`,
  roles: `${host}/auth/Account/getRoles`,
  updateUserLocale: `${host}/auth/Account/UpdateUserLocal`,
  updateUserTheme: `${host}/auth/Account/UpdateUserTheme`,
  lockOutUser: `${host}/auth/Account/LockOutUser`,

  addUserStaffId: `${host}/auth/Account/UpdateUserStaffId`,

  createRole: `${host}/auth/Roles/Create`,
  addUserToRole: `${host}/auth/Roles/AddUserToRole`,
  removeUserFromRole: `${host}/auth/Roles/RemoveUserFromRole`,

  // CreateFeedback
  createFeedBack: `${host}/auth/General/CreateFeedback`,

  uploadFile: `${host}/staff/Staff/UploadFileForFolder`,
  contractFileUpload: `${host}/ticket/Ticket/UploadFileForFolder`,
  getFile: `${host}/staff/Staff/GetFile`,
  getBase64: `${host}/staff/Staff/GetStringBase64ById`,
  getBase64Video: `${host}/staff/Staff/GetStringBase64ByIdVideo`,
  getContractBase64: `${host}/ticket/Ticket/GetStringBase64ByIdContract`,
  getTicketBase64: `${host}/ticket/Ticket/GetStringBase64ByIdTask`,
  createTicketComment: `${host}/ticket/Ticket/CreateTicketComment`,
  deleteTicketComment: `${host}/ticket/Ticket/DeleteTicketComment`,
  updateTicketComment: `${host}/ticket/Ticket/EditTicketComment`,
  getTicketById: `${host}/ticket/Ticket/GetTicketById`,
  orgChart: `${host}/staff/Staff/GetOrgChart`,
  saveProfile: `${host}/staff/Staff/OnSaveProfile`,
  users: `${host}/auth/Account/GetUserList`,
  departments: `${host}/staff/staff/GetOrgUnitList`,
  staffList: `${host}/staff/staff/StaffsList`,
  staffById: `${host}/staff/Staff/StaffById`,
  staffBirhtdayListById: `${host}/staff/Staff/StaffBirthdayListByDate`,
  getBlanks: `${host}/staff/Staff/GetTemplateFileList`,
  getPresentations: `${host}/staff/Staff/GetPresentationFileList`,
  getPhotoFromHrm: `${host}/staff/Staff/GetPhotoFromHrm`,

  contractsList: `${host}/ticket/Ticket/ContractList`,
  createContract: `${host}/ticket/Ticket/CreateContract`,
  editContract: `${host}/ticket/Ticket/EditContract`,
  deleteContract: `${host}/ticket/Ticket/DeleteContract`,
  getOrganizationsList: `${host}/ticket/Ticket/OrganizationList`,
  getOrganizationById: `${host}/ticket/Ticket/GetOrganizationById`,

  skudEmployersList: `${host}/staff/Skud/GetSessionByPINFL`,
  skudByStaffId: `${host}/staff/Skud/SessionsByStaffId`,
  skudInOffice: `${host}/staff/Skud/InOffice`,

  createDepartment: `${host}/staff/staff/CreateOrgUnit`,
  editDepartment: `${host}/staff/staff/EditOrgUnit`,
  deleteDepartment: `${host}/staff/staff/DeleteOrgUnit`,
  createUser: `${host}/auth/AD/RegisterADUser`,

  chats: `${host}/auth/NChat/GetChatGroupList`,
  contacts: `${host}/auth/NChat/GetContactList`,
  messages: `${host}/auth/NChat/GetMessagesList`,
  createGroup: `${host}/auth/NChat/AddChatGroup`,
  getGroupMembers: `${host}/auth/NChat/GetChatUserList`,
  addUserToGroup: `${host}/auth/NChat/AddUserToChatGroup`,
  removeUserFromGroup: `${host}/auth/NChat/RemoveUserFromChatGroup`,

  updateStatus: `${host}/auth/Account/UpdateUserStatus`,
  ticketList: `${host}/ticket/Ticket/TicketsList`,
  phaseList: `${host}/ticket/Ticket/TicketsListByProject`,
  createProject: `${host}/ticket/Ticket/CreateProject`,
  deleteProject: `${host}/ticket/Ticket/DeleteProject`,
  deleteTicket: `${host}/ticket/Ticket/DeleteTicket`,
  createTicket: `${host}/ticket/Ticket/CreateTicket`,
  editTicket: `${host}/ticket/Ticket/EditTicket`,
  projects: `${host}/ticket/Ticket/ProjectsList`,
  projectById: `${host}/ticket/Ticket/GetProjectById`,
  addMember: `${host}/ticket/Ticket/addStaffToProject`,

  positions: `${host}/sps/SP/SpItemsList/Positions`,
  createPosition: `${host}/sps/SP/AddUpdate/Positions`,
  positionCategories: `${host}/sps/SP/SpItemsList/PositionCategories`,

  staffDepartments: `${host}/staff/Staff/GetOrgUnitListDepartmentsOnly`,
  getStaffListAll: `${host}/staff/Staff/StaffsListAll`,
  staffPositionList: `${host}/staff/Shtat/GetPositionsList`,
  createStaffPosition: `${host}/staff/Shtat/CreatePosition`,
  updateStaffPosition: `${host}/staff/Shtat/UpdatePosition`,
  deleteStaffPosition: `${host}/staff/Shtat/DeletePosition`,
  staffPositionById: `${host}/staff/Shtat/GetPositionById`,

  staffSchedule: `${host}/staff/Shtat/GetShtatScheduleList`,
  createPositionAssign: `${host}/staff/Shtat/CreatePersonnelPlacement`,
  updatePositionAssign: `${host}/staff/Shtat/UpdatePersonnelPlacement`,
  deletePositionAssign: `${host}/staff/Shtat/DeletePersonnelPlacement`,

  //Alarms
  getAlarmsList: `${host}/alarm/Alarm/GetAlarmList`,
  updateAlarmStatus: `${host}/alarm/Alarm/UpdateAlarmStatus`,
  getNewAlarmsCount: `${host}/alarm/Alarm/GetNewAlarmsCount`,

  //SP (references)
  getSpItemsList: `${host}/sps/SP/SpItemsList/`,

  //Contracts
  downloadContractFile: `${host}/ticket/Contract/GetFile`,
};
