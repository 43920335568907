import FusePageCarded from '@fuse/core/FusePageCarded/FusePageCarded';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon/FuseSvgIcon';
import handleError from '@fuse/core/errorHandler';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SkudDetailTable from './skud-detail-table/SkudDetailTable';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import StaffOnline from 'app/shared-components/StaffOnline';
import moment from 'moment';
import { motion } from 'framer-motion';
import { createContext, createElement, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFetchSessionsByStaffIdMutation } from 'src/app/services/skudApi';
import { IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { useThemeMediaQuery } from '@fuse/hooks';
import { useStaffByIdMutation } from 'src/app/services/staffApi';
import { useTranslation } from 'react-i18next';

const date = new Date();

const SubEmployeeSkudDetail = () => {
  const { t } = useTranslation('translations');
  const { id: staffId } = useParams();
  const [getStaff] = useStaffByIdMutation();
  const [fio, setFio] = useState('');

  function getStaffById() {
    getStaff({ id: staffId })
      .unwrap()
      .then((res) => setFio(res?.fullName.fio))
      .catch(handleError);
  }

  useEffect(() => {
    if (staffId) {
      getStaffById();
    }
  }, [staffId]);

  const [data, setData] = useState([]);
  const [currentDate, setCurrentDate] = useState(date);
  const calendarRef = useRef();

  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const [fetchSessions] = useFetchSessionsByStaffIdMutation();

  const calendarApi = () => calendarRef?.current?.getApi();

  useEffect(() => {
    fetchSessions({ staffId, dateStart: currentDate.toISOString(), period: 2 })
      .unwrap()
      .then((res) => {
        setData(res);
        calendarApi()?.gotoDate(currentDate);
      })
      .catch(handleError);
  }, [currentDate, fetchSessions, staffId]);

  const gotoPrevMonth = () => {
    setCurrentDate((prev) => moment(prev).subtract(1, 'month').toDate());
  };

  const gotoNextMonth = () => {
    setCurrentDate((prev) => moment(prev).add(1, 'month').toDate());
  };

  return (
    <FusePageCarded
      header={
        <div className="card overflow-x-auto w-full border-b-1 bg-white dark:bg-darker">
          <div className="p-24 pb-8 w-full flex flex-row justify-between items-center">
            <div className="flex flex-row items-center">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-20 md:text-24 tracking-tight leading-none"
              >
                {fio}
              </Typography>
              <Stack className="ml-20">
                <StaffOnline staffId={staffId} variant="outlined" />
              </Stack>
            </div>

            <div>
              <div className="flex items-center ">
                <Tooltip title={t('PreviousMonth')}>
                  <IconButton aria-label="Previous" onClick={gotoPrevMonth}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Tooltip>
                <Typography className="text-2xl tracking-tight whitespace-nowrap">
                  {moment(currentDate).format('MMMM YYYY')}
                </Typography>
                <Tooltip title={t('NextMonth')}>
                  <IconButton aria-label="Next" onClick={gotoNextMonth}>
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={t('Today')}>
                  <div>
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1, transition: { delay: 0.3 } }}
                    >
                      <IconButton
                        aria-label="today"
                        onClick={() => setCurrentDate(moment().toDate())}
                        size="large"
                      >
                        <FuseSvgIcon>heroicons-outline:calendar</FuseSvgIcon>
                      </IconButton>
                    </motion.div>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      }
      content={createElement(SkudDetailTable, { data, calendarRef })}
      scroll={isMobile ? 'normal' : 'page'}
    />
  );
};

export default SubEmployeeSkudDetail;

export const CalendarContext = createContext();
