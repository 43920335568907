import StaffTableList from './StaffTableList';
import FuseLoading from '@fuse/core/FuseLoading';
import { Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useGetShtatScheduleListQuery } from 'src/app/services/shtatApi';
import { useGetOrgUnitListDepartmentsOnlyQuery } from 'src/app/services/staffApi';

export default function StaffTable() {
  const { t } = useTranslation('translations');
  const { data: schedule } = useGetShtatScheduleListQuery();
  const { data: departments } = useGetOrgUnitListDepartmentsOnlyQuery();

  if (!schedule || !departments.length) return <FuseLoading />;

  return (
    <>
      <div>
        <div className="card overflow-x-auto w-full bg-white dark:bg-darker">
          <div className="p-24 sm:p-32 w-full border-b-1">
            <div className="flex justify-between items-center sm:items-start">
              <Typography
                component={motion.span}
                initial={{ x: -20 }}
                animate={{ x: 0, transition: { delay: 0.2 } }}
                delay={300}
                className="text-24 md:text-32 tracking-tight leading-none"
              >
                {t('StaffTable')}
              </Typography>
              <div className="flex gap-8">
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => {
                    document
                      .querySelectorAll('.staff-table .MuiAccordionSummary-root.Mui-expanded')
                      .forEach((el) => {
                        el.click();
                      });
                  }}
                >
                  {t('CollapseAll')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full staff-table">
          <StaffTableList schedule={schedule} />
        </div>
      </div>
    </>
  );
}
