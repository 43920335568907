import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import DownloadApp from './DownloadApp';

const HelpDeskApp = lazy(() => import('./HelpDesk'));

const HelpDeskAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/helpdesk',
      element: <HelpDeskApp />,
    },
    {
      path: 'apps/download-app',
      element: <DownloadApp />,
    },
  ],
};

export default HelpDeskAppConfig;
