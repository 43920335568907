import handleError from '@fuse/core/errorHandler';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { API } from 'app/configs/api';

import axios from 'axios';

export const getChats = createAsyncThunk(
  'chatStore/chats/getChats',
  async (params, { dispatch, getState }) => {
    const userId = getState().user?.id || '';
    try {
      const { data } = await axios.get(`${API.chats}?userId=${userId}`);

      const chats = data.map((chat) => {
        if (chat.type === 0) {
          const member = chat.members.find((m) => m.id !== userId);

          chat.fullName = member ? member.fullName : 'Saved Messages';
          chat.contactId = member ? member.id : userId;
        }

        return chat;
      });

      return chats;
    } catch (e) {
      handleError(e);
      return [];
    }
  }
);

const chatsAdapter = createEntityAdapter({});

export const { selectAll: selectChats, selectById: selectChatById } = chatsAdapter.getSelectors(
  (state) => state.chatStore.chats
);

const chatsSlice = createSlice({
  name: 'chatStore/chats',
  initialState: chatsAdapter.getInitialState({
    isInitialized: false,
  }),
  reducers: {
    setChatPhoto: (state, { payload: { id, photoUrl } }) => {
      state.ids.forEach((key) => {
        const member =
          state.entities[key].type === 0 &&
          state.entities[key].members.find((item) => item.id === id);
        if (member) {
          state.entities[key].photoURL = photoUrl;

          if (state.entities[key].type === 0) {
            state.entities[key].fullName = member ? member.fullName : 'Saved Messages';
            state.entities[key].contactId = member?.id;
          }
        }
      });
    },
    addUnreadMessage: (state, { payload: message }) => {
      if (state.entities[message.chatGroupId]) {
        state.entities[message.chatGroupId].unreadMessagesCount += 1;
        state.entities[message.chatGroupId].lastMessageText = message.text;
        state.entities[message.chatGroupId].lastMessageDate = message.createdDate;
      }
    },
    addLastMessage: (state, { payload: message }) => {
      if (state.entities[message.chatGroupId]) {
        state.entities[message.chatGroupId].lastMessageText = message.text;
        state.entities[message.chatGroupId].lastMessageDate = message.createdDate;
      }
    },
    initializeChat: (state) => {
      state.isInitialized = true;
    },
    readUnreadMessages: (state, { payload: chatId }) => {
      if (state.entities[chatId]) {
        state.entities[chatId].unreadMessagesCount = 0;
      }
    },
    addNewMember: (state, { payload: { chatId, newMembers } }) => {
      if (state.entities[chatId]) {
        state.entities[chatId].members = [...state.entities[chatId].members, ...newMembers];
      }
    },
    removeMemberFromGroup: (state, { payload: { userId, chatId } }) => {
      const memberIndex = state.entities[chatId].members.findIndex((m) => m.id === userId);
      if (memberIndex > -1) {
        state.entities[chatId].members = [
          ...state.entities[chatId].members.slice(0, memberIndex),
          ...state.entities[chatId].members.slice(memberIndex + 1),
        ];
      }
    },
  },
  extraReducers: {
    [getChats.fulfilled]: chatsAdapter.setAll,
  },
});

export const selectIsInitialized = ({ chatStore }) => chatStore.chats.isInitialized;

export const {
  setChatPhoto,
  addUnreadMessage,
  readUnreadMessages,
  addLastMessage,
  addNewMember,
  removeMemberFromGroup,
  initializeChat,
} = chatsSlice.actions;

export default chatsSlice.reducer;
