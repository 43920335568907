import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import ru from './i18n/ru';
import uz from './i18n/uz';

const Support = lazy(() => import('./Support'));

i18next.addResourceBundle('en', 'profileApp', en);
i18next.addResourceBundle('ru', 'profileApp', ru);
i18next.addResourceBundle('uz', 'profileApp', uz);

const SupportConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/support',
      element: <Support />,
    },
  ],
};

export default SupportConfig;