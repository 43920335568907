import { authRoles } from 'src/app/auth';
import InternalDocuments from './InternalDocuments';

const InternalDocumentsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/internal-documents',
      element: <InternalDocuments />,
    },
  ],
};

export default InternalDocumentsConfig;
