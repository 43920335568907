import { Avatar } from '@mui/material';
import { API } from 'app/configs/api';
import axios from 'axios';
import { useEffect, useState } from 'react';
import handleError from '../errorHandler';

function Image({ id, name, variant = 'standart', className }) {
  const [src, setSrc] = useState();

  useEffect(() => {
    if (id) {
      axios
        .post(API.getBase64, undefined, { params: { id } })
        .then((res) => setSrc(res.data))
        .catch(handleError);
    }
  }, []);

  return variant === 'avatar' ? (
    <Avatar src={src} alt={name} className={className ?? 'w-full h-full'}>
      {name[0]}
    </Avatar>
  ) : (
    <img alt={name} src={src} className={className ?? 'mb-3 w-3rem h-3rem'} />
  );
}

export default Image;
