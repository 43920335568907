import App from './app/App';
import ruRU from 'antd/locale/ru_RU';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';

const container = document.getElementById('root');

const root = createRoot(container);
root.render(
  <ConfigProvider
    theme={{
      token: {
          colorPrimary: '#0000FF',
      },
    }}
    locale={ruRU}
  >
    <StyleProvider hashPriority="high">
      <App/>
    </StyleProvider>
  </ConfigProvider>
);

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
