import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { rtkBaseQuery } from '../helpers/rtkquery';

export const buildingsApi = createApi({
  reducerPath: 'buildingsApi',
  baseQuery: rtkBaseQuery('/staff/Build'),
  tagTypes: ['Buildings'],
  endpoints: (builder) => ({
    createBuild: builder.mutation({
      query: (body) => ({
        url: '/Create',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Buildings'],
    }),
    addImageBuild: builder.mutation({
      query: (file) => {
        const formData = new FormData();
        formData.append("formFile", file);
        return {
          url: '/AddImage',
          method: 'POST',
          body: formData,
          formData: true,
          responseHandler: (response) => response.text()
        }       
      }
    }),
    getBuildsList: builder.query({
      query: () => '/GetAll',
      providesTags: ['Buildings']
    }),
    editBuild: builder.mutation({
      query: (body) => ({
        url: `/Update`,
        method: 'PUT',
        body
      }),
      invalidatesTags: ['Buildings'],
    }),
    getBuildById: builder.query({
      query: ({id}) => ({
        url: `/GetById?id=${id}`,
      })
    }),
    deleteBuildById: builder.mutation({
      query: (id) => ({
        url: `/Delete?id=${id}`,
        method: 'Delete'
      }),
      invalidatesTags: ['Buildings'],
    }),
  })
});

export const {
  useCreateBuildMutation,
  useAddImageBuildMutation,
  useGetBuildsListQuery,
  useEditBuildMutation,
  useGetBuildByIdQuery,
  useDeleteBuildByIdMutation
} = buildingsApi;