import handleError from '@fuse/core/errorHandler';
import SimpleDialog from '@fuse/core/SimpleDialog';
import StaffContactDetails from 'app/shared-components/StaffContactDetails';
import {
  Avatar,
  Card,
  CardContent,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { ChevronLeft, ChevronRight, Search } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useDeferredValue, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetListOfStaffsMutation } from 'src/app/services/staffApi';
import { capitalizeFirstLetter, getAbsoluteFileUrl } from 'src/app/helpers/utils';
import { Button } from 'antd';

const rows = 5;

const EmployeesList = () => {
  const { t } = useTranslation('translations');
  const { i18n } = useTranslation();
  const [employees, setEmployees] = useState([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [search, setSearch] = useState('');
  const [isEmplDetailsOpen, setIsEmplDetailsOpen] = useState(false);
  const [activeEmplId, setActiveEmplId] = useState(null);
  const [page, setPage] = useState(1);
  const [getListOfStaffs] = useGetListOfStaffsMutation();
  const deferredSearch = useDeferredValue(search);
  const lang = i18n.language === 'ru' ? 'ru' : 'uzb_lat';

  const getEmployeesList = () => {
    setEmployees([]);
    getListOfStaffs({
      page,
      rows,
      name: deferredSearch,
    })
      .unwrap()
      .then((response) => {
        setEmployees(response?.items);
        setTotalEmployees(response?.totalItems);
      })
      .catch(handleError);
  };

  const handleEmplClick = (id) => {
    setActiveEmplId(id);
    setIsEmplDetailsOpen(true);
  };

  const handleSearchChange = (event) => {
    if (page > 1) {
      setPage(1);
    }
    const employee = event.target.value.trim();
    setSearch(employee);
  };

  const handleClearSearch = () => {
    setPage(1);
    setSearch('');
  };

  const totalPages = useMemo(() => {
    return Math.ceil(totalEmployees / rows);
  }, [totalEmployees]);

  useEffect(() => {
    if (deferredSearch.length > 2) {
      getEmployeesList();
    }
  }, [deferredSearch, page]);

  return (
    <>
      <Card
        component={motion.div}
        variants={{
          hidden: { opacity: 0, y: 40 },
          show: { opacity: 1, y: 0 },
        }}
        className="w-full mb-1 rounded-16 p-24 pb-4 shadow-2"
      >
        <div>
          <Typography className="flex flex-1 text-2xl leading-tight">{t('Employees')}</Typography>
        </div>
        <div className="flex justify-center items-center gap-3 mt-16 border-0 relative">
          {deferredSearch && (
            <span
              className="block absolute right-0 z-40 text-xl font-medium mr-12 text-grey-500 dark:bg-dark hover:cursor-pointer"
              onClick={handleClearSearch}
            >
              &times;
            </span>
          )}
          <TextField
            className="bg-gray-100 dark:bg-[#1E1D29] border-0 rounded-8 pl-4"
            fullWidth
            id="outlined-controlled"
            size="small"
            autoComplete="off"
            value={search}
            variant="standard"
            placeholder={t('Search')}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginLeft: '7px' }}>
                  <Search />
                </InputAdornment>
              ),
              disableUnderline: true,
            }}
          />
        </div>

        <CardContent className="p-0">
          <List>
            {deferredSearch &&
              employees.map((empl) => (
                <ListItem
                  key={empl.id}
                  className="px-0 space-x-8 cursor-pointer hover:bg-grey-50 dark:hover:bg-lightblack border-b-1"
                  onClick={() => handleEmplClick(empl.id)}
                >
                  <Avatar src={getAbsoluteFileUrl(empl?.photo)} alt={empl.photo} />
                  <ListItemText>
                    <div className="flex flex-col">
                      <Typography
                        className="text-base dark:text-white capitalize font-inter font-700"
                        color="primary.main"
                        paragraph={false}
                      >
                        {capitalizeFirstLetter(empl.fullName.name)}{' '}
                        {capitalizeFirstLetter(empl.fullName.surname)}
                      </Typography>
                      <Typography className="text-sm font-inter text-gray-600 mt-2">
                        {empl.placeOfWork.positionName && empl?.placeOfWork?.positionName[lang]}
                      </Typography>
                    </div>
                  </ListItemText>
                </ListItem>
              ))}
          </List>
          {deferredSearch && employees.length < totalEmployees && totalEmployees > rows && (
            <div className="h-10 mt-10 flex justify-center items-center">
              <Button type="text" onClick={() => setPage(page - 1)} disabled={page < 2}>
                <ChevronLeft className="dark:text-white" />
              </Button>
              <span>
                {page} / {totalPages}
              </span>
              <Button type="text" onClick={() => setPage(page + 1)} disabled={page === totalPages}>
                <ChevronRight className="dark:text-white" />
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
      <SimpleDialog open={isEmplDetailsOpen} onClose={() => setIsEmplDetailsOpen(false)} noPadding>
        <StaffContactDetails id={activeEmplId} />
      </SimpleDialog>
    </>
  );
};

export default EmployeesList;
