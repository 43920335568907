import { lazy } from 'react';
import { authRoles } from 'src/app/auth';

const NewsApp = lazy(() => import('./NewsApp'));

const NewsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/news',
      element: <NewsApp />,
    },
  ],
};

export default NewsAppConfig;
