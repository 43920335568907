import handleError from '@fuse/core/errorHandler';
import dayjs from 'dayjs';
import FuseLoading from '@fuse/core/FuseLoading';
import SkudReportHeader from './SkudReportHeader';
import SkudReportDrawer from './SkudReportDrawer';
import moment from 'moment';
import { useEffect, useMemo, useState, useRef } from 'react';
import { useGetSessionsAllStaffMutation } from 'src/app/services/skudApi';
import { useThemeMediaQuery } from '@fuse/hooks';
import { IconButton, Button } from '@mui/material';
import { Tour } from 'antd';
import { useTranslation } from 'react-i18next';
import { QuestionOutlined } from '@ant-design/icons';
import {
  calcDataForLineChart,
  calcDataForPieChart,
  groupArrayForSkudTable,
} from '../skud-detail/SkudUtils';

const SkudReport = () => {
  const { t } = useTranslation('translations');
  const [getAllStaffSessions, { isLoading }] = useGetSessionsAllStaffMutation();
  const [fromDate, setFromDate] = useState(dayjs().subtract(7, 'day'));
  const [toDate, setToDate] = useState(dayjs());
  const [originalArray, setOriginalArray] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openTour, setOpenTour] = useState(false);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('md'));

  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const tourSteps = [
    {
      title: t('BeginAndEndDates'),
      description: t('ChooseStartAndEndDatesForSummaryReport'),
      target: () => ref1.current,
      nextButtonProps: {
        children: t('Further'),
      },
    },
    {
      title: t('EmployeesSummaryReport'),
      description: t('PressShiftButtonAndScrollMouseWheel'),
      cover: <img alt="horizontalscroll.jpg" src="assets/images/apps/skud/horizontalscroll.png" />,
      target: () => ref2.current,
      prevButtonProps: {
        children: t('Backward'),
      },
    },
  ];

  useEffect(() => {
    if (isMobile && originalArray.length > 0) {
      setIsDrawerOpen(true);
    }
  }, [originalArray]);

  useEffect(() => {
    getDataForReport();
  }, [fromDate, toDate]);

  const getDataForReport = () => {
    const dateStart = moment(+fromDate).format('YYYY-MM-DD');
    const dateStop = moment(+toDate).format('YYYY-MM-DD');
    getAllStaffSessions({ dateStart, dateStop })
      .unwrap()
      .then((res) => setOriginalArray(res))
      .catch(handleError);
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      setFromDate(dayjs(dates[0]));
      setToDate(dayjs(dates[1]));
    }
  };

  const groupedArray = useMemo(() => groupArrayForSkudTable(originalArray), [originalArray]);
  const dataForPieChart = useMemo(() => calcDataForPieChart(originalArray), [originalArray]);
  const dataForLineChart = useMemo(() => calcDataForLineChart(originalArray), [originalArray]);

  const uniqueDates = [
    ...new Set(groupedArray.flatMap((entry) => entry.dates.map((date) => date.date))),
  ];

  if (isLoading)
    return (
      <div className="h-full flex justify-center items-center">
        <FuseLoading />
      </div>
    );

  return (
    <div className="bg-white dark:bg-darker h-full flex flex-col justify-between">
      <SkudReportHeader
        ref={ref1}
        onRangeChange={onRangeChange}
        fromDate={fromDate}
        toDate={toDate}
        dataForPieChart={dataForPieChart}
        dataForLineChart={dataForLineChart}
        isMobile={isMobile}
        showTable={() => setIsDrawerOpen(true)}
        onTourShow={() => setTourOpen(true)}
      />
      <div className="flex justify-center items-center">
        <div className="text-center mb-20 flex-grow">
          <Button
            ref={ref2}
            variant="contained"
            color="info"
            onClick={() => setIsDrawerOpen(true)}
            className="dark:text-white"
          >
            {t('ShowTable')}
          </Button>
        </div>
        {!isMobile && (
          <IconButton
            color="info"
            sx={{ border: 1 }}
            onClick={() => setOpenTour(true)}
            className="mb-16 mr-16"
          >
            <QuestionOutlined />
          </IconButton>
        )}
      </div>
      <SkudReportDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        dates={uniqueDates}
        emplStats={groupedArray}
      />
      <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
    </div>
  );
};

export default SkudReport;
