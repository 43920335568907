import handleError from '@fuse/core/errorHandler';
import FileUpload from '@fuse/core/FileUpload';
import { Button, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDeleteAttachmentMutation } from 'src/app/services/ticketsApi';
import { UPLOAD_FILE_TYPE } from 'src/app/constants/enums';
import { useForm } from 'react-hook-form';
import { DeleteOutline } from '@mui/icons-material';
import useBlobFileDownload from 'src/app/hooks/useBlobFileDownload';
import { API } from 'app/configs/api';

const ContractFiles = ({ data, refetch }) => {
  const { downloadFile } = useBlobFileDownload();
  const [deleteAttachment] = useDeleteAttachmentMutation();
  const { contractId } = useParams();
  const handleDeleteFile = (id) => {
    deleteAttachment({ id, fileType: UPLOAD_FILE_TYPE.CONTRACT })
      .unwrap()
      .then(refetch)
      .catch(handleError);
  };
  const { control } = useForm();

  return (
    <div className="h-[600px] overflow-y-auto">
      {contractId && (
        <div>
          {data?.fileList?.map((file) => (
            <div
              key={file.id}
              className="flex items-center justify-between gap-8 hover:bg-grey-100 dark:hover:bg-lightblack"
            >
              <Button size="small" onClick={() => downloadFile(API.downloadContractFile, file)}>
                {file.fileName}
              </Button>
              <IconButton onClick={() => handleDeleteFile(file.id)}>
                <DeleteOutline color="error" />
              </IconButton>
            </div>
          ))}
          <FileUpload
            multiple
            control={control}
            name="fileList"
            type={UPLOAD_FILE_TYPE.CONTRACT}
            folder="contract"
            parentId={contractId}
            onSuccess={refetch}
            list={false}
          />
        </div>
      )}
    </div>
  );
};

export default ContractFiles;
