import FileUpload from '@fuse/core/FileUpload';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import SimpleDialog from '@fuse/core/SimpleDialog';
import handleError from '@fuse/core/errorHandler';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import { API } from 'app/configs/api';
import { selectUser } from 'app/store/userSlice';
import {
  addMessage,
  getChat,
  loadMoreMessages,
  removeChat,
  selectChat,
  selectUnsentMessages,
} from 'app/store/chatStore/chatSlice';
import { getChats, selectChatById } from 'app/store/chatStore/chatsSlice';
import {
  selectContactById,
  selectContacts,
  updateContactById,
} from 'app/store/chatStore/contactsSlice';
import axios from 'axios';
import clsx from 'clsx';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ChatAppContext } from '../ChatApp';
import ContactAvatar from '../ContactAvatar';
import ChatMoreMenu from './ChatMoreMenu';
import Message from './Message';
import { useTranslation } from 'react-i18next';
import { selectAllStaff } from 'app/store/staffSlice';

let prevChatId;

function Chat(props) {
  const { t } = useTranslation('translations');
  const { setMainSidebarOpen, setContactSidebarOpen } = useContext(ChatAppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chat = useSelector(selectChat);
  const user = useSelector(selectUser);
  const unsentMessages = useSelector(selectUnsentMessages);
  const routeParams = useParams();
  const contactId = routeParams.id;
  const selectedContact = useSelector((state) => selectContactById(state, contactId));
  const _selectedChat = useSelector((state) => selectChatById(state, contactId));
  const allStaff = useSelector(selectAllStaff);

  const selectedChat = _selectedChat ?? selectedContact;

  const [messageText, setMessageText] = useState('');
  const [repliedMessage, setRepliedMessage] = useState('');

  const inputRef = useRef(null);
  const chatRef = useRef(null);
  const chatTopRef = useRef(null);
  const chatObserverRoot = useRef(null);
  const previousLastMessage = useRef(null);

  function scrollToBottom() {
    let timeout = null;

    if (!chatRef.current) {
      if (timeout) return;

      timeout = setTimeout(() => {
        scrollToBottom();
      }, 1000);
      return;
    }
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (chatTopRef.current) {
      observer.observe(chatTopRef.current);
    }

    return () => {
      if (chatTopRef.current) {
        observer.unobserve(chatTopRef.current);
      }
    };
  }, [chatTopRef.current]);

  useEffect(() => {
    if (unsentMessages?.length) {
      localStorage.setItem('unsentMessages', JSON.stringify(unsentMessages));
    } else if (!unsentMessages.length && localStorage.getItem('unsentMessages')) {
      localStorage.removeItem('unsentMessages');
    }
  }, [unsentMessages]);

  const handleObserver = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        loadMore();
      }
    });
  };

  const loadMore = () => {
    const lastSlashIndex = window.location.pathname.lastIndexOf('/');
    const chatId = +window.location.pathname.slice(lastSlashIndex + 1);
    if (chatId > -1) {
      dispatch(
        loadMoreMessages({
          filters: JSON.stringify({ chatgroupid: { value: chatId } }),
          rows: 10,
          sortField: 'id',
          sortOrder: 0,
        })
      ).then(() => {
        setTimeout(() => {
          previousLastMessage.current?.scrollIntoView({ behavior: 'smooth' });
        }, 10);
      });
    }
  };

  useEffect(() => {
    if (Number(contactId)) {
      dispatch(
        getChat({
          filters: JSON.stringify({ chatgroupid: { value: contactId } }),
          first: 0,
          rows: 20,
          sortField: 'id',
          sortOrder: 0,
        })
      )
        .unwrap()
        .then((res) => {
          scrollToBottom();
        });
      if (+prevChatId !== +contactId) {
        window.connection.invoke('EnterChat', +prevChatId, +contactId);
        prevChatId = contactId;
        scrollToBottom();
      }
    } else {
      dispatch(removeChat([]));
      if (prevChatId) {
        window.connection.invoke('EnterChat', +prevChatId, null);
        prevChatId = undefined;
      }
    }
  }, [contactId, dispatch]);

  useEffect(() => {
    return () => {
      window.connection.invoke('EnterChat', +prevChatId, null);
      prevChatId = undefined;
    };
  }, []);

  function isFirstMessageOfGroup(item, i) {
    return i === 0 || (chat[i - 1] && chat[i - 1]?.userIdSender !== item?.userIdSender);
  }

  function isLastMessageOfGroup(item, i) {
    return (
      i === chat.length - 1 || (chat[i + 1] && chat[i + 1]?.userIdSender !== item?.userIdSender)
    );
  }

  function onInputChange(ev) {
    setMessageText(ev.target.value);
  }

  async function onMessageSubmit(ev) {
    const sendingMessageText = messageText;
    setMessageText('');
    try {
      const isFileMessage = ev.id;

      if (!isFileMessage) ev.preventDefault();

      if (sendingMessageText === '' && !isFileMessage) {
        return;
      }

      let chatId = contactId;

      if (Number.isNaN(Number(contactId))) {
        const { data: newChatId } = await axios.post(API.createGroup, {
          name: '',
          type: 0,
          createdBy: user.id,
          LastMessageText: '',
          Members: [],
          CompanionId: contactId,
        });

        chatId = newChatId;

        dispatch(updateContactById({ id: contactId, inChat: true, chatId }));
        await dispatch(getChats({ userId: user.id }));

        await window.connection.invoke('EnterChat', +prevChatId, newChatId);
        prevChatId = newChatId;
        scrollToBottom();

        navigate(`/apps/chat/${chatId}`);
      }
      const tempId = crypto.randomUUID();
      dispatch(
        addMessage({
          text: isFileMessage ? ev.name : sendingMessageText,
          chatgroupid: +chatId,
          userIdSender: user?.id,
          fileId: ev.id,
          messageType: isFileMessage ? 1 : 0,
          parentMessageId: repliedMessage?.id ?? 0,
          status: 3,
          tempId,
        })
      );
      await window.connection.invoke('SendGroupMessage', {
        text: isFileMessage ? ev.name : sendingMessageText,
        chatgroupid: +chatId,
        useridsender: user?.id,
        fileId: ev.id,
        messageType: isFileMessage ? 1 : 0,
        parentMessageId: repliedMessage?.id ?? 0,
        tempId,
      });

      scrollToBottom();
      setRepliedMessage(null);
    } catch (e) {
      handleError(e);
    }
  }

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, reset } = useForm();

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const promises = values.files.map((file) => onMessageSubmit(file));
      await Promise.all(promises);
      reset();
      setOpen(false);
    } catch (e) {
      handleError(e);
    }
    setLoading(false);
  };

  if (!user || !chat || !selectedChat) {
    return null;
  }

  return (
    <>
      <Box
        className="w-full border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <Toolbar className="flex items-center justify-between px-16 w-full">
          <div className="flex items-center">
            <IconButton
              aria-label="Open drawer"
              onClick={() => setMainSidebarOpen(true)}
              className="flex lg:hidden"
              size="large"
            >
              <FuseSvgIcon>heroicons-outline:list-bullet</FuseSvgIcon>
            </IconButton>
            <div
              className="flex items-center cursor-pointer wrap"
              onClick={() => {
                setContactSidebarOpen(true);
              }}
              onKeyDown={() => setContactSidebarOpen(true)}
              role="button"
              tabIndex={0}
            >
              <ContactAvatar className="relative mx-8" contact={selectedChat} />
              <Typography color="inherit" className="text-16 font-semibold px-4" component="div">
                {selectedChat?.fullName || selectedChat?.name}

                {selectedChat.type === 1 && (
                  <Typography color="inherit" className="text-14 ">
                    {t('ParticipantsCount')}: {selectedChat.members.length}
                  </Typography>
                )}
              </Typography>
            </div>
          </div>
          <ChatMoreMenu className="-mx-8" />
        </Toolbar>
      </Box>

      <div className="flex flex-auto h-full min-h-0 w-full">
        <div className={clsx('flex flex-1 z-10 flex-col relative', props.className)}>
          <div
            ref={chatRef}
            className="flex flex-1 flex-col overflow-y-auto relative scroll-smooth"
          >
            {chat?.length > 0 && (
              <div ref={chatObserverRoot} className="flex flex-col pt-16 px-16 pb-40">
                <div ref={chatTopRef}></div>
                <div ref={previousLastMessage} className="relative top-8"></div>
                {chat.toReversed().map((item, index) => {
                  const isMe = item?.userIdSender === user.id;
                  const author = isMe
                    ? user
                    : allStaff.find((member) => member.applicationUserId === item?.userIdSender);

                  let _repliedMessage;
                  let repliedMessageAuthor;

                  if (item.parentMessageId) {
                    _repliedMessage = chat.find((message) => message.id === item.parentMessageId);
                    repliedMessageAuthor =
                      _repliedMessage?.userIdSender === user.id
                        ? user
                        : allStaff.find(
                            (member) => member.applicationUserId === _repliedMessage?.userIdSender
                          );
                  }
                  return (
                    <Message
                      index={index}
                      chat={chat}
                      item={item}
                      isMe={isMe}
                      key={index}
                      author={author}
                      selectedChat={selectedChat}
                      repliedMessage={_repliedMessage}
                      setRepliedMessage={setRepliedMessage}
                      repliedMessageAuthor={repliedMessageAuthor}
                      isLastMessageOfGroup={isLastMessageOfGroup}
                      isFirstMessageOfGroup={isFirstMessageOfGroup}
                    />
                  );
                })}
              </div>
            )}
          </div>
          <Box className="absolute bottom-0 right-0 left-0 px-16">
            {repliedMessage && (
              <Box elevation={0} className="flex justify-between py-8">
                <div>
                  <IconButton className="" type="submit" size="small">
                    <FuseSvgIcon className="text-24" color="action">
                      heroicons-outline:reply
                    </FuseSvgIcon>
                  </IconButton>
                  <span className="ml-10">
                    {repliedMessage?.text?.length > 50
                      ? `${repliedMessage?.text.slice(0, 50)}...`
                      : repliedMessage?.text}
                  </span>
                </div>
                <Button
                  type="button"
                  size="small"
                  onClick={() => {
                    setRepliedMessage(null);
                  }}
                >
                  X
                </Button>
              </Box>
            )}
            {chat && (
              <Paper
                square
                elevation={0}
                component="form"
                onSubmit={onMessageSubmit}
                className="bottom-0 right-0 left-0  bg-white dark:bg-dark border-1"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                      ? lighten(theme.palette.background.default, 0.4)
                      : lighten(theme.palette.background.default, 0.02),
                }}
              >
                <div className="flex items-center relative">
                  <IconButton className="" type="submit" size="large" onClick={() => setOpen(true)}>
                    <FuseSvgIcon className="text-24" color="action">
                      heroicons-outline:paper-clip
                    </FuseSvgIcon>
                  </IconButton>

                  <SimpleDialog open={open} onClose={() => setOpen(false)} title={t('SendFile')}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <FileUpload
                        multiple
                        control={control}
                        name="files"
                        defaultValue={[]}
                        type={2}
                      />
                      <LoadingButton
                        loading={loading}
                        type="submit"
                        className="w-full"
                        variant="contained"
                        color="secondary"
                      >
                        <FuseSvgIcon className="rotate-90" size={20}>
                          heroicons-outline:paper-airplane
                        </FuseSvgIcon>
                        <span className="mx-8">{t('Send')}</span>
                      </LoadingButton>
                    </form>
                  </SimpleDialog>

                  <InputBase
                    multiline
                    ref={inputRef}
                    autoFocus
                    autoComplete="off"
                    id="message-input"
                    className="flex-1 flex grow shrink-0 min-h-44 mx-8 px-16"
                    placeholder={t('Message')}
                    onChange={onInputChange}
                    value={messageText}
                    maxRows={4}
                    sx={{ backgroundColor: 'background.paper' }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
                        onMessageSubmit(e);
                      }
                    }}
                  />

                  <IconButton className="" type="submit" size="large">
                    <FuseSvgIcon className="rotate-90" color="action">
                      heroicons-outline:paper-airplane
                    </FuseSvgIcon>
                  </IconButton>
                </div>
              </Paper>
            )}
          </Box>
        </div>
      </div>
    </>
  );
}

export default Chat;
