import { lazy } from 'react';
import { authRoles } from 'src/app/auth';
import i18next from 'i18next';
import Chat from './chat/Chat';
import ChatFirstScreen from './ChatFirstScreen';
import en from '../../../../translations/en';
import ru from '../../../../translations/ru';
import uz from '../../../../translations/uz';

const ChatApp = lazy(() => import('./ChatApp'));

i18next.addResourceBundle('en', 'common', en);
i18next.addResourceBundle('ru', 'common', ru);
i18next.addResourceBundle('uz', 'common', uz);

const ChatAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'apps/chat',
      element: <ChatApp />,
      children: [
        {
          path: '',
          element: <ChatFirstScreen />,
        },
        {
          path: ':id',
          element: <Chat />,
        },
      ],
    },
  ],
};

export default ChatAppConfig;
