import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import _ from '@lodash';
import { getAbsoluteFileUrl } from 'src/app/helpers/utils';
import { useSelector } from 'react-redux';
import { selectOnlineUsers } from 'app/store/chatStore/contactsSlice';
import { selectUser } from 'app/store/userSlice';
import Statuses from './Statuses';

const StyledBadge = styled(Badge)(({ theme, isgroup, ...props }) => {
  const styles = {
    width: 40,
    height: 40,
    fontSize: 20,
    '& .MuiAvatar-root': {
      fontSize: 'inherit',
      color: theme.palette.text.secondary,
      fontWeight: 600,
    },
  };

  if (isgroup === 'false') {
    styles['& .MuiBadge-badge'] = {
      backgroundColor: props.statuscolor,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        content: '""',
      },
    };
  }

  return styles;
});

function ContactAvatar({ contact, className }) {
  const onlineUsers = useSelector(selectOnlineUsers);
  const status = _.find(Statuses, {
    value: (
      contact.type === 0
        ? onlineUsers.includes(contact.contactId)
        : onlineUsers.includes(contact.id)
    )
      ? 'online'
      : 'offline',
  });

  const user = useSelector(selectUser);

  const chatSrc =
    contact.type === 0 && contact.members
      ? contact.members.find((member) => member.id !== user.id)?.staffImageFileUrl
      : null;

  const src = getAbsoluteFileUrl(contact?.staffImageFileUrl || chatSrc);

  return (
    <StyledBadge
      className={className}
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      variant="dot"
      statuscolor={status?.color}
      isgroup={`${contact.type === 1}`}
    >
      <Avatar src={src} alt={contact.fullName} className="w-full h-full">
        {(contact.fullName || contact.fio || contact.name || 'U')[0]}
      </Avatar>
    </StyledBadge>
  );
}

export default ContactAvatar;
