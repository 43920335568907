const { createApi } = require('@reduxjs/toolkit/dist/query/react');
const { rtkBaseQuery } = require('../helpers/rtkquery');

export const ticketsApi = createApi({
  reducerPath: 'ticketsApi',
  baseQuery: rtkBaseQuery('/ticket/Ticket'),
  tagTypes: ['Ticket', 'Project', 'Board', 'Organization', 'Phase', 'MyTickets'],
  endpoints: (builder) => ({
    // Project
    getProjectsList: builder.query({
      query: (params) => ({
        url: '/ProjectsList',
        method: 'GET',
        params,
      }),
      providesTags: ['Project'],
    }),
    // getProjectsList: builder.query({
    //   query: (body) => ({
    //     url: '/ProjectsList',
    //     method: 'POST',
    //     body,
    //   }),
    //   providesTags: ['Project'],
    // }),
    createProject: builder.mutation({
      query: (body) => ({
        url: '/CreateProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Project'],
    }),
    editProject: builder.mutation({
      query: (body) => ({
        url: '/EditProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Project', id }, 'Project'],
    }),
    deleteProject: builder.mutation({
      query: (params) => ({
        url: '/DeleteProject',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Project'],
    }),
    addStaffToProject: builder.mutation({
      query: (body) => ({
        url: '/AddStaffToProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],
    }),
    removeStaffFromProject: builder.mutation({
      query: (body) => ({
        url: '/RemoveStaffFromProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],
    }),
    addStaffToTicket: builder.mutation({
      query: (body) => ({
        url: '/AddStaffToTicket',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Board'],
    }),
    removeStaffFromTicket: builder.mutation({
      query: (body) => ({
        url: '/RemoveStaffFromTicket',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Board'],
    }),

    // Board
    createBoard: builder.mutation({
      query: (body) => ({
        url: '/CreateBoard',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Board'],
    }),
    deleteBoard: builder.mutation({
      query: (params) => ({
        url: '/DeleteBoard',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Board'],
    }),
    editBoard: builder.mutation({
      query: (body) => ({
        url: '/EditBoard',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Board', id: arg.id }, 'Board'],
    }),
    getBoardListByProject: builder.query({
      query: (params) => ({
        url: '/GetBoardListByProject',
        method: 'POST',
        params,
      }),
      providesTags: (result, error, { projectId }) => [{ type: 'Board', id: projectId }, 'Board'],
    }),
    // POST /services/ticket/Ticket/MoveBoard
    moveList: builder.mutation({
      query: (body) => ({
        url: '/MoveBoard',
        method: 'POST',
        body,
      }),
      // invalidatesTags: ['Board'],
    }),
    // Ticket
    fetchTicketList: builder.mutation({
      query: (body) => ({
        url: '/TicketsList',
        method: 'POST',
        body,
      }),
    }),
    getPhaseList: builder.query({
      query: (body) => ({
        url: '/TicketsList',
        method: 'POST',
        body,
      }),
      providesTags: ['Phase'],
    }),
    getPhaseListByProject: builder.query({
      query: (params) => ({
        url: '/GetPhaseListByProject',
        method: 'POST',
        params,
      }),
      providesTags: (result, error, { projectId }) => [{ type: 'Phase', id: projectId }, 'Phase'],
    }),
    fetchTicketListByProject: builder.mutation({
      query: (params) => ({
        url: '/TicketsListByProject',
        method: 'POST',
        params,
      }),
    }),
    createTicket: builder.mutation({
      query: (body) => ({
        url: '/CreateTicket',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { parentTicketId }) => [
        { type: 'Ticket', id: parentTicketId },
        'Board',
        'Phase',
      ],
    }),
    createTicketComment: builder.mutation({
      query: (body) => ({
        url: '/CreateTicketComment',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { ticketId }) => [{ type: 'Ticket', id: ticketId }, 'Board'],
    }),
    // Phase
    fetchPhaseList: builder.mutation({
      query: (params) => ({
        url: '/PhaseList',
        method: 'POST',
        params,
      }),
    }),

    addTicketToBoard: builder.mutation({
      query: (body) => ({
        url: '/AddTicketToBoard',
        method: 'POST',
        body,
      }),
    }),
    deleteTicketToBoard: builder.mutation({
      query: (params) => ({
        url: '/DeleteTicketToBoard',
        method: 'POST',
        params,
      }),
    }),
    moveTicket: builder.mutation({
      query: (params) => ({
        url: '/MoveTicket',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Board'],
    }),
    editTicketComment: builder.mutation({
      query: (body) => ({
        url: '/EditTicketComment',
        method: 'POST',
        body,
      }),
    }),
    deleteTicketComment: builder.mutation({
      query: (params) => ({
        url: '/DeleteTicketComment',
        method: 'POST',
        params,
      }),
    }),
    deleteTicket: builder.mutation({
      query: (params) => ({
        url: '/DeleteTicket',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Board'],
    }),
    deletePhase: builder.mutation({
      query: (params) => ({
        url: '/DeleteTicket',
        method: 'POST',
        params,
      }),
      invalidatesTags: ['Phase'],
    }),
    editTicket: builder.mutation({
      query: (body) => ({
        url: '/EditTicket',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Ticket', id }, 'Board', 'MyTickets'],
    }),
    ticketById: builder.mutation({
      query: (params) => ({
        url: '/GetTicketById',
        params,
      }),
    }),
    getTicketById: builder.query({
      query: (params) => ({
        url: '/GetTicketById',
        params,
      }),
      providesTags: (result, error, { id }) => [{ type: 'Ticket', id }],
    }),
    getProjectById: builder.query({
      query: (params) => ({
        url: '/GetProjectById',
        params,
      }),
      providesTags: (result, error, { id }) => [{ type: 'Project', id }, 'Project'],
    }),
    uploadFileForFolder: builder.mutation({
      query: (params) => ({
        url: '/UploadFileForFolder',
        method: 'POST',
        params,
      }),
    }),
    getStringBase64ByIdTask: builder.mutation({
      query: (params) => ({
        url: '/GetStringBase64ByIdTask',
        method: 'POST',
        params,
      }),
    }),
    readOrganizations: builder.query({
      query: () => '/ReadOrganizations',
    }),
    getOrganizationById: builder.query({
      query: (params) => ({
        url: '/GetOrganizationById',
        params,
      }),
    }),
    fetchOrganizationList: builder.mutation({
      query: (body) => ({
        url: '/OrganizationList',
        method: 'POST',
        body,
      }),
    }),
    getOrganizations: builder.mutation({
      query: (body) => ({
        url: '/OrganizationList',
        method: 'POST',
        body,
      }),
      // transformResponse: (response) => response.items,
    }),
    updateSpOrganization: builder.mutation({
      query: (body) => ({
        url: '/EditOrganization',
        method: 'POST',
        body,
      }),
    }),

    createSpOrganization: builder.mutation({
      query: (body) => ({
        url: '/CreateOrganization',
        method: 'POST',
        body,
      }),
    }),

    getAllOrganizations: builder.query({
      query: () => ({
        url: '/OrganizationList',
        method: 'POST',
        body: { filters: '{}', first: 0, rows: 1000, sortField: '', sortOrder: 0 },
      }),
      transformResponse: (response) => response.items,
    }),

    downloadGanttChart: builder.mutation({
      query: (body) => ({
        url: '/DownloadGantChart',
        method: 'POST',
        body,
        responseHandler: (response) => response.text(),
      }),
    }),

    changeRoleInProject: builder.mutation({
      query: (body) => ({
        url: '/ChangeRoleInProject',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Project', id: projectId }],
    }),

    getTicketByStaffId: builder.query({
      query: (params) => ({
        url: '/GetTicketByStaffId',
        params,
      }),
      providesTags: ['MyTickets'],
    }),

    getTicketCountByStaffId: builder.query({
      query: (params) => ({
        url: '/GetTicketCountByStaffId',
        params,
      }),
    }),

    getAllProjectList: builder.query({
      query: () => '/GetAllProjectList',
    }),

    deleteAttachment: builder.mutation({
      query: (params) => ({
        url: '/DeleteFile',
        method: 'POST',
        params,
      }),
    }),

    getProjectPassportById: builder.query({
      query: (params) => ({
        url: '/GetProjectPassportByProjectId',
        params,
      }),
    }),

    addProjectPassport: builder.mutation({
      query: (body) => ({
        url: '/CreateProjectPassport',
        method: 'POST',
        body,
      }),
    }),

    editProjectPassport: builder.mutation({
      query: (body) => ({
        url: '/EditProjectPassport',
        method: 'POST',
        body,
      }),
    }),

    bindProjectsWithProject: builder.mutation({
      query: (body) => ({
        url: '/BindProjectWithProject',
        method: 'POST',
        body,
      }),
    }),

    getProjectByStaffId: builder.query({
      query: (params) => ({
        url: '/GetProjectsByStaffId',
        params,
      }),
    }),

    getBoardTicketListByProject: builder.mutation({
      query: (body) => ({
        url: 'GetBoardTicketListByProject',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useAddStaffToProjectMutation,
  useAddStaffToTicketMutation,
  useAddTicketToBoardMutation,
  useCreateBoardMutation,
  useCreateProjectMutation,
  useEditProjectMutation,
  useCreateTicketCommentMutation,
  useCreateTicketMutation,
  useDeleteBoardMutation,
  useDeleteProjectMutation,
  useDeleteTicketCommentMutation,
  useDeleteTicketMutation,
  useDeleteTicketToBoardMutation,
  useEditBoardMutation,
  useEditTicketCommentMutation,
  useEditTicketMutation,
  useFetchOrganizationListMutation,
  useFetchPhaseListMutation,
  useGetProjectsListQuery,
  useFetchTicketListByProjectMutation,
  useFetchTicketListMutation,
  useGetBoardListByProjectQuery,
  useGetOrganizationByIdQuery,
  useGetProjectByIdQuery,
  useGetStringBase64ByIdTaskMutation,
  useTicketByIdMutation,
  useGetTicketByIdQuery,
  useMoveTicketMutation,
  useReadOrganizationsQuery,
  useGetAllOrganizationsQuery,
  useGetOrganizationsMutation,
  useUpdateSpOrganizationMutation,
  useCreateSpOrganizationMutation,
  useRemoveStaffFromProjectMutation,
  useRemoveStaffFromTicketMutation,
  useUploadFileForFolderMutation,
  useMoveListMutation,
  useDownloadGanttChartMutation,
  useGetPhaseListQuery,
  useDeletePhaseMutation,
  useGetPhaseListByProjectQuery,
  useChangeRoleInProjectMutation,
  useGetTicketByStaffIdQuery,
  useGetTicketCountByStaffIdQuery,
  useGetAllProjectListQuery,
  useDeleteAttachmentMutation,
  useGetProjectPassportByIdQuery,
  useAddProjectPassportMutation,
  useEditProjectPassportMutation,
  useBindProjectsWithProjectMutation,
  useGetProjectByStaffIdQuery,
  useGetBoardTicketListByProjectMutation,
} = ticketsApi;
